import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { NavParams } from '@ionic/angular';

@Component({
    selector: 'notification-popover',
    templateUrl: './notification-popover.component.html',
    styleUrls: ['./notification-popover.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationPopoverComponent implements OnInit {
    message: string;

    constructor(private navParams: NavParams) {
        this.message = this.navParams.get('message');
    }

    ngOnInit() { }

}

export interface NotificationData {
    message: string;
    event?: any;
    props?: any;
}
