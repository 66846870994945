<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{'components.batteryResults.label.assessment' | translate}}: {{battery.patient.name.value}}
        </ion-title>
        <ion-buttons slot="start">
            <ion-back-button class="show-back-button" defaultHref="dashboard" routerDirection="right">
            </ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-segment color="accent" (ionChange)="segmentChanged($event)" value="subtests">
        <ion-segment-button value="subtests">
            <ion-label>{{'components.batteryResults.label.subtests' | translate}}</ion-label>
        </ion-segment-button>
        <ion-segment-button value="composites">
            <ion-label>{{'components.batteryResults.label.composites' | translate}}</ion-label>
        </ion-segment-button>
        <ion-segment-button value="comparisons">
            <ion-label>{{'components.batteryResults.label.comparisons' | translate}}</ion-label>
        </ion-segment-button>
    </ion-segment>
    <ion-grid class="ion-padding">
        <ion-row [ngSwitch]="resultView">
            <ion-col *ngSwitchCase="'subtests'">
                <h1>{{'components.batteryResults.label.subtests' | translate}}</h1>
            </ion-col>
            <ion-col *ngSwitchCase="'composites'">
                <h1>{{'components.batteryResults.label.composites' | translate}}</h1>
            </ion-col>
            <ion-col *ngSwitchCase="'comparisons'">
                <h1>{{'components.batteryResults.label.comparisons' | translate}}</h1>
            </ion-col>

        </ion-row>
    </ion-grid>

</ion-content>