import { Injectable } from '@angular/core';

import { Login, LoginConstants, LoginFailureReason, LoginUserInfo, OfflineLoginState } from '@app/models';
import { AssessEvents } from '@app/shared';
import { environment } from '@appenv';

import { Storage } from '@ionic/storage';

import { EncryptDecryptService } from './encryption.service';
import { Events } from './events.service';
import { HttpService } from './http.service';
import { LoggingService, Logger } from './logging.service';
import { NetworkStatusService } from './network-status.service';
import { OfflineLoginStoreService } from './offline-login.service';
import { UserStoreService } from './user-store.service';

@Injectable()
export class AuthService {

    AUTH_URL = `${environment.centralEndpoint}/sync/checkAuth`;
    LOGOUT_URL = `${environment.centralEndpoint}/j_spring_security_logout`;
    AURORA_URL = `${environment.centralEndpoint}/login/checkAuthExternal`;
    logger: Logger;
    isExternalUser: boolean;

    constructor(
        public events: Events,
        private http: HttpService,
        private loggingService: LoggingService,
        private storage: Storage,
        private userStoreService: UserStoreService,
        private offlineStorage: OfflineLoginStoreService,
        private networkStatusService: NetworkStatusService,
        private encryptionService: EncryptDecryptService
    ) {
        this.logger = this.loggingService.getLogger('AuthService');
        this.events.subscribe(AssessEvents.logout, async () => {
            this.logout();
        });
    }

    /**
    * Login to Central
    * @param credentials - username and password as Login interface
    * @param isExternalUser - flag to depict if user if external or not
    * @return - a promise
    */
    public async doLogin(credentials: Login, isExternalUser = false): Promise<void> {
        this.events.publish(AssessEvents.login);
        if (this.networkStatusService.isConnected()) {
            try {
                const authHeader = {
                    Authorization: 'Basic ' + btoa(`${credentials.username}:${credentials.password}`)
                };
                const userInfo: LoginUserInfo = await this.http.get(this.AUTH_URL, { headers: authHeader }, true);
                userInfo.signInId = credentials.username;
                await this.userStoreService.setLoggedInUserInfo(userInfo);
                let usernametoValidate;
                let passwordtoValidate;
                if (isExternalUser) {
                    usernametoValidate = credentials.externalUsername;
                    passwordtoValidate = credentials.externalPassword;
                } else {
                    usernametoValidate = credentials.username;
                    passwordtoValidate = credentials.password;
                }
                await this.offlineStorage.addOrUpdateLoginInfo(usernametoValidate, userInfo, passwordtoValidate);
                this.logger.success(`Online login successful for ${credentials.username}`);
            } catch (err) {
                if (err.status === 401) {
                    this.events.publish(AssessEvents.loginFailure, LoginFailureReason.ONLINE_UNAUTHORISED);
                    this.logger.warn(`Online login failed for ${credentials.username} with err 401`);
                } else {
                    this.events.publish(AssessEvents.loginFailure, LoginFailureReason.UNKNOWN);
                    this.logger.warn(`Online login failed for ${credentials.username} with ${JSON.stringify(err, null, 5)}`);
                }
            }
        } else {
            this.logger.debug(`Logging in the user with offline store`);
            this.doOfflineLogin(credentials);
        }
    }

    /**
    * Authenticate External User using Aurora API
    * @param credentials - username and password for Aurora
    * @return - a promise
    */
    public async authenticateExternalUser(credentials: Login): Promise<void> {
        this.events.publish(AssessEvents.login);
        this.logger.debug(`Authenticating for Field Research App user`);
        const params = {
            userName: credentials.username,
            passWord: credentials.password
        };
        if (this.networkStatusService.isConnected()) {
            this.logger.debug(`Logging into ${environment.centralEndpoint} using ng httpclient`);
            try {
                const userInfo = await this.http.get(this.AURORA_URL, { params: params }, true);
                if (userInfo && userInfo['errrorMessageCentral']) {
                    this.events.publish(AssessEvents.loginFailure, LoginFailureReason.ONLINE_UNAUTHORISED);
                    this.logger.warn(`Unable to Authenticate external user ${credentials.username} - ${userInfo['errrorMessageCentral']}`);
                } else {
                    this.logger.info(`External User ${credentials.username} successfully authenticated by Aurora`);
                    this.logger.info(`Attempting login using internal username and password for Central`);
                    credentials.externalUsername = credentials.username;
                    credentials.externalPassword = credentials.password;
                    credentials.username = userInfo['autoUsername'];
                    credentials.password = userInfo['autoPassword'];
                    await this.doLogin(credentials, true);
                }
            } catch (err) {
                if (err.status === 401) {
                    this.events.publish(AssessEvents.loginFailure, LoginFailureReason.ONLINE_UNAUTHORISED);
                    this.logger.warn(`Online login failed for ${credentials.username} with err 401`);
                } else {
                    this.events.publish(AssessEvents.loginFailure, LoginFailureReason.UNKNOWN);
                    this.logger.warn(`Online login failed for ${credentials.username} with ${JSON.stringify(err, null, 5)}`);
                }
            }
        } else {
            // Offline login
            this.logger.debug(`Logging in the external user with offline store`);
            this.doOfflineLogin(credentials);
        }
    }

    /**
     * Offline Login when credentials are provided
     * @param credentials
     */
    public async doOfflineLogin(credentials): Promise<void> {
        const offlineState: OfflineLoginState = await this.offlineStorage.getUserInfo(credentials.username);
        if (!offlineState) {
            this.events.publish(AssessEvents.loginFailure, LoginFailureReason.OFFLINE_CREDS_NOT_FOUND);
            this.logger.warn(`Offline creds not found for ${credentials.username}`);
            return;
        }
        const isValid: boolean = await this.offlineStorage.isValidCredentials(offlineState, credentials.password);
        if (!isValid) {
            this.events.publish(AssessEvents.loginFailure, LoginFailureReason.INCORRECT_OFFLINE_CREDS);
            this.logger.warn(`Incorrect offline creds for ${credentials.username}`);
        } else {
            const userInfo: LoginUserInfo = offlineState.loginInfo;
            userInfo.isOffline = true;
            await this.userStoreService.setLoggedInUserInfo(userInfo);
            this.logger.success(`Offline login successful for ${credentials.username}`);
        }
    }

    public async isLoggedIn(): Promise<boolean> {
        return await this.storage.get(LoginConstants.HAS_LOGGED_IN) === true;
    }

    public async resetLogin(): Promise<void> {
        if (await this.isLoggedIn()) {
            this.logger.debug(`Reseting central login on connection success`);
            const loginInfo = await this.offlineStorage.getUserInfo(await this.userStoreService.getSignInUserName());
            const authHeader = {
                Authorization: 'Basic ' +
                    btoa(`${loginInfo.loginInfo.signInId}:${await this.encryptionService.decrypt(loginInfo.passwordHash)}`)
            };
            await this.http.get(this.AUTH_URL, { headers: authHeader }, true);
        }
    }

    private async logout(): Promise<void> {
        this.storage.remove(LoginConstants.HAS_LOGGED_IN);
        this.logger.success('User logging out.');
        try {
            await this.http.post(this.LOGOUT_URL);
        } catch (e) {
            this.logger.error('Error logging out.', e);
        }
    }
}
