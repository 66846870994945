import {
    Component,
    ChangeDetectorRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import { ProgressStatus } from '@app/models';

@Component({
    selector: 'content-progress',
    templateUrl: './content-progress.component.html',
    styleUrls: ['./content-progress.component.scss'],
})

export class ContentProgressComponent implements OnInit {
    @Input() type; // linear or circle
    @Input() completeOnExtraction;

    @Input() downloadStatus: ProgressStatus;
    @Input() isSubtest;

    processComplete: Boolean = false;
    background = '#ffffff';
    downloadColor = '#005a70';
    extractColor = '#ffb71c';

    @ViewChild('progress', { read: ViewContainerRef }) progress;
    @ViewChild('linearProgress', { read: ViewContainerRef }) linearProgress;
    @ViewChild('progressBg', { read: ViewContainerRef }) progressBg;

    constructor(private changeDetector: ChangeDetectorRef) { }

    @HostBinding('class.linear') isLinear: Boolean = false;
    @HostBinding('class.circle') isCircle: Boolean = true;

    ngOnInit() {
        // if we didn't receive a completeOnExtraction input, default to false
        if (this.completeOnExtraction === undefined) {
            this.completeOnExtraction = false;
        }

        if (this.isSubtest === undefined) {
            this.isSubtest = false;
        }

        // if we didn't revieve a type input, default to cicrle
        if (this.type === undefined) {
            this.type = 'circle';
        }

        if (this.type === 'linear') {
            this.isLinear = true;
            this.isCircle = false;
            this.changeDetector.detectChanges();
        }
    }

    getProgress() {
        const downloadComplete = this.downloadStatus.downloadProgress >= 100;
        if (this.completeOnExtraction) {
            if (downloadComplete) {
                if (this.isExtracting()) {
                    if (this.isLinear) {
                        return this.downloadStatus.extractionProgress / 100;
                    }
                    return this.downloadStatus.extractionProgress;
                }
                return 1;
            } else {
                const progress = this.downloadStatus.downloadProgress;
                return this.isLinear ? progress / 100 : progress;
            }
        }
        const status = this.downloadStatus.downloadProgress;
        return this.isLinear ? status / 100 : status;
    }

    isComplete() {
        const downloadComplete = this.downloadStatus.downloadProgress >= 100;

        if (this.completeOnExtraction) {
            return downloadComplete && !this.isExtracting();
        } else {
            return downloadComplete;
        }
    }

    isDownloading() {
        return this.downloadStatus.downloadProgress < 100;
    }

    isExtracting() {
        const extracting = this.downloadStatus.extractionProgress >= 0 && this.downloadStatus.extractionProgress < 100;
        return (this.downloadStatus.downloadProgress >= 100 && extracting);
    }

    downloadFailed() {
        return this.downloadStatus.downloadFailed;
    }
}
