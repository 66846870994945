import { ItemTypes, TestStates } from '@app/models/consts';
import { IBaseRule } from '@app/rules';
import { RulesFactory } from '@app/rules/rules-factory';
import { Observations } from '@app/interfaces/common';

import { BehaviorSubject } from 'rxjs';

import { Test } from '../battery/test';
import { TestBattery } from '../battery/test-battery';
import { ItemGroup } from './item-group';
import { Question } from './question';
import { SingleCardItemList } from './single-card-item-list';
import { SubtestSummaryPage } from './subtest-summary-page';

/**
 * The model of a subtest that is loaded during administration and review.
 */
export class Subtest {
    averageDuration: number;
    id: string;
    subtestGUID: string;
    subtestInstanceID: string;
    showStimOnReconnect: boolean;

    children: Array<any> = [];
    questions: Array<any> = [];
    itemGroups: Array<ItemGroup> = [];
    singleCardItemLists: Array<SingleCardItemList<ItemGroup>> = [];
    subtestStims: Array<any> = [];

    rawScore: BehaviorSubject<any> = new BehaviorSubject(null);

    title: string;
    subtestName: string;
    displayName: string;

    testDisplayName: string;
    testTitle: string;
    testName: string;
    testGUID: string;
    test: Test;
    battery: TestBattery;
    patientAge: number;

    itemType: string = ItemTypes.subtest;
    baseUrl: string;
    normType: string;
    subtestType: string;

    wasStarted = false;
    wasSummaryScreenVisited = false;
    discontinuedState = false;
    isTimeRemainMsgRequired: boolean;
    disableSwipeback: boolean;

    rules: Array<IBaseRule>;
    contextualEvents: Array<any> = [];
    observations: Array<Observations> = [];

    customData: any = {};

    // TODO:  Create interface for subtestData, so we can ditch the validation
    constructor(subtestData, subtestInstanceID) {
        if (!subtestInstanceID) {
            throw new Error('Subtest has no instance ID.');
        }

        if (!subtestData.title) {
            throw new Error('Subtest has no title.');
        }

        if (!subtestData.displayName) {
            throw new Error('Subtest has no display name.');
        }

        if (!subtestData.testTitle) {
            throw new Error('Subtest has no test title.');
        }

        if (!subtestData.testDisplayName) {
            throw new Error('Subtest has no test display name.');
        }

        if (subtestData.subtestStims && subtestData.subtestStims.length) {
            this.subtestStims = subtestData.subtestStims;
        }
        // construct the rules
        this.rules = RulesFactory.create(subtestData, this);

        if (subtestData.singleCardItemLists && subtestData.singleCardItemLists.length > 0) {
            this.singleCardItemLists = subtestData.singleCardItemLists.map((itemListJson) => {
                return new SingleCardItemList<ItemGroup>(itemListJson, ItemGroup);
            });

            this.children = this.singleCardItemLists.slice();
        }

        if (subtestData.questions && subtestData.questions.length > 0) {
            subtestData.questions.forEach((questionJson) => {
                this.questions.push(Question.createItem(questionJson));
            });
            this.questions.forEach(q => q.setParent(this));
            this.children = this.children.concat(this.questions);

            if (subtestData.itemGroups && subtestData.itemGroups.length > 0) {
                throw new Error('Subtest contains both questions and item groups.');
            }
        } else if (subtestData.itemGroups && subtestData.itemGroups.length > 0) {
            subtestData.itemGroups.forEach((groupJson) => {
                const ig = new ItemGroup(groupJson);
                ig.setParent(this);
                this.itemGroups.push(ig);
            });
            this.children = this.children.concat(this.itemGroups);
        }

        if (this.children.length === 0) {
            throw new Error('Subtest has no questions.');
        }

        this.id = subtestInstanceID;
        this.subtestInstanceID = subtestInstanceID;
        this.averageDuration = subtestData.averageDuration;
        this.showStimOnReconnect = subtestData.reshowStimOnBluetoothReconnect;
        this.testName = subtestData.testTitle;
        this.normType = subtestData.normType;
        this.title = subtestData.title;
        this.subtestGUID = subtestData.subtestGUID;
        this.subtestName = subtestData.subtestName;
        this.displayName = subtestData.displayName;
        this.testGUID = subtestData.testGUID;
        this.testTitle = subtestData.testTitle;
        this.testDisplayName = subtestData.testDisplayName;
        this.subtestType = subtestData.subtestType;
        this.baseUrl = subtestData.baseUrl;
        this.wasStarted = !!subtestData.wasStarted;
        this.wasSummaryScreenVisited = !!subtestData.wasSummaryScreenVisited;
        this.discontinuedState = subtestData.discontinueUsed;
        this.isTimeRemainMsgRequired = subtestData.isTimeRemainMsgRequired;
        this.disableSwipeback = subtestData.disableSwipeback || false;
        const summaryPage = new SubtestSummaryPage(this);
        this.children.push(summaryPage);

        this.questions.forEach(q => q.setParent(this));
        this.contextualEvents = subtestData.contextualEvents;
        this.observations = subtestData.observations;
    }

    public getUniqueId(): string {
        return this.id;
    }

    public getTestDisplayName(): string {
        return this.testDisplayName || this.testName;
    }

    public getTestTitle(): string {
        return this.testTitle;
    }

    public getDisplayName(): string {
        return this.displayName;
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public getPatientAge(): number {
        return this.patientAge || this.battery.getPatientAge().year;
    }

    public setWasStarted(didStart) {
        this.wasStarted = didStart;
    }

    public setSummaryScreenVisited(didVisit) {
        this.wasSummaryScreenVisited = didVisit;
    }

    public state(): string {
        // TODO:  Update to include 'todo' state once we start scoring
        if (this.wasSummaryScreenVisited) {
            return TestStates.complete;
        }
        if (this.wasStarted) {
            return TestStates.inProgress;
        }
        return TestStates.notStarted;
    }

    public getContextualEvents(): Array<any> {
        return this.contextualEvents;
    }

    public getObservations(): Array<Observations> {
        return this.observations;
    }

    public subtestHasObservations(): boolean {
        return !!(this.observations && this.observations.length > 0);
    }

    public setObservationState(observation: Observations) {
        const index = this.observations.findIndex(obj => obj.guid === observation.guid);
        this.observations[index] = observation;
    }

    public serializeForSave() {
        const subtestJson = {
            averageDuration: this.averageDuration,
            customData: this.customData,
            derivedState: {
                state: this.state()
            },
            discontinueUsed: this.discontinuedState,
            displayName: this.getDisplayName(),
            normType: this.normType,
            subtestGUID: this.subtestGUID,
            subtestId: this.id,
            subtestInstanceID: this.subtestInstanceID,
            subtestName: this.subtestName,
            subtestType: this.subtestType,
            testGUID: this.testGUID,
            testDisplayName: this.testDisplayName,
            testName: this.testName,
            testTitle: this.testTitle,
            title: this.title,
            totalRawScore: this.rawScore.value,
            wasStarted: this.wasStarted,
            wasSummaryScreenVisited: this.wasSummaryScreenVisited,
            observations: this.observations,
            rules: this.rules.map((rule) => rule.serializeForSave ? rule.serializeForSave() : null)
        };

        if (this.singleCardItemLists.length > 0) {
            subtestJson['singleCardItemLists'] = this.singleCardItemLists.map(list => list.serializeForSave());
        }

        if (this.itemGroups.length > 0) {
            subtestJson['itemGroups'] = this.itemGroups.map(ig => ig.serializeForSave());
        } else {
            subtestJson['questions'] = this.questions.map(q => q.serializeForSave());
        }
        return subtestJson;
    }

    public restoreSavedData(savedState) {
        this.wasStarted = !!savedState.wasStarted;
        this.wasSummaryScreenVisited = !!savedState.wasSummaryScreenVisited;
        this.discontinuedState = !!savedState.discontinueUsed;
        this.rawScore.next(savedState.totalRawScore);
        this.customData = savedState.customData;
        this.observations = savedState.observations;

        if (savedState.rules) {
            if (savedState.rules.length !== this.rules.length) {
                throw new Error(`Saved data rule and current rules length mismatch: ${savedState.rules}-${this.rules}`);
            }

            savedState.rules.forEach((r, idx) => {
                if (this.rules[idx].restoreSavedData) {
                    this.rules[idx].restoreSavedData(r);
                }
            });
        }

        if (this.singleCardItemLists.length > 0) {
            this.singleCardItemLists.forEach((list, idx) => list.restoreSavedData(savedState.singleCardItemLists[idx]));
        }

        if (this.itemGroups.length > 0) {
            this.itemGroups.forEach((ig, idx) => ig.restoreSavedData(savedState.itemGroups[idx]));
        } else {
            this.questions.forEach((q, idx) => q.restoreSavedData(savedState.questions[idx]));
        }
    }

    public unload() {
        if (this.singleCardItemLists.length > 0) {
            this.singleCardItemLists.forEach((list) => list.unload());
        }

        if (this.itemGroups.length > 0) {
            this.itemGroups.forEach((ig) => ig.unload());
        } else {
            this.questions.forEach((q) => q.unload());
        }
    }
}
