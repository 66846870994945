<ion-grid>
    <ion-row>
        <ion-header>
            <ion-toolbar mode="ios" color="accent">
                <ion-title>{{'components.notes.title' | translate}}</ion-title>
                <ion-buttons slot="start">
                    <ion-button size="small" icon-only (click)="toggleLayers()">
                        <ion-icon name="layers-outline"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button size="small" icon-only (click)="close()">
                        <ion-icon name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
    </ion-row>
    <ion-row class="main-row">
        <ion-col class="main-note-column">
            <ion-row class="notes-row">
            </ion-row>
            <ion-row class="notes-content-row">
                <note #currentNote [data]="currentNoteData" [batteryId]="battery.id" (delete)="confirmDelete($event)">
                </note>
            </ion-row>
            <ion-row class="notes-row"></ion-row>
        </ion-col>
        <ion-col class="notes-col" *ngIf="layersVisible" [@slideInLeftOnEnter] [@slideOutLeftOnLeave]>
            <div class="notes-container">
                <ion-fab vertical="top" horizontal="center" slot="fixed">
                    <ion-fab-button (click)="createNewNote()" color="new">
                        <ion-icon name="add"></ion-icon>
                    </ion-fab-button>
                </ion-fab>
                <ion-content>
                    <ion-list>
                        <ion-item *ngFor="let note of notes" lines="none">
                            <ion-card class="note-thumbnail" (click)="selectNote(note)">
                                <note #noteThumbnail [data]="note" [shouldScale]="true" [showNoteOptions]="false"
                                    [batteryId]="battery.id"></note>
                            </ion-card>
                        </ion-item>
                    </ion-list>
                </ion-content>
            </div>
        </ion-col>
    </ion-row>
</ion-grid>