export class Test {
    title: string;
    displayName: string;
    testGUID: string;
    gradeLevels: Array<any>;
    testScoringType: string;
    normType: string;

    getSubtestRefs: Function;
    getPatientAge: Function;
    getPatientGender: Function;
    getBatteryStartDate: Function;

    constructor(json, getSubtestRefs: Function, getPatientAge: Function, getPatientGender: Function, getBatteryStartDate: Function) {
        this.title = json.title;
        this.displayName = json.displayName;
        this.testGUID = json.testGUID;
        this.gradeLevels = json.gradeLevels;
        this.testScoringType = json.testType;
        this.normType = json.normType;

        this.getSubtestRefs = getSubtestRefs;
        this.getPatientAge = getPatientAge;
        this.getPatientGender = getPatientGender;
        this.getBatteryStartDate = getBatteryStartDate;
    }

    public serializeForSave(): any {
        return {
            testName: this.title,
            displayName: this.displayName,
            testGUID: this.testGUID,
        };
    }

    public restoreSavedData(savedState) {
        // TODO: restore things once we have things to restore (grade level, custom data, observations, etc.)
    }
}
