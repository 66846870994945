<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>{{'rules.overlays.message.discontinueRuleTriggered' | translate}}</ion-title>
        <ion-buttons slot="end">
            <ion-button size="small" icon-only (click)="onDismiss('cancel')">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<section class="description">
    <label>{{ruleData.description}}</label>
</section>
<div class="content-wrapper">
    <section class="content-row">
        <ion-col>
            <ng-container *ngIf="discontinueRuleType === 'consecutiveZerosDiscontinue'">
                <ion-label>
                    {{'rules.overlays.message.discontinueLastItemPlural' | translate:{'zeros':ruleData.numZeros} }}
                </ion-label>
                <ul class="triggered-items">
                    <li *ngFor="let rule of ruleData.dcItems" lines="none">
                        <ion-label>
                            {{rule.title}}: {{rule.score}} {{'rules.overlays.label.points' | translate }}
                        </ion-label>
                    </li>
                </ul>
            </ng-container>
        </ion-col>
    </section>
</div>
<section class="bottom-message-row">
    <ion-label>{{'rules.overlays.message.discontinueRuleBottomMessageLabel' | translate}}</ion-label>
    <ion-icon color="secondary" name="close-circle"></ion-icon>
    <ion-label>{{'rules.overlays.message.discontinueRuleBottomMessageLabel2' | translate}}</ion-label>
</section>
<section class="button-row">
    <ion-button (click)="onDismiss('testLimits')" color="secondary" class="modal-btn outer-button" fill="outline"
        shape="round">
        {{'rules.overlays.label.testLimits' | translate}}
    </ion-button>
    <ion-button (click)="onDismiss('discontinue')" color="secondary" class="modal-btn" shape="round">
        {{'rules.overlays.label.discontinue' | translate}}
    </ion-button>
</section>