export const ItemTypes = {
    administrable: 'administrable',
    itemGroup: 'itemGroup',
    scil: 'singleCardItemList',
    subtest: 'subtest',
    subtestSummary: 'subtestSummary',
    trial: 'trial'
};

export const QuestionTypes = {
    demo: 'Demo',
    sample: 'Sample',
    score: 'Score'
};

export const TestStates = {
    complete: 'Complete',
    inProgress: 'In-Progress',
    notStarted: 'Not Started',
    toDo: 'To Do'
};

export const HTTP_HEADERS: any = {
    'X-Requested-With': 'Assess/2.0',
};

export const ScoreDisplayTypes = {
    hide: 'hide',
    showScore: 'showScore',
    showUnscoredTodo: 'showUnscoredTodo',
    showLetteredScore: 'showLetteredScore'
};

export enum QuestionMode {
    administration,
    scoring,
    preview
}

export enum DisconnectionType {
    background = 'App backgrounded',
    appSwitching = 'App entering switch mode',
    disconnect = 'Bluetooth disconnect',
    home = 'Visited dashboard'
}


export enum InvalidationSource {
    CLIENT = 'c', PRACTITIONER = 'p'
}
