import { Component, OnInit } from '@angular/core';

import { Observations } from '@app/interfaces/common';
import { Subtest } from '@app/models';

import { NavParams } from '@ionic/angular';

@Component({
  selector: 'observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss'],
})
export class ObservationsComponent implements OnInit {
    subtest: Subtest;
    observations: Array<Observations>;
    testTitle: string;
    subtestTitle: string;

    constructor( private navParams: NavParams ) {
        this.subtest = this.navParams.get('subtest');
        this.observations = this.subtest.observations;
        this.subtestTitle = this.subtest.getDisplayName();
        this.testTitle = this.subtest.getTestDisplayName();
        if (this.observations) {
            this.observations.forEach((observation) => {
                if (observation.type === 'Boolean') {
                    observation.isChecked = !!observation.isChecked;
                }
            });
        }
    }

    ngOnInit() { }

    public toggleChecked(observation: Observations) {
        observation.isChecked = !observation.isChecked;
        this.subtest.setObservationState(observation);
    }
}

