import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';

import { BatteryService } from '@app/core/providers/battery.service';
import { Note } from '@app/interfaces/common';
import { Administrable, ItemGroup, SingleCardItemList, Subtest, TestBattery } from '@app/models';
import { ItemTypes } from '@app/models/consts';
import { SubtestSummaryPage } from '@app/models/subtest/subtest-summary-page';
import { TranslateService } from '@app/shared';

import { AlertController, ModalController } from '@ionic/angular';

import { slideInLeftOnEnterAnimation, slideOutLeftOnLeaveAnimation } from 'angular-animations';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

import { NoteComponent } from './note/note.component';

// gsap > v3 we need to register plugins
gsap.registerPlugin(Draggable);

export enum NoteTypes {
    GENERAL = 'General',
    ITEM = 'Item',
    SUBTEST = 'Subtest'
}

@Component({
    selector: 'notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    animations: [
        slideInLeftOnEnterAnimation({ duration: 300 }), slideOutLeftOnLeaveAnimation({ duration: 300 })
    ]
})
export class NotesComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('currentNote', { static: true }) currentNote: NoteComponent;
    @ViewChildren('noteThumbnail') noteThumbnails: QueryList<NoteComponent>;

    battery: TestBattery;
    currentItem: any;
    currentNoteData: Note;
    notes: Array<Note>;
    dismissEmitter: EventEmitter<void>;

    shouldShowCanvas = true;
    shouldShowGrid = false;
    layersVisible = true;
    draggableNote: any;

    static getModalConfig(
        battery: TestBattery,
        dismissEmitter: EventEmitter<void>,
        currentItem?: Administrable | ItemGroup | SingleCardItemList<any> | SubtestSummaryPage | Subtest
    ) {
        return {
            component: NotesComponent,
            cssClass: 'notes',
            componentProps: {
                battery,
                dismissEmitter,
                currentItem
            },
            backdropDismiss: false
        };
    }

    constructor(
        private modalController: ModalController,
        private alertController: AlertController,
        private translate: TranslateService,
        private batteryService: BatteryService,
        private el: ElementRef
    ) {
        this.getNewNote().then((note) => {
            this.currentNoteData = note;
        });
    }

    ngOnInit() {
        this.notes = this.battery.getNotes() || [];
        this.notes.forEach((n) => {
            n.opacity = '1';
        });
    }

    ngAfterViewInit() {
        this.draggableNote = Draggable.create(this.currentNote.hostElement.nativeElement, {
            type: 'top,left',
            bounds: '.main-row',
            trigger: '.drag-handle'
        });
        gsap.set(this.currentNote.hostElement.nativeElement, { left: 115, top: -75 });

        this.dismissEmitter.subscribe(() => {
            if (this.currentNote.hasWriting()) {
                this.currentNote.save();
                if (this.notes.indexOf(this.currentNoteData) === -1) {
                    this.notes.unshift(this.currentNote.data);
                }
            }
            this.saveNotes();
        });
    }

    ngOnDestroy() {
        this.el.nativeElement.remove();
        this.dismissEmitter.unsubscribe();
    }

    toggleLayers() {
        this.layersVisible = !this.layersVisible;
    }

    async close() {
        await this.modalController.dismiss();
    }

    async confirmDelete(note: Note) {
        // prompt the user to confirm
        const alert = await this.alertController.create({
            backdropDismiss: false,
            header: await this.translate.get('components.notes.delete.title').toPromise(),
            message: await this.translate.get('components.notes.delete.message').toPromise(),
            buttons: [
                {
                    text: await this.translate.get('shared.label.cancel').toPromise()
                },
                {
                    text: await this.translate.get('shared.label.delete').toPromise(),
                    handler: () => this.deleteNote(note)
                }
            ]
        });
        await alert.present();
    }

    selectNote(note: Note) {
        this.currentNote.capture();
        this.currentNote.save();

        // if there was something drawn on the current note, add it to notes array, otherwise hose it
        if (this.currentNote.hasWriting() && this.notes.indexOf(this.currentNote.data) === -1) {
            this.notes.unshift(this.currentNote.data);
            this.saveNotes();
        }
        // set the data to the new note
        this.currentNoteData = note;
    }

    private async createNewNote() {
        this.currentNote.capture();

        // if the current note was existing note, or it's a new note with capture data, save it
        if (this.currentNote.hasWriting()) {
            this.currentNote.save();
            if (this.notes.indexOf(this.currentNote.data) === -1) {
                this.notes.unshift(this.currentNote.data);
                this.saveNotes();
            }
        }

        // set currentNoteData to a new Note object
        this.currentNoteData = await this.getNewNote();
    }

    private async getNewNote(): Promise<Note> {
        const titles = await this.getNoteTitles();
        const type = this.getDefaultNoteType();

        return {
            type,
            name: titles[type.toLowerCase()],
            capture: [],
            important: false,
            opacity: '1',
            additionalProperties: titles
        };
    }

    private saveNotes() {
        this.battery.saveNotes(this.notes);
        this.batteryService.saveBattery(this.battery);
    }

    private deleteNote(note: Note) {
        const noteIdx = this.notes.indexOf(note);
        if (noteIdx !== -1) {
            this.notes.splice(noteIdx, 1);
        }
        this.currentNote.clear();
        this.createNewNote();
    }

    private async getNoteTitles(): Promise<any> {
        const props = {
            general: await this.translate.get('components.notes.types.general').toPromise(),
            subtest: await this.translate.get('components.notes.types.subtest').toPromise(),
            item: await this.translate.get('components.notes.types.item').toPromise()
        };

        if (this.currentItem) {
            const type = this.currentItem.itemType;
            if (type === ItemTypes.subtest) {
                props.subtest = `${this.currentItem.getTestDisplayName()} ${this.currentItem.getDisplayName()}`;
            } else if (type === ItemTypes.subtestSummary) {
                props.subtest = this.currentItem.getDisplayName();
            } else {
                props.subtest = `${this.currentItem.getTestDisplayName()} ${this.currentItem.getSubtestName()}`;
                props.item = `${this.currentItem.getTestDisplayName()} ${this.currentItem.getDisplayName()}`;
            }
        }
        return props;
    }

    private getDefaultNoteType(): string {
        if (this.currentItem) {
            const type = this.currentItem.itemType;
            if (type === ItemTypes.subtest || type === ItemTypes.subtestSummary || type === ItemTypes.itemGroup) {
                return NoteTypes.SUBTEST;
            }
            return NoteTypes.ITEM;
        }
        return NoteTypes.GENERAL;
    }
}
