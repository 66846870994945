import { Subject, Subscription } from 'rxjs';

export interface ArrayEvent<T> {
    affectedItem?: T;
    affectedItems?: T[];
    affectedIndex?: number;
    fromIndex?: number;
    previousValue?: T;
    newItem?: T;
    newItems?: T[];
    operation: 'add' | 'remove' | 'modify' | 'splice' | 'replace' | 'move';
}

export class ObservableArray<T> {
    private contents: T[];
    private event: Subject<ArrayEvent<T>> = new Subject();

    constructor(initialContents: any[] = null) {
        this.contents = initialContents ? initialContents.slice() : [];
    }

    subscribe(next?: (value: ArrayEvent<T>) => void, error?: (error: any) => void, complete?: () => void): Subscription {
        return this.event.subscribe(next, error, complete);
    }

    unsubscribe() {
        this.event.unsubscribe();
    }


    get length() {
        return this.contents.length;
    }

    value(): T[] {
        return this.contents.slice();
    }

    toString(): string {
        return this.contents.toString();
    }
    toLocaleString(): string {
        return this.contents.toLocaleString();
    }

    pop() {
        this.splice(this.length - 1, 1);
    }

    splice(index: number, length: number): T[] {
        const value = this.contents.splice(index, length);

        this.event.next({
            operation: 'splice',
            affectedItems: value,
            affectedIndex: index
        });

        return value;
    }

    push(...items: T[]): number {
        for (const item of items) {
            this.contents.push(item);
            this.event.next({
                operation: 'add',
                affectedItem: item,
                affectedIndex: this.contents.length - 1
            });
        }
        return this.length;
    }

    insertAt(index: number, value: T) {
        this.contents.splice(index, 0, value);
        this.event.next({
            operation: 'add',
            affectedItem: value,
            affectedIndex: index
        });
    }

    setAt(index: number, value: T) {
        const oldValue = this.contents[index];

        this.contents[index] = value;
        this.event.next({
            operation: 'modify',
            affectedItem: value,
            affectedIndex: index,
            previousValue: oldValue
        });
    }

    getAt(index): T {
        return this.contents[index];
    }

    first(): T {
        return this.contents[0];
    }

    last(): T {
        return this.contents ? this.contents[this.contents.length - 1] : null;
    }

    setValue(newContents: T[]) {
        const oldContents = this.contents;

        this.contents = newContents ? newContents.slice() : [];
        this.event.next({
            operation: 'replace',
            affectedItems: oldContents,
            newItems: this.contents.slice()
        });
    }

    moveItemFrom(fromIndex: number, toIndex: number) {
        const item = this.moveFrom(fromIndex, toIndex);

        this.event.next({
            operation: 'move',
            affectedItem: item,
            fromIndex: fromIndex,
            affectedIndex: this.contents.indexOf(item)
        });
    }

    private moveFrom(fromIndex: number, toIndex: number) {
        const item = this.contents[fromIndex];
        if (fromIndex < toIndex) {
            this.contents.splice(toIndex, 0, item);
            this.contents.splice(fromIndex, 1);
        } else {
            this.contents.splice(fromIndex, 1);
            this.contents.splice(toIndex, 0, item);
        }
        return item;
    }

}
