import {
    Component,
    ComponentFactoryResolver,
    HostListener,
    NgZone,
    OnInit,
    ViewEncapsulation,
    ViewContainerRef,
    ViewChild,
    ComponentRef
} from '@angular/core';
import { Router } from '@angular/router';

import { NotificationData, NotificationPopoverComponent } from '@app/components';
import {
    AuthService,
    ContentDownloaderService,
    ConsoleLogModal,
    DeployService,
    DeviceConnectivityService,
    Events,
    HttpService,
    IdleTimeoutService,
    Logger,
    LoggingService,
    PlatformService,
    RemoteSessionService,
    SyncService,
    TestStateService,
    ToastService
} from '@app/core';
import { AssessEvents, BatteryEvents, ConnectionEvents, ConsoleLogEvents, MessageTypes, SyncEvents, TranslateService } from '@app/shared';
import { environment } from '@appenv';
import { IdleTimeout } from '@app/interfaces/common';

import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import * as qs from 'querystring';
import { timer } from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';
import { RemoteSessionComponent } from './components/remote-session/remote-session.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    logger: Logger;

    private shouldPersistDisconnectedToast = false;
    // 30 minutes
    private idleTimeout: IdleTimeout = { idle: 1800, timeout: 1, override: 60 };
    private isDownloadingContent = false;
    private isSyncing = false;
    private backgroundTime = null;

    public isConnected = false;
    public remoteVideoComponentRef: ComponentRef<any> = null;
    public isPractitionerMode = false;
    public isRemoteSession = false;

    @ViewChild('appcontainer', { static: false, read: ViewContainerRef }) appContainer;

    constructor(
        private events: Events,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private loggingService: LoggingService,
        private translateService: TranslateService,
        private modalController: ModalController,
        private deploy: DeployService,
        private platformService: PlatformService,
        private device: Device,
        private popoverCtrl: PopoverController,
        private contentService: ContentDownloaderService,
        private router: Router,
        private alertController: AlertController,
        private translate: TranslateService,
        private httpService: HttpService,
        private toastService: ToastService,
        private connectivityService: DeviceConnectivityService,
        private authService: AuthService,
        private syncService: SyncService,
        private testStateService: TestStateService,
        private idleTimeoutService: IdleTimeoutService,
        private remoteSessionService: RemoteSessionService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private zone: NgZone
    ) {
        this.logger = this.loggingService.getLogger('AppComponent');
        this.initializeApp();
    }

    async ngOnInit() { }

    async initializeApp() {
        this.platform.ready().then(async () => {
            await this.initLanguage();
            this.isPractitionerMode = await this.platformService.isPractitionerMode();
            this.isRemoteSession = !this.isPractitionerMode;

            if (this.platformService.isNative()) {
                // hide the status bar
                this.statusBar.hide();
                // check for and download any app updates while on the splash screen
                // wait on this, so it's done by the time we load the login page
                this.deploy.checkForUpdates().then((didUpdate) => {
                    if (!didUpdate) {
                        this.splashScreen.hide();
                    }
                }).catch((e) => {
                    this.logger.error(`Error while trying to check for deploy updates ${JSON.stringify(e, null, 5)}`);
                    this.splashScreen.hide();
                });
            }

            this.connectivityService.init();

            const deviceInfo = {
                cordova: this.device.cordova,
                model: this.device.model,
                platform: this.device.platform,
                uuid: this.device.uuid,
                version: this.device.version,
                manufacturer: this.device.manufacturer,
                isSimulator: this.device.isVirtual,
                serial: this.device.serial
            };
            this.logger.info(`Device info:`, deviceInfo);

            this.events.subscribe(ConsoleLogEvents.show, async () => await this.showConsoleLogWindow());
            this.events.subscribe(AssessEvents.notify, async (data) => await this.showNotification(data));

            if (this.isPractitionerMode) {
                // stop monitoring inactivity on logout, during sync or during content download
                this.events.subscribe(AssessEvents.logout, () => {
                    this.idleTimeoutService.stopWatching();
                });
                this.events.subscribe(AssessEvents.contentDownloadStarted, () => {
                    this.isDownloadingContent = true;
                    this.idleTimeoutService.stopWatching();
                });
                this.events.subscribe(SyncEvents.running, () => {
                    this.isSyncing = true;
                    this.idleTimeoutService.stopWatching();
                });

                // keep track of sync and content download status
                this.events.subscribe(AssessEvents.contentDownloadEnded, () => {
                    this.isDownloadingContent = false;
                });
                this.events.subscribe(SyncEvents.ended, () => {
                    this.isSyncing = false;
                });

                // keep track of when the app enters the background and logout upon resume if timeout is met or surpassed
                this.events.subscribe(AssessEvents.paused, time => this.backgroundTime = time);
                this.events.subscribe(AssessEvents.resumed, async (time) => {
                    const secondsDiff = Math.floor((time - this.backgroundTime) / 1000);
                    this.logger.debug(`App was backgrounded for ${secondsDiff} seconds.`);
                    if (secondsDiff >= this.idleTimeoutService.getTimeoutValues().idle && await this.authService.isLoggedIn()) {
                        this.idleTimeoutService.stopWatching();
                        this.handleInactivityTimeout();
                    }
                });

                // start monitoring inactivity on login or when both content download and sync are completed
                const appEvents = [this.events.observe(AssessEvents.messageReceived)];
                this.events.subscribeAll([AssessEvents.loginSuccess, AssessEvents.contentDownloadEnded, SyncEvents.ended], async () => {
                    if (!this.isSyncing && !this.isDownloadingContent) {
                        this.idleTimeoutService.startWatching(this.idleTimeout, appEvents, await this.platformService.overrideTimeout());
                    }
                });

                this.idleTimeoutService.onTimeout().subscribe(() => this.handleInactivityTimeout());
            }

            this.events.subscribe(SyncEvents.ended, hasError => {
                if (hasError) {
                    this.toastService.showToast('dashboard.message.sync.failure.upload', 'danger');
                }
            });

            this.events.subscribe(BatteryEvents.behaviorShown,
                beh => this.shouldPersistDisconnectedToast = beh.shouldHandleInterruptions());
            this.events.subscribe(BatteryEvents.behaviorHidden, () => this.shouldPersistDisconnectedToast = false);
            this.events.subscribe(AssessEvents.goHome, () => this.toastService.dismissTop());

            // device connectivity subscriptions
            this.events.subscribe(ConnectionEvents.connected, async (showToast) => {
                this.logger.info('State has changed to connected.');

                this.isRemoteSession = true;
                this.isConnected = true;

                if (this.isPractitionerMode && showToast) {
                    await this.toastService.showToast('shared.connectivity.message.connected');
                    // if the devices disconnected because wifi was turned off, prompt the user to enable
                    this.connectivityService.isWifiEnabled(async (isEnabled) =>
                        await this.connectivityService.handleWifiDisabled(isEnabled));
                }
            });

            this.events.subscribe(ConnectionEvents.disconnected, async (showToast) => {
                this.logger.info('State has changed to disconnected.');

                if (await this.authService.isLoggedIn()) {
                    if (this.isPractitionerMode) {
                        await this.toastService.showToast(
                            'shared.connectivity.message.notConnected', 'info', this.shouldPersistDisconnectedToast);
                    }
                    this.connectivityService.beginBroadcasting();
                }

                // if the devices disconnected because wifi was turned off, prompt the user to enable
                this.connectivityService.isWifiEnabled(async (isEnabled) => await this.connectivityService.handleWifiDisabled(isEnabled));
            });

            this.logger.success('Environment is ', environment);
            this.syncService.initEvents();
            await this.contentService.initStatus();

            if (this.isPractitionerMode) { // this is when we went home or dashboard, while disconnected
                // we need a way to mark the app to know that there is a stim lingering on the client still
                this.events.subscribe(MessageTypes.SYNC_MESSAGE_FROM_CLIENT,
                    (payload, uniqueId) => this.testStateService.setLastKnownStim(uniqueId));
            }
        });
    }

    /**
     * Resolves device lang using either ECMA Internationization API if available or the cordova globalization
     * plugin. Refer: https://cordova.apache.org/news/2017/11/20/migrate-from-cordova-globalization-plugin.html
     */
    async initLanguage(): Promise<string> {
        let appLang = 'en';
        if (window['Intl'] && typeof window['Intl'] === 'object') {
            this.logger.debug(`Getting language using ecma intl api`);
            appLang = navigator.language;
        } else {
            try {
                this.logger.debug(`Getting language using cordova plugin`);
                const langObj = await new Promise<any>((res, rej) => {
                    // this returns { "value": "en-US" }
                    navigator['globalization'].getPreferredLanguage((lang: any) => {
                        res(lang);
                    }, () => {
                        rej('Error in getting lang from cordova plugin');
                    });
                });
                appLang = langObj.value;
            } catch (e) {
                this.logger.error(`Could not get preferred language from the plugin`, e);
            }
        }
        this.translateService.setDefaultLang('en');
        // this is for local lang switching in the url in chrome. Just add `?lang=<lang_country>`
        const params = qs.parse(window.location.search.substring(1));
        appLang = params['lang'] as string || appLang;
        this.logger.success(`Got device preferred lang as ${appLang}`);
        this.translateService.use(appLang);
        this.events.subscribe(AssessEvents.selectLang, (lang) => {
            this.logger.info(`Selected lang ${lang}`);
            this.translateService.use(lang);
        });
        this.events.subscribe(AssessEvents.selectDefaultLang, () => {
            this.logger.info(`Reseting lang to platform lang`);
            this.translateService.use(appLang);
        });
        return appLang;
    }

    async showConsoleLogWindow() {
        const modal = await this.modalController.create({
            component: ConsoleLogModal,
            cssClass: 'full-screen',
            backdropDismiss: false
        });
        return await modal.present();
    }

    async showNotification(data: NotificationData) {
        const msg = data.message;
        const ev = data.event;
        const props = data.props;
        const popover = await this.popoverCtrl.create({
            component: NotificationPopoverComponent,
            componentProps: {
                message: msg
            },
            event: ev,
            animated: true,
            showBackdrop: true,
            backdropDismiss: true,
            ...props
        });
        return await popover.present();
    }

    private handleInactivityTimeout() {
        const dismissTop = (top) => {
            if (top) {
                top.dismiss();
            }
        };
        this.alertController.getTop().then(dismissTop);
        this.modalController.getTop().then(dismissTop);
        this.popoverCtrl.getTop().then(dismissTop);
        this.events.publish(AssessEvents.logout);
        this.zone.run(() => this.router.navigate(['/login']));
        this.toastService.dismissTop();
        this.showLoggedOutAlert();
    }

    private async showLoggedOutAlert() {
        const alert = await this.alertController.create({
            header: await this.translateService.get('shared.inactivity.header').toPromise(),
            message: await this.translateService.get('shared.inactivity.loggedOut').toPromise(),
            buttons: [await this.translate.get('shared.label.ok').toPromise()],
            backdropDismiss: false
        });
        await alert.present();
    }

    @HostListener('document:resign')
    onResign() {
        this.events.publish(AssessEvents.resign);
    }

    @HostListener('document:active')
    onActive() {
        this.events.publish(AssessEvents.active);
    }
}
