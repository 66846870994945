import { Injectable } from '@angular/core';

import { Subtest, SubtestRef, TestBattery } from '@app/models';
import { AssessEvents, ContentQuery } from '@app/shared';

import { Storage } from '@ionic/storage';

import { BehaviorSubject } from 'rxjs';

import { Events } from './events.service';

const ACTIVE_BATTERY_ID = 'ACTIVE_BATTERY';
const ACTIVE_SUBTEST_INSTANCE_ID = 'ACTIVE_SUBTEST_INSTANCE_ID';
const ACTIVE_ITEM_ID = 'ACTIVE_ITEM_ID';

@Injectable()
export class TestStateService {
    public currentBattery: BehaviorSubject<TestBattery> = new BehaviorSubject(null);
    public currentSubtest: BehaviorSubject<Subtest> = new BehaviorSubject(null);
    public currentItem: BehaviorSubject<any> = new BehaviorSubject(null);
    private lastKnownStim: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private events: Events,
        private storage: Storage
    ) {
        this.events.subscribe(AssessEvents.login, async () => {
            await this.storage.remove(ACTIVE_BATTERY_ID);
            await this.storage.remove(ACTIVE_SUBTEST_INSTANCE_ID);
            await this.storage.remove(ACTIVE_ITEM_ID);
        });
    }

    public async setCurrentBattery(battery: TestBattery) {
        this.currentBattery.next(battery);
        await this.storage.set(ACTIVE_BATTERY_ID, battery.id);

        // find the subtest in progress or the first one
        const subtestId = battery.getSubtestInProgressId();
        const ref: SubtestRef = subtestId ? battery.subtestRefs.find(sr => sr.subtestInstanceID === subtestId) : battery.subtestRefs[0];
        await this.setCurrentSubtest(ref.loadModel(battery));

        // find the current item or set it to null
        const itemId = battery.getItemInProgressId();
        const item = itemId ? ContentQuery.findOne(this.currentSubtest.value, it => it.getUniqueId() === itemId) : null;
        this.setCurrentItem(item);
    }

    public async setCurrentSubtest(subtest: Subtest) {
        const battery: TestBattery = this.currentBattery.value;
        const ref: SubtestRef = battery.subtestRefs.find(sr => sr.subtestInstanceID === subtest.subtestInstanceID);

        if (!ref) {
            throw new Error('Attempting to set current subtest that is not a part of the active battery.');
        }
        this.currentSubtest.next(subtest);
        await this.storage.set(ACTIVE_SUBTEST_INSTANCE_ID, subtest.subtestInstanceID);
    }

    public async setCurrentItem(currentItem) {
        this.currentItem.next(currentItem);
        if (currentItem) {
            await this.storage.set(ACTIVE_ITEM_ID, currentItem.getUniqueId());
        } else {
            await this.storage.remove(ACTIVE_ITEM_ID);
        }
    }

    public async getCurrentBatteryId(): Promise<string> {
        return await this.storage.get(ACTIVE_BATTERY_ID);
    }

    public async getCurrentSubtestId(): Promise<string> {
        return await this.storage.get(ACTIVE_SUBTEST_INSTANCE_ID);
    }

    public async getCurrentItemId(): Promise<string> {
        return await this.storage.get(ACTIVE_ITEM_ID);
    }

    public getCurrentBattery(): TestBattery {
        return this.currentBattery.value;
    }

    public getCurrentSubtest(): Subtest {
        return this.currentSubtest.value;
    }

    public async getCurrentItem() {
        return this.currentItem.value;
    }

    public async unloadCurrentBattery(): Promise<void> {
        this.currentBattery.next(null);
        await this.storage.remove(ACTIVE_BATTERY_ID);
        this.currentSubtest.next(null);
        await this.storage.remove(ACTIVE_SUBTEST_INSTANCE_ID);
        this.currentItem.next(null);
        await this.storage.remove(ACTIVE_ITEM_ID);
    }

    public getLastKnownStim(): BehaviorSubject<string> {
        return this.lastKnownStim;
    }

    public setLastKnownStim( uniqueId: string) {
        this.lastKnownStim.next(uniqueId);
    }
}
