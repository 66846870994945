import { Component, OnInit, ViewChild, ViewEncapsulation, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService, DeviceConnectivityService, Events, PlatformService, Logger, LoggingService } from '@app/core';
import { LoginFailureReason } from '@app/models';
import { TranslateService, AssessEvents } from '@app/shared';
import { environment } from '@appenv';

import { LoadingController } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Storage } from '@ionic/storage';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [FormBuilder, Keyboard],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
    submitted = false;
    lockoutTime: Number;
    IS_LOCKED_OUT = false;
    NUM_LOGIN_ATTEMPTS = 0;
    MAX_ATTEMPTS = 5;
    LOGIN_ATTEMPT_DURATION = 60000; // 1m
    unauthorized = false;
    acctPrelock = false;
    acctLock = false;
    loading = null;
    isNative = true;
    unauthorizedMessage = '';
    keyboardShown = null;

    @ViewChild('password') passwordInput;
    @ViewChild('username') usernameInput;

    public loginForm: FormGroup;
    private logger: Logger;
    keyboardVisible: boolean;

    constructor(
        public authService: AuthService,
        private formBuilder: FormBuilder,
        public router: Router,
        public loadingCtrl: LoadingController,
        private zone: NgZone,
        private keyboard: Keyboard,
        private storage: Storage,
        private translate: TranslateService,
        private appPreferences: AppPreferences,
        private platform: PlatformService,
        private events: Events,
        private loggingService: LoggingService,
        private platformService: PlatformService,
        private connectivityService: DeviceConnectivityService
    ) {
        this.loginForm = this.formBuilder.group({
            username: new FormControl({ value: '', disabled: this.acctLock }, Validators.required),
            password: new FormControl({ value: '', disabled: this.acctLock }, Validators.required)
        });
        this.isNative = this.platform.isNative();
        if (this.isNative) {
            this.keyboardVisible = keyboard.isVisible;
        }
        this.initEvents();
        this.logger = this.loggingService.getLogger('LoginComponent');
    }

    private initEvents() {
        window.addEventListener('keyboardDidShow', (event) => {
            this.keyboardVisible = true;
        });

        window.addEventListener('keyboardDidHide', (event) => {
            this.keyboardVisible = false;
        });
        this.events.subscribe(AssessEvents.loginSuccess, async () => {
            // clear the login form
            this.loginForm.reset();

            this.zone.run(async () => {
                const isPractitioner = await this.platform.isPractitionerMode();
                if (isPractitioner) {
                    this.logger.info('Logging in as practitioner device');
                    await this.router.navigate(['dashboard']);
                } else {
                    this.logger.info('Logging in as stim device');
                    await this.router.navigate(['stim']);
                }
                this.loadingCtrl.dismiss();
            });
            // reset the form error messaging flags
            this.unauthorized = false;
            this.acctPrelock = false;
            this.acctLock = false;
            // reset the login attempts
            this.NUM_LOGIN_ATTEMPTS = 0;
            this.storage.set('loginAttempts', this.NUM_LOGIN_ATTEMPTS);
        });

        this.events.subscribe(AssessEvents.loginFailure, async (reason: LoginFailureReason) => {
            this.loadingCtrl.dismiss();
            await this.handleLoginAttempt();

            if ((reason === LoginFailureReason.ONLINE_UNAUTHORISED || reason === LoginFailureReason.INCORRECT_OFFLINE_CREDS)
                && !this.acctPrelock && !this.acctLock) {
                const key: string = reason === LoginFailureReason.ONLINE_UNAUTHORISED ?
                    'auth.login.message.unauthorized' : 'auth.login.offline.message.unauthorized';
                this.unauthorizedMessage = await this.translate.get(key).toPromise();
                this.unauthorized = true;
            } else if (reason === LoginFailureReason.OFFLINE_CREDS_NOT_FOUND || reason === LoginFailureReason.UNKNOWN) {
                const key: string = reason === LoginFailureReason.OFFLINE_CREDS_NOT_FOUND ?
                    'auth.login.offline.message.notfound' : 'auth.login.message.unknown';
                this.unauthorizedMessage = await this.translate.get(key).toPromise();
                this.unauthorized = true;
            }
        });
    }

    ngOnInit() {
        if (this.isNative) {
            this.keyboard.hideFormAccessoryBar(true);
            this.keyboardShown = this.keyboard.onKeyboardWillShow();
            // TODO: re-style the login form to fit in the available space. see eclk-49082
        }

        this.storage.ready().then(() => {
            if (!this.storage.get('loginAttempts')) {
                this.storage.set('loginAttempts', this.NUM_LOGIN_ATTEMPTS);
            } else {
                this.storage.get('loginAttempts').then(val => {
                    this.NUM_LOGIN_ATTEMPTS = val;
                });
            }
        });
    }

    public async onLogin() {
        this.submitted = true;
        this.unauthorized = false;
        await this.presentLoader();
        const isExternalAuthentication = await this.platformService.isExternalAuthentication();
        if (environment.isFr && isExternalAuthentication) {
            await this.authService.authenticateExternalUser(this.loginForm.value);
        } else {
            await this.authService.doLogin(this.loginForm.value, false);
        }
    }

    /*
    * Keep track of login attempts, and lock account as needed
    */
    async handleLoginAttempt() {
        this.NUM_LOGIN_ATTEMPTS++;
        await this.storage.set('loginAttempts', this.NUM_LOGIN_ATTEMPTS);

        if (!this.lockoutTime) {
            this.lockoutTime = Date.now();
        }

        // TODO: Need to store separate refs depending on username?
        const attempts: number = await this.storage.get('loginAttempts');

        if (attempts + 1 === this.MAX_ATTEMPTS) {
            this.unauthorized = false;
            this.acctPrelock = true;
        }
        if (attempts >= this.MAX_ATTEMPTS) {
            this.unauthorized = false;
            this.acctPrelock = false;
            this.acctLock = true;
            // lock them out
            this.IS_LOCKED_OUT = true;
            setTimeout(() => {
                this.IS_LOCKED_OUT = false;
                // unlock
                this.acctLock = false;
            }, 60000 * 5);

            // reset the attempt counter
            this.NUM_LOGIN_ATTEMPTS = 0;
            this.storage.set('loginAttempts', this.NUM_LOGIN_ATTEMPTS);
        }
    }

    async presentLoader() {
        const loading = await this.loadingCtrl.create({
            message: await this.translate.get('auth.login.message.loginSpinner').toPromise(),
            spinner: 'dots',
            translucent: true
        });
        await loading.present();
    }

    onFocus(evt) {
        evt.currentTarget.value = '';
    }

    onKeypress(evt) {
        const username = this.usernameInput.value;
        const password = this.passwordInput.value;
        if (evt.keyCode === 13) {
            this.passwordInput.setFocus();
            if (username !== '' && password !== '') {
                this.onLogin();
            }
        }
    }

    async openAppSettings() {
        await this.appPreferences.show();
    }
}
