import { Animation } from '@ionic/angular';
import { createAnimation, iosTransitionAnimation, } from '@ionic/core';

import { CommonUtils } from './shared';

export const getIonPageElement = (element: HTMLElement) => {
    if (element.classList.contains('ion-page')) {
        return element;
    }

    const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');
    if (ionPage) {
        return ionPage;
    }
    // idk, return the original element so at least something animates and we don't have a null pointer
    return element;
};

/**
 * Inspired from https://github.com/ionic-team/ionic/blob/5.0.x/core/src/utils/transition/md.transition.ts
 * @param _
 * @param opts
 */
export const simpleNavAnimation = (_: HTMLElement, opts: any): Animation => {

    const backDirection = (opts.direction === 'back');
    const enteringEl = opts.enteringEl;
    const leavingEl = opts.leavingEl;
    const DURATION = 300;

    const ionPageElement = getIonPageElement(enteringEl);
    const enteringToolbarEle = ionPageElement.querySelector('ion-toolbar');
    const rootTransition = createAnimation();

    rootTransition
        .addElement(ionPageElement)
        .fill('both')
        .beforeRemoveClass('ion-page-invisible');

    // animate the component itself
    if (backDirection) {
        rootTransition
            .duration(DURATION);

    } else {
        rootTransition
            .duration(DURATION)
            .fromTo('opacity', 0.01, 1);
    }

    // Animate toolbar if it's there
    if (enteringToolbarEle) {
        const enteringToolBar = createAnimation();
        enteringToolBar.addElement(enteringToolbarEle);
        rootTransition.addAnimation(enteringToolBar);
    }

    // setup leaving view
    if (leavingEl && backDirection) {
        // leaving content
        rootTransition
            .duration(DURATION);

        const leavingPage = createAnimation();
        leavingPage
            .addElement(getIonPageElement(leavingEl))
            .afterStyles({ 'display': 'none' })
            .fromTo('opacity', 1, 0);

        rootTransition.addAnimation(leavingPage);
    }

    return rootTransition;
};
export function getNavAnimation(navEl: HTMLElement, opts: any) {
    return CommonUtils.disableAnimations() ? simpleNavAnimation(navEl, opts) : iosTransitionAnimation(navEl, opts);
}
