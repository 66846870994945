import { Injectable } from '@angular/core';

import { DirectoryEntry, FileEntry } from '@ionic-native/file/ngx';

import { Observable, Subject } from 'rxjs';

import { ZipTaskProgress } from '.';
import { FileUtilService } from '../file-util.service';
import { Logger, LoggingService } from '../logging.service';
import { ZipService } from './zip.service';

export interface ZipEntry {
    version: number;
    bitFlag: number;
    compressionMethod: number;
    lastModDateRaw: number;
    lastModDate: string;
    crc32: number;
    compressedSize: number;
    uncompressedSize: number;
    filenameLength: number;
    extraFieldLength: number;
    commentLength: number;
    directory: boolean;
    offset: 0;
    filename: string;
    comment: string;
}

/* this is exposed as a script tag in the index.html and through angular.json */
declare const zip: any;

/**
 * For all browser and non ios/android unzipping concerns
 */
@Injectable()
export class Html5ZipService extends ZipService {
    private logger: Logger;

    constructor(
        private loggingService: LoggingService,
        private fileUtil: FileUtilService
    ) {
        super();
        this.logger = this.loggingService.getLogger('Html5ZipService');
        zip.workerScriptsPath = 'scripts/';
    }

    /**
     * Unzip
     * @param source
     * @param targetDir
     */
    public unzip(file: FileEntry, targetDir: DirectoryEntry): Observable<ZipTaskProgress> {
        this.logger.debug(`Will unzip ${file.toURL()} into ${targetDir.toURL()}`);
        const sub = new Subject<ZipTaskProgress>();
        const observable = sub.asObservable();
        setTimeout(async () => {
            const entries: ZipEntry[] = await this.getEntries(file);
            this.logger.debug(`Got zip entries size = ${entries.length}`);
            const files = entries.filter(it => !it.directory);
            const state: ZipTaskProgress = {
                active: true,
                current: 0,
                total: files.length
            };
            sub.next(state);
            const onlyUnique = (value, index, list) => {
                return list.indexOf(value) === index;
            };

            const dirNames = entries.filter(it => it.directory).map(it => it.filename.split('/')
                .reverse().slice(1).reverse().join('/')).filter(onlyUnique);
            // serially create the folders
            for (const dir of dirNames) {
                await this.fileUtil.mkDirs(targetDir, dir);
            }

            try {
                for (const entry of files) {
                    const writer = new zip.BlobWriter();
                    await new Promise((res, rej) => {
                        (entry as any).getData(writer, async (blob) => {
                            try {
                                await this.fileUtil.writeFile(targetDir, entry.filename, blob);
                                res();
                            } catch (e) {
                                rej(e);
                            }
                        }, () => {

                        });
                    });
                    state.current++;
                    sub.next(state);
                }
                state.active = false;
                state.current = state.total;
                sub.next(state);
                sub.complete();
            } catch (e) {
                sub.error(e);
                sub.complete();
            }
        }, 10);
        return observable;
    }

    /**
     * Gets the entries from the zip
     * @param source
     */
    private async getEntries(file: FileEntry): Promise<ZipEntry[]> {
        const fileBlob = await this.fileUtil.toBlob(file, 'application/zip');
        const reader = new zip.BlobReader(fileBlob);
        return new Promise<ZipEntry[]>((res, rej) => {
            zip.createReader(reader, zipReader => {
                zipReader.getEntries(entries => {
                    res(entries);
                });
            }, message => {
                rej(message);
            });
        });
    }
}
