import { Injectable } from '@angular/core';

import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';

import * as CryptoJS from 'crypto-js';

import { Logger, LoggingService } from './logging.service';

const DEFAULT_KEY = `private_ASSESS2`;
/**
 * Provides encryption and decryption services. This uses crypto JS and always needs a private
 * key to enc-decrypt. The default private keys are always the UID with a well known constant.
 * The class also exposed methods to provide custom private keys
 */
@Injectable({ providedIn: 'root' })
export class EncryptDecryptService {
    private logger: Logger;
    constructor(private uniqueDeviceID: UniqueDeviceID, private loggingService: LoggingService) {
        this.logger = this.loggingService.getLogger('EncryptDecryptService');
    }

    /**
     * Encrypts a string using the deviceUID if available or a standard key
     * @param valueToEncrypt string
     */
    public async encrypt(valueToEncrypt: string): Promise<string> {
        return this.encryptWithKey(await this.resolveKey(), valueToEncrypt);
    }

    /**
     * Decrypts an encrypted string using deviceUID if available or a standard key
     * @param valueToDecrypt string
     */
    public async decrypt(valueToDecrypt: string): Promise<string> {
        return this.decryptWithKey(await this.resolveKey(), valueToDecrypt);
    }

    /**
     * Encrypts a string using a private key and AES encryption
     * @param privateKey string
     * @param valueToEncrypt string
     */
    public async encryptWithKey(privateKey: string, valueToEncrypt: string): Promise<string> {
        const encrypted = CryptoJS.AES.encrypt(valueToEncrypt, privateKey);
        return encrypted.toString();
    }

    /**
     * Decrypts a string with AES and provided private key
     * @param privateKey string
     * @param valueToDecrypt string
     */
    public async decryptWithKey(privateKey: string, valueToDecrypt: string): Promise<string> {
        const decrypted = CryptoJS.AES.decrypt(valueToDecrypt, privateKey);
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    /**
     * Returns a key pased on the availability of the UID on the app
     */
    private async resolveKey(): Promise<string> {
        let key = DEFAULT_KEY;
        try {
            const uid = await this.uniqueDeviceID.get();
            if (uid) {
                this.logger.info(`Unique device id = ${uid}`);
                key = `${uid}_ASSESS2`;
            }
        } catch (e) {
            this.logger.warn(`Couldnt get Unique device ID`);
        }
        return key;
    }
}
