import { ItemGroup, Subtest } from '@app/models';

import { RULES_MAP } from './rules-map';

export class RulesFactory {
    private static knownRules = ['reverseRule', 'consecutiveZerosDiscontinueRule', 'skipSubsequentTrialsRule'];

    static create(containerJson, container: Subtest | ItemGroup) {
        const rules = [];

        const constructRule = (ruleName, json) => {
            const RuleCtor = RULES_MAP[ruleName];
            if (!RuleCtor) {
                throw new Error(`No such rule type: ${ruleName}`);
            }

            if (Array.isArray(json)) {
                json.forEach(r => rules.push(new RuleCtor(r, container)));
            } else {
                rules.push(new RuleCtor(json, container));
            }
        };

        Object.keys(containerJson).forEach((key) => {
            if (RulesFactory.knownRules.includes(key)) {
                if (key.endsWith('Rule')) {
                    // create only if true
                    if (containerJson[key]) {
                        constructRule(key, containerJson[key]);
                    }
                } else if (key.endsWith('Rules')) {
                    const ruleName = key.substring(0, key.length - 1);
                    containerJson[key].forEach(item => constructRule(ruleName, item));
                }
            }
        });

        return rules;
    }
}
