import { Platform } from '@ionic/angular';
import { Zip } from '@ionic-native/zip/ngx';

import { FileUtilService } from '../file-util.service';
import { Html5ZipService } from './html5.zip.service';
import { LoggingService } from '../logging.service';
import { PluginZipService } from './plugin.zip.service';

export * from './zip.service';

export interface ZipTaskProgress {
    current?: number;
    total?: number;
    active: boolean;
}

export function zipServiceFactory(platform: Platform, loggingService: LoggingService, zip: Zip, fileUtil: FileUtilService) {
    return platform.is('ios') || platform.is('android') ? new PluginZipService(loggingService, zip) :
        new Html5ZipService(loggingService, fileUtil);
}
