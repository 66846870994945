import { UUID } from '@app/shared';

import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

export class Patient {

    dob: BehaviorSubject<Date>;
    id: BehaviorSubject<string>;
    guid: string;
    name: BehaviorSubject<string>;
    gender: BehaviorSubject<string>;
    education: BehaviorSubject<string>;

    constructor(json) {
        if (json.gender !== 'Male' && json.gender !== 'Female') {
            throw new Error(`Patient gender must be either 'Male' or 'Female': ${json.gender}`);
        }

        this.dob = new BehaviorSubject<Date>(
            new Date(json.dob)
        );
        this.id = new BehaviorSubject(json.id);
        this.guid = json.guid || UUID.generate(32);
        this.name = new BehaviorSubject(json.name);
        this.gender = new BehaviorSubject(json.gender);
        this.education = new BehaviorSubject(json.education || '');
    }

    // Serializes the patient.
    public serializeForSave(): any {
        return {
            id: this.id.value,
            name: this.name.value,
            dob: moment(this.dob.value).format('MM/DD/YYYY hh:mm A'), // 01/03/2011 12:00 AM
            gender: this.gender.value,
            guid: this.guid,
            education: this.education.value
        };
    }
}
