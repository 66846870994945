function secureRandomByte() {
    const buf = new Uint8Array(1);
    window.crypto.getRandomValues(buf);
    return buf[0];
}

const CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

/* tslint:disable:no-bitwise */
export class UUID {

    static generate(len?, radix?) {
        const chars = CHARS;
        const uuid = [];
        radix = radix || chars.length;

        if (len) {
            // Compact form
            for (let i = 0; i < len; i++) {
                uuid[i] = chars[0 | (secureRandomByte() % radix)];
            }
        } else {
            // rfc4122, version 4 form
            let r;

            // rfc4122 requires these characters
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
            uuid[14] = '4';

            // Fill in random data.  At i==19 set the high bits of clock sequence as
            // per rfc4122, sec. 4.1.5
            for (let i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | (secureRandomByte() % 16);
                    uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
                }
            }
        }

        return uuid.join('');
    }
}
