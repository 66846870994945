import { ISubtestRef } from '@app/interfaces/battery-components';
import { Note } from '@app/interfaces/common';
import { TestStates } from '@app/models/consts';
import { BatteryEvents } from '@app/shared/assess-events';
import { DateUtils } from '@app/shared/utils';

import { BehaviorSubject, Observable } from 'rxjs';

import { BatteryContentStatus } from '../content';
import { Patient } from './patient';
import { SubtestRef } from './subtest-ref';
import { Test } from './test';

export class TestBattery {
    batteryStartDate: Date;
    subtestRefs: any[];
    identifier: string;
    id: string;
    json: any;
    tests: Array<Test>;
    patient: Patient;
    examiners: string[];
    practiceMode: boolean;
    administrationDate: BehaviorSubject<Date>;
    itemInProgressId: string;
    subtestInProgressId: string;
    contentDownloadStatus: BatteryContentStatus = new BatteryContentStatus();
    contentStatusChange: Observable<void>;
    notes: Array<Note>;

    constructor(json, savedSubtestRefs = null) {
        this.json = json;
        this.id = json.id;
        this.patient = new Patient(json.patient);
        this.practiceMode = json.practiceMode;

        this.notes = json.notes;

        this.administrationDate = new BehaviorSubject<Date>(
            new Date(json.administrationDate)
        );

        this.itemInProgressId = json.itemInProgress;
        this.subtestInProgressId = json.subtestInProgress;

        if (json.batteryStartDate) {
            this.batteryStartDate = new Date(json.batteryStartDate);
        }

        if (this.id === undefined) {
            throw new Error('The battery has no ID.');
        }

        if (savedSubtestRefs) {
            this.subtestRefs = savedSubtestRefs;
        } else if (json.subtests) {
            this.subtestRefs = this.createSubtestsFromJson(json.subtests);
        } else {
            throw new Error('Test battery doesn\'t have subtest refs.');
        }

        if (!json.administrationDate) {
            throw new Error('Test Battery has no test start time.');
        } else if (isNaN(Date.parse(json.administrationDate))) {
            throw new Error('Test Battery has an invalid test start time.');
        }

        if (!json.identifier) {
            throw new Error('Test battery doesn\'t have an identifier.');
        }

        if (json.identifier.length < 3) {
            throw new Error('Test battery identifier must be at least three characters long.');
        }
        this.identifier = json.identifier;

        if (json.batteryStartDate) {
            this.batteryStartDate = new Date(json.batteryStartDate);
        }
        BatteryEvents.onBatteryCreation.next(this);
    }

    /**
     * Creates Subtests from the provided json.
     * @param json - the subtest json
     */
    public createSubtestsFromJson(json): SubtestRef[] {
        const refs: SubtestRef[] = [];

        for (let i = 0; i < json.length; i++) {
            const subtestJson = json[i];

            if (!subtestJson.testName) {
                throw new Error('Test battery\'s subtest has no test name.');
            } else if (!subtestJson.subtestName) {
                throw new Error('Test battery\'s subtest has no subtest name.');
            }

            const subtestData: ISubtestRef = {
                abbr: subtestJson.abbr,
                averageDuration: subtestJson.averageDuration || 0,
                testName: subtestJson.testName,
                testDisplayName: subtestJson.testDisplayName,
                subtestName: subtestJson.subtestName,
                subtestDisplayName: subtestJson.displayName || subtestJson.subtestDisplayName,
                normType: subtestJson.normType,
                subtestInstanceID: subtestJson.subtestInstanceID,
                subtestGUID: subtestJson.subtestGUID,
                testGUID: subtestJson.testGUID
            };

            refs.push(new SubtestRef(subtestData, subtestJson));
        }
        return refs;
    }

    // Returns the battery's todo status.
    public hasTodos(): boolean {
        // TODO:  do this for real
        return false;
    }

    public getSubtestRefs(): SubtestRef[] {
        return this.subtestRefs;
    }

    public getPatientAge() {
        return DateUtils.dateDifference(this.patient.dob.value, this.batteryStartDate || new Date());
    }

    public getBatteryStartDate() {
        return this.batteryStartDate;
    }

    public setContent(testsJson, subtestsJson) {
        if (subtestsJson.length !== this.subtestRefs.length) {
            throw new Error('Supplied subtest list doesn\'t match subtest refs.');
        }

        // Construct test models now. Unlike subtest models, they're relatively lightweight.
        try {
            this.tests = testsJson.map((testJson) => {
                return new Test(
                    testJson,
                    () => this.subtestRefs,
                    this.getPatientAge,
                    () => this.patient.gender.value,
                    this.getBatteryStartDate
                );
            });

            if (this.json.tests) {
                this.tests.forEach(t => t.restoreSavedData(this.json.tests.find(json => t.title === json.title)));
            }
        } catch (e) {
            throw new Error(`Error constructing tests: ${e}\nFrom JSON: ${testsJson}`);
        }

        this.subtestRefs.forEach((sr, idx) => {
            const subtest = subtestsJson[idx];
            if (subtest.testTitle !== sr.testName || subtest.testTitle !== sr.testName) {
                throw new Error('Supplied subtest list doesn\'t match subtest refs.');
            }
            sr.setContentJson(subtest);
        });
    }

    // Returns the state of the battery
    public state(): string {
        let started = false;
        let anyPending = false;

        if (this.subtestRefs) {
            this.subtestRefs.forEach((ref) => {
                const subtestState = ref.state();
                started = started || subtestState !== TestStates.notStarted;
                anyPending = anyPending || subtestState !== TestStates.complete;
            });

            if (started && !anyPending) {
                if (this.hasTodos()) {
                    return TestStates.toDo;
                }
                return TestStates.complete;
            } else if (started) {
                return TestStates.inProgress;
            }
        }
        return TestStates.notStarted;
    }

    public getSubtestInProgressId() {
        return this.subtestInProgressId;
    }

    public getItemInProgressId() {
        return this.itemInProgressId;
    }

    public setSubtestInProgressId(subtestId) {
        this.subtestInProgressId = subtestId;
    }

    public setItemInProgressId(itemId) {
        this.itemInProgressId = itemId;
    }

    public getNotes(): Array<Note> {
        return this.notes;
    }

    public saveNotes(notes: Array<Note>) {
        this.notes = notes;
    }

    // Serializes the battery.
    public serializeForSave() {
        return {
            serializationFormatVersion: 8,
            id: this.id,
            identifier: this.identifier,
            exportTime: new Date().toUTCString(),
            administrationDate: this.administrationDate.value.toUTCString(),
            state: this.state(),
            patient: this.patient.serializeForSave(),
            practiceMode: this.practiceMode,
            tests: this.tests ? this.tests.map(t => t.serializeForSave()) : this.json.tests,
            subtests: this.subtestRefs.map(st => st.serializeForSave()),
            examiners: this.examiners,
            batteryStartDate: this.batteryStartDate && this.batteryStartDate.toUTCString(),
            itemInProgress: this.itemInProgressId,
            subtestInProgress: this.subtestInProgressId,
            notes: this.notes
        };
    }
}
