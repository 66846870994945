import { Injectable } from '@angular/core';

import { Assessment, BatteryInfo } from '@app/interfaces/battery-components';
import { ISubtestHierarchy, ITestHierarchy } from '@app/interfaces/test-hierarchy';
import { SubtestRef, TestBattery } from '@app/models';
import { AssessEvents } from '@app/shared';
import { environment } from '@appenv';

import { BatteryService } from './battery.service';
import { ContentService, IContentResult } from './content.service';
import { Events } from './events.service';
import { HttpService } from './http.service';
import { Logger, LoggingService } from './logging.service';
import { NetworkStatusService } from './network-status.service';
import { UserStoreService } from './user-store.service';

@Injectable({ providedIn: 'root' })
export class AssessmentService {

    logger: Logger;

    private testHierarchy: ITestHierarchy[];

    constructor(
        private batteryService: BatteryService,
        private contentLoader: ContentService,
        private events: Events,
        private http: HttpService,
        private loggingService: LoggingService,
        private userStoreService: UserStoreService,
        private networkStatusService: NetworkStatusService
    ) {
        this.logger = this.loggingService.getLogger('AssessmentService');

        this.events.subscribe(AssessEvents.loginSuccess, async () => await this.getTestHierarchy(true));
        this.events.subscribe(AssessEvents.logout, () => this.testHierarchy = null);
    }

    // Retrieves all assessments for the user (from Central and the local db)
    public async getAssessments(): Promise<TestBattery[]> {
        if (this.networkStatusService.isConnected()) {
            await this.getNewAssessments();
        } else {
            this.logger.warn(`Not downloading any new assessments as device is offline`);
        }
        const batteryIdsinRepo: string[] = await this.batteryService.getRepoIds();
        this.logger.info(`Battery ids in repo`, batteryIdsinRepo);
        return await this.loadAssessmentsFromDB(batteryIdsinRepo);
    }

    // Retrieves the new assessments for the user (from Central only)
    public async getNewAssessments(): Promise<TestBattery[]> {
        try {
            const batteryInfo: BatteryInfo = await this.http.get(`${environment.centralEndpoint}/sync/getReady`);
            this.logger.debug(`Got ${batteryInfo.assessments.length} assessments from central.`);
            return await this.prepBatteryFromAssessments(batteryInfo.assessments);
        } catch (e) {
            if (e.status !== 401) {
                this.events.publish(AssessEvents.assessmentDownloadError);
            }
            this.logger.error(`Error retrieving assessments from Central. ${JSON.stringify(e)}`);
        }
    }

    /**
    * Create and save battery to file.
    * @param userAssessments - the assessments to save
    */
    public async prepBatteryFromAssessments(userAssessments: Assessment[]): Promise<TestBattery[]> {
        let subtestGUIDList;
        this.logger.debug('Prepping battery from assessments.');

        // Check if subtest in Eligible List. If yes Get Test Hierarchy.
        // Else Reload Test Hierarchy after updating eligible subtest list
        for (let i = 0, len = userAssessments.length; i < len; i++) {
            const assessment: Assessment = userAssessments[i];
            subtestGUIDList = assessment.battery.subtests.map((subtest) => {
                return subtest.subtestGUID;
            });
        }
        const reloadTestHierarchy = await this.updateEligibleSubtests(subtestGUIDList);

        const testHierarchy = await this.getTestHierarchy(reloadTestHierarchy);
        const batteries: TestBattery[] = [];
        for (let i = 0, len = userAssessments.length; i < len; i++) {
            const assessment: Assessment = userAssessments[i];
            const alreadyInLocalRepo = await this.batteryService.isBatteryInRepo(assessment.id);

            if (!alreadyInLocalRepo) {
                try {
                    // rectify subtest display names
                    assessment.battery.subtests.forEach((st) => {
                        const test = testHierarchy.find(t => st.testGUID === t.testGUID);
                        const subtest = test.subtests.find(s => st.subtestGUID === s.subtestGUID);
                        st.subtestDisplayName = subtest.subtestDisplayName;
                    });
                    const subtestRefs: SubtestRef[] = assessment.battery.subtests.map(st => new SubtestRef(st));
                    assessment.subtestRefs = subtestRefs;
                    assessment.identifier = assessment.title;

                    const battery: TestBattery = new TestBattery(assessment, subtestRefs);
                    batteries.push(battery);

                    await this.batteryService.saveBattery(battery);
                    // for each prep we need to inform central that we have downloaded the assessment
                    await this.notifyCentralForAssessmentdownload(assessment.id);
                } catch (e) {
                    this.logger.error(`Error exporting battery ${assessment.id}.`, e);
                }
            } else {
                this.logger.warn(`Downloaded a battery with ${assessment.id} that we already have. Keeping the local copy.`);
            }
        }
        return batteries;
    }

    public async updateEligibleSubtests(subtestGUIDList: string[] = []): Promise<boolean> {
        let newSubtestGUIDs = [];
        let eligibleSubtests: string[] = await this.userStoreService.getEligibleSubtestGUIDs();
        newSubtestGUIDs = subtestGUIDList.filter((subtestGUID) => {
            return !eligibleSubtests.includes(subtestGUID);
        });
        newSubtestGUIDs = [...new Set(newSubtestGUIDs)];
        if (newSubtestGUIDs.length > 0) {
            eligibleSubtests = eligibleSubtests.concat(newSubtestGUIDs);
            await this.userStoreService.setEligibleSubtestGUIDs(eligibleSubtests);
            return true;
        }
        return false;
    }

    /**
     * Gets a battery attached to an assessment by loading it from the file system
     * @param assessment - the assessment whose battery we're getting
     */
    public async getBattery(batteryId: string): Promise<TestBattery> {
        const inRepo = await this.batteryService.isBatteryInRepo(batteryId);

        if (!inRepo) {
            const msg = `Battery ${batteryId} not in repo.`;
            this.logger.error(msg);
            throw new Error(msg);
        }

        const batteryJsonString = await this.batteryService.getSavedTestBattery(batteryId);
        const battery = JSON.parse(batteryJsonString);

        this.logger.info(`Got saved battery with ${battery.id}`);
        return new TestBattery(battery);
    }

    /**
     * Notifies Central of a successful battery download
     * @param id - the id of the battery that was downloaded
     */
    public async notifyCentralForAssessmentdownload(id: string): Promise<any> {
        try {
            return await this.http.post(`${environment.centralEndpoint}/sync/assessmentDownloadSucceeded`, {}, {
                params: { id },
                responseType: 'text'
            });
        } catch (e) {
            this.logger.error(`Unable to notify Central of download of assessment with id ${id}.`);
            throw e;
        }
    }

    public async getTestHierarchy(forceReload = false): Promise<ITestHierarchy[]> {
        if (this.testHierarchy && !forceReload) {
            return this.testHierarchy;
        }

        const fullTestHierarchy = await this.http.get('assets/battery/test-json/test-hierarchy.json');
        const eligibleSubtests: string[] = await this.userStoreService.getEligibleSubtestGUIDs();
        const filteredTestHierarchy: ITestHierarchy[] = [];

        fullTestHierarchy.forEach((test) => {
            const filteredSubtests: ISubtestHierarchy[] = [];
            test.subtests.forEach((subtest) => {
                // TODO:  Update so testHierarchy.json is exported with this info
                if (eligibleSubtests.includes(subtest.subtestGUID)) {
                    filteredSubtests.push({
                        abbr: '',
                        averageDuration: subtest.averageDuration,
                        contentPath: subtest.contentPath,
                        displayName: subtest.displayName,
                        name: subtest.name,
                        normType: test.name,
                        subtestDisplayName: subtest.displayName,
                        subtestGUID: subtest.subtestGUID,
                        subtestName: subtest.name,
                        testDisplayName: test.displayName,
                        testGUID: test.testGUID,
                        testName: test.name
                    });
                }
            });
            if (filteredSubtests.length > 0) {
                test.subtests = filteredSubtests;
                filteredTestHierarchy.push(test);
            }
        });
        this.testHierarchy = filteredTestHierarchy;
        return filteredTestHierarchy;
    }

    public async loadBatteryWithContent(assessmentId: string): Promise<TestBattery> {
        this.logger.debug('Loading battery with content');
        let initialBattery: TestBattery;

        try {
            initialBattery = await this.getBattery(assessmentId);
            this.logger.debug('Got initial battery');

            const testHierarchy: ITestHierarchy[] = await this.getTestHierarchy();
            const contentResult: IContentResult = await this.contentLoader.getContentForBattery(initialBattery.subtestRefs, testHierarchy);
            initialBattery.setContent(contentResult.tests, contentResult.subtests);
        } catch (e) {
            this.logger.error('Unable to load content for battery.');
            throw e;
        }
        return initialBattery;
    }

    private async loadAssessmentsFromDB(ids: string[]): Promise<TestBattery[]> {
        const promises: Promise<TestBattery>[] = ids.map(async (bat) => {
            return await this.getBattery(bat);
        });
        return await Promise.all(promises);
    }
}
