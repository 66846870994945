import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'test-thumbnails',
  templateUrl: './test-thumbnails.component.html',
  styleUrls: ['./test-thumbnails.component.scss'],
})
export class TestThumbnailsComponent implements OnInit {

    @Input() name: string;
    imageName: string;
    constructor() { }

    ngOnInit() {
        this.imageName = `assets/img/test-thumbnails/${this.name}-thumbnail.png`;
    }
}
