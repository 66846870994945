import { Point } from '.';

export class Pen {

    stroke: Array<Point> = [];
    ctx: CanvasRenderingContext2D;

    width: number;
    color: string;

    constructor(width: number, color: string) {
        this.width = width;
        this.color = color;
    }

    setColor(color: string) {
        this.color = color;
    }

    start(canvas: HTMLCanvasElement) {
        this.stroke = [];
        this.ctx = canvas.getContext('2d');
        this.ctx.lineWidth = this.width;
        this.ctx.strokeStyle = this.color;
    }

    stop() {
        const length = this.stroke.length;
        if (length === 1) {
            this.drawDot(this.stroke[0]);
        } else if (length > 1) {
            this.drawWithoutSmoothing();
        }
        this.stroke = [];
    }

    draw(pt: Point) {
        this.addPoint(pt);

        const length = this.stroke.length;
        if (length > 1 && length < 3) {
            this.drawLine(this.stroke[length - 1], this.stroke[length - 2]);
        } else if (length >= 4) {
            this.drawBezierCurve(this.stroke[length - 3], this.stroke[length - 2], this.stroke[length - 1], this.stroke[length - 4]);
        }
    }

    drawDot(pt: Point) {
        this.ctx.beginPath();
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = this.color;
        this.ctx.arc(pt.x, pt.y, 1.5, 0, Math.PI * 2, true);
        this.ctx.closePath();
        this.ctx.stroke();
        this.ctx.fill();
    }

    drawWithoutSmoothing(pt?: Point) {
        if (pt) {
            this.addPoint(pt);
        }

        const length = this.stroke.length;
        if (this.stroke.length > 1) {
            this.drawLine(this.stroke[length - 1], this.stroke[length - 2]);
        }
    }

    drawLine(prev: Point, curr: Point) {
        this.ctx.beginPath();
        this.ctx.moveTo(prev.x, prev.y);
        this.ctx.lineTo(curr.x, curr.y);
        this.ctx.lineWidth = this.width;
        this.ctx.strokeStyle = this.color;
        this.ctx.stroke();
        this.ctx.closePath();
    }

    drawBezierCurve(a: Point, b: Point, next: Point, prev: Point) {
        const ctrl1 = this.getControlPoints(prev, a, b, 0.35).control2;
        const ctrl2 = this.getControlPoints(a, b, next, 0.35).control1;
        this.ctx.beginPath();
        this.ctx.moveTo(a.x, a.y);
        this.ctx.bezierCurveTo(ctrl1.x, ctrl1.y, ctrl2.x, ctrl2.y, b.x, b.y);
        this.ctx.lineWidth = this.width;
        this.ctx.strokeStyle = this.color;
        this.ctx.stroke();
        this.ctx.closePath();
    }

    /**
     * Adds a point to the stroke
     * @param pt - the point to add
     */
    private addPoint(pt: Point) {
        const len = this.stroke.length;
        if (pt && len === 0 || (this.stroke[len - 1].x !== pt.x || this.stroke[len - 1].y !== pt.y)) {
            this.stroke.push(pt);
        }
    }

    /**
     * Calculates the control points for a curve
     * @param knot0 - coordinates of first end point of line segment
     * @param knot1 - coordinates of other end point of line segment
     * @param knot2 - the next point
     * @param tension - the tension to apply to the curve (0 = infinite tension = shortest path = straight line)
     * @returns - 2 control points
     */
    private getControlPoints(knot0: Point, knot1: Point, knot2: Point, tension: number) {
        const distance1 = this.distance(knot1, knot0);
        const distance2 = this.distance(knot2, knot1);
        const fa: number = tension * distance1 / (distance1 + distance2);
        const fb: number = tension - fa;

        return {
            control1: {
                x: knot1.x + fa * (knot0.x - knot2.x),
                y: knot1.y + fa * (knot0.y - knot2.y)
            },
            control2: {
                x: knot1.x - fb * (knot0.x - knot2.x),
                y: knot1.y - fb * (knot0.y - knot2.y)
            }
        };
    }

    /**
	 * Calculates the distance between two points
	 * @param a - point with an x and y value
	 * @param b - point with an x and y value
	 * @returns - the distance between point a and point b
	 */
    private distance(a: Point, b: Point) {
        return Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
    }
}
