<div *ngIf="type === 'linear'" class="linear-wrapper" [ngClass]="{'subtest': isSubtest}">
    <ion-label class="linear-prog-label" *ngIf="downloadFailed()">{{'components.contentProgress.label.failed' | translate}}</ion-label>
    <ion-label class="linear-prog-label" *ngIf="isDownloading() && !downloadFailed()">{{'components.contentProgress.label.downloading' | translate}}</ion-label>
    <ion-label class="linear-prog-label" *ngIf="completeOnExtraction && isExtracting() && !downloadFailed()">{{'components.contentProgress.label.preparing' | translate}}</ion-label>
    <ion-label class="linear-prog-label" *ngIf="isComplete()">{{'components.contentProgress.label.ready' | translate}}</ion-label>
    <ion-progress-bar *ngIf="!downloadFailed()"
        [ngClass]="{'extracting': isExtracting(), 'downloading': isDownloading(), 'complete': isComplete()}"
        [value]="getProgress()"></ion-progress-bar>
    <ion-progress-bar *ngIf="downloadFailed()"
        class="failed"
        value="1"></ion-progress-bar>
</div>
<div *ngIf="type === 'circle'" class="progress-wrapper" [ngClass]="{'subtest': isSubtest}">
    <div *ngIf="isDownloading() else failedDownload">
        <round-progress [current]="downloadStatus.downloadProgress" [responsive]="true" [stroke]="2" [max]="100"
            [radius]="6" [background]="background" [color]="downloadColor">
        </round-progress>
    </div>  
    <span *ngIf="downloadFailed() else else1" class="failed-dot" #failedDownload></span>  
    <div *ngIf="!downloadFailed() && (downloadStatus.downloadProgress >= 100 && downloadStatus.extractionProgress < 100) else else2" #else1>
            <round-progress *ngIf="completeOnExtraction && downloadStatus.extractionProgress > 0 else else3" [background]="background"
                [color]="extractColor" [current]="downloadStatus.extractionProgress" [stroke]="2" [max]="100" [radius]="6" [responsive]="true">
            </round-progress>
            <span *ngIf="completeOnExtraction && downloadStatus.extractionProgress === 0" class="extracting-dot" #else3></span>
    </div>
    <span *ngIf="isComplete()" class="complete-dot" #else2></span>
</div>