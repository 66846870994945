import { Injectable } from '@angular/core';

import { AssessEvents, ConnectionEvents, MessageTypes } from '@app/shared';

import { AssessmentService } from './assessment.service';
import { ContentDownloaderService } from './content-downloader.service';
import { Events } from './events.service';
import { Logger, LoggingService } from './logging.service';
import { PlatformService } from './platform.service';
import { ToastService } from './toast.service';

import Peer, { DataConnection, MediaConnection } from 'peerjs';

const OPTIONS = {
    host: 'peer-fr.qiactive.com',
    path: '/myapp',
    debug: 3,
    secure: true,
    config: {
        iceServers: [
            {
                'urls': 'stun:ec2-15-222-107-144.ca-central-1.compute.amazonaws.com:3478'
            },
            { 'urls': 'stun:stun.l.google.com:19302' },
            { 'urls': 'stun:stun1.l.google.com:19302' },
            { 'urls': 'stun:stun2.l.google.com:19302' },
            { 'urls': 'stun:stun3.l.google.com:19302' },
            { 'urls': 'stun:stun4.l.google.com:19302' },
            {
                'urls': 'turn:ec2-15-222-107-144.ca-central-1.compute.amazonaws.com:3478',
                'credential': 'qiturn',
                'username': 'Rex1t'
            }
        ]
      /*iceServers: [
            { 'urls': 'stun:ec2-3-83-12-195.compute-1.amazonaws.com:3478' },
            { 'urls': 'stun:stun.l.google.com:19302' },
            { 'urls': 'stun:stun1.l.google.com:19302' },
            { 'urls': 'stun:stun2.l.google.com:19302' },
            { 'urls': 'stun:stun3.l.google.com:19302' },
            { 'urls': 'stun:stun4.l.google.com:19302' },
            {
                'urls': 'turn:ec2-3-83-12-195.compute-1.amazonaws.com:3478',
                'credential': 'qiturn',
                'username': 'Rex1t'
            }
        ]*/
    }
};

@Injectable()
export class RemoteSessionService {

    private logger: Logger;

    private peer: Peer;

    private isPractitioner: boolean;
    private isClientReady = false;

    connection: DataConnection;
    call: MediaConnection;
    connectionId: string;
    myStream: MediaStream;
    remoteStream: MediaStream;
    peerReady = false;

    constructor(
        private loggingService: LoggingService,
        private events: Events,
        private platform: PlatformService,
        private contentDownloaderService: ContentDownloaderService,
        private assessmentService: AssessmentService,
        private toastService: ToastService
    ) {
        this.logger = this.loggingService.getLogger('RemoteSessionService');
        this.events.subscribe(AssessEvents.batteryEdit, () => this.sendContentDownloadInfo());
        this.events.subscribe(MessageTypes.END_CALL, () => this.disconnectCall());

        this.events.subscribe(MessageTypes.STIM_READY, () => {
            this.isClientReady = true;
            this.toastService.dismissTop();
            this.toastService.showToastMessage('Client is ready for administration.');
        });
    }

    isConnected() {
        if (!this.peer) {
            return;
        }
        return !!this.connection;
    }

    isCallActive() {
        return !!this.call;
    }

    // Both client and practitioner initialize a Peer (only one can specify an id)
    async initializeSession(id: string) {
        this.isPractitioner = await this.platform.isPractitionerMode();

        if (id !== this.connectionId) {
            AssessEvents.remoteSession.next(true);
            window.MPC.onChangeState.next({ state: 'NotConnected' });
            this.events.publish(ConnectionEvents.disconnected, false);

            this.connectionId = id;
        }

        if (!this.isPractitioner) {
            this.peer = new Peer(null, OPTIONS);

            this.peer.on('open', () => {
                this.peerReady = true;
                this.logger.info('Peer IS ready!');
                this.joinSession();
            });

            this.peer.on('call', (call) => {
                this.call = call;
                this.events.publish(AssessEvents.incomingCall);
            });
        } else {
            this.peer = new Peer(id, OPTIONS);

            this.peer.on('open', (peerId) => {
                this.logger.info(`Peer id is ${peerId}.`);
            });

            // pract is waiting on client connection
            this.peer.on('connection', (c) => {
                this.logger.info('Connection received from client!');
                this.connection = c;
                this.initListeners();
                // give it a beat, so we don't flash this guy if content is already downloaded
                setTimeout(() => {
                    if (!this.isClientReady) {
                        this.toastService.dismissTop();
                        this.toastService.showToastMessage('Client is preparing content for administration.', null, 'toast-danger', 0);
                    }
                }, 50);

                window.MPC.onChangeState.next({ state: 'Connected' });
                this.events.publish(ConnectionEvents.connected);
            });
        }
    }

    answerCall() {
        if (this.call) {
            navigator.getUserMedia({ video: true, audio: true }, (stream) => {
                const videos = document.querySelectorAll('.video') as any;
                this.myStream = stream;
                this.events.publish(AssessEvents.localStreamStarted);
                this.call.answer(stream);
                this.call.on('stream', (remoteStream) => {
                    // show stream in some UI element.
                    this.remoteStream = remoteStream;
                    this.events.publish(AssessEvents.remoteStreamStarted);
                });
            }, (err) => {
                this.logger.error('Failed to get local stream', err);
            });
        }
    }

    declineCall() {
        this.sendData(JSON.stringify({ type: MessageTypes.END_CALL }));
        this.call.close();
        this.call = null;
    }

    callPeer() {
        if (this.connection) {
            navigator.getUserMedia({ video: true, audio: true }, (stream) => {
                this.myStream = stream;
                this.events.publish(AssessEvents.localStreamStarted);
                this.call = this.peer.call(this.connection.peer, stream);
                this.call.on('stream', (remoteStream) => {
                    this.remoteStream = remoteStream;
                    this.events.publish(AssessEvents.remoteStreamStarted);
                });
                this.call.on('error', () => {
                    this.disconnectCall();
                });
                this.call.on('close', () => {
                    this.disconnectCall();
                });
            }, (err) => {
                this.logger.error('Failed to get local stream.', err);
            });
        }
    }

    disconnect() {
        this.disconnectCall();
        this.closeConnection();

        if (this.peer) {
            this.peer.destroy();
            this.peer = null;
        }
    }

    closeConnection() {
        if (this.connection) {
            this.connection.close();
            this.connection = null;
        }
    }

    disconnectCall() {
        if (this.call) {
            this.sendData(JSON.stringify({ type: MessageTypes.END_CALL }));
            this.myStream.getTracks().forEach(track => track.stop());
            this.call.close();
            this.call = null;
        }
    }

    // Client connects to client session using assessment id
    public joinSession() {
        if (!this.peer || !this.peerReady) {
            this.logger.info('Peer NOT ready!');
            return;
        }

        if (this.connection) {
            this.connection.close();
            this.connection = null;
        }

        this.contentDownloaderService.getExtractedHashes().then(hashes => {
            const existingContent = hashes.existingContent;
            const existingStims = hashes.existingStims;

            this.connection = this.peer.connect(this.connectionId, {
                reliable: true,
                metadata: {
                    existingContent,
                    existingStims
                }
            });
            this.initListeners();
        });
    }

    sendData(msg: string) {
        this.connection.send(msg);
    }

    getLocalStream() {
        return this.myStream;
    }

    getRemoteStream() {
        return this.remoteStream;
    }

    private sendContentDownloadInfo() {
        if (this.connection) {
            // get client's content extraction info from connection metadata
            const existingContent = this.connection.metadata.existingContent;
            const existingStims = this.connection.metadata.existingStims;
            this.assessmentService.getBattery(this.connectionId).then((battery) => {
                this.contentDownloaderService.queryBatteryVersionsForRemoteStim(battery, existingContent, existingStims).then((res) => {
                    this.sendData(JSON.stringify({
                        type: MessageTypes.CONTENT_QUERY,
                        payload: res
                    }));
                });
            });
        }
    }

    private initListeners() {
        this.connection.on('open', () => {
            this.logger.info('Connection received!');
            window.MPC.onChangeState.next({ state: 'Connected' });
            this.events.publish(ConnectionEvents.connected, false);

            if (this.isPractitioner) {
                // send an acknowledgement + content info
                this.sendContentDownloadInfo();
            }
        });

        this.connection.on('close', () => {
            this.logger.info('Connection closed!');
            window.MPC.onChangeState.next({ state: 'NotConnected' });
            this.events.publish(ConnectionEvents.disconnected);

            this.connection = null;
            this.isClientReady = false;

            this.disconnectCall();

            if (!this.isPractitioner) {
                // notify that the session has ended
                this.events.publish(AssessEvents.remoteSessionEnded);
            }
        });

        this.connection.on('error', (error) => {
            this.logger.error('Connection error!', error);
            this.events.publish(ConnectionEvents.disconnected);
        });

        this.connection.on('data', (data) => {
            this.logger.info('Data received!');
            window.MPC.onReceiveData.next(data);
        });
    }
}
