<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>{{'rules.overlays.message.selectStartPoint' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <section class="content-wrapper">
        <ion-list lines="none">
            <!-- TODO: Update to use an array of skipTo items, and use loop here -->
            <ion-item [ngClass]="{'selected' : isSelected(rule.skipToItem)}"
                (click)="setSelectedOption(rule.skipToItem)">
                <h3><b>{{rule.skipToItem.title}}</b> (Ages {{rule.minAge}} - {{rule.maxAge}})</h3>
            </ion-item>
            <ion-item [ngClass]="{'selected' : isSelected(rule.noSkipItem)}"
                (click)="setSelectedOption(rule.noSkipItem)">
                <h3><b>{{rule.noSkipItem.title}}</b> ({{rule.noSkipMsg}})</h3>
            </ion-item>
        </ion-list>
    </section>
</ion-content>
<section class="button-row">
    <ion-button (click)="onDismiss()" [disabled]="!selectedItemID" color="secondary" shape="round" expand="block"
        class="modal-btn">
        {{'rules.overlays.label.ok' | translate}}
    </ion-button>
</section>