import { Injectable, NgZone } from '@angular/core';

import { IdleTimeout } from '@app/interfaces/common';

import { from, fromEvent, interval, merge, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { bufferTime, distinctUntilChanged, filter, finalize, map, scan, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Logger, LoggingService } from './logging.service';

@Injectable({
    providedIn: 'root'
})
export class IdleTimeoutService {

    // Events that can interrupts user's inactivity timer.
    activityEvents: Observable<any>;

    timerStart = new Subject<boolean>();
    idleDetected = new Subject<boolean>();
    timeout = new Subject<boolean>();
    idle: Observable<any>;
    timer: Observable<any>;

    // Idle value in milliseconds. Default equals to 10 minutes.
    idleValue = 600 * 1000;

    // Idle buffer wait time milliseconds to collect user action. Default equals to 1 Sec.
    idleSensitivityMillisec = 1000;

    // Timeout value in seconds.Default equals to 5 minutes.
    timeoutValue = 300;

    // Timeout status.
    isTimeout: boolean;

    // Timer of user's inactivity is in progress.
    isInactivityTimer: boolean;
    isIdleDetected: boolean;

    idleSubscription: Subscription;
    timerSubscription: Subscription;

    logger: Logger;

    constructor(private _ngZone: NgZone, private loggingService: LoggingService) {
        this.logger = this.loggingService.getLogger('IdleTimeoutService');
    }

    /**
    * Start watching for user idle and setup timer and ping.
    */
    startWatching(idleTimeout: IdleTimeout, events: Array<Observable<any>>, isOverride?: boolean) {
        this.idleValue = isOverride ? idleTimeout.override * 1000 : idleTimeout.idle * 1000;
        this.timeoutValue = idleTimeout.timeout;

        if (!this.activityEvents) {
            this.activityEvents = merge(
                fromEvent(window, 'mousemove'),
                fromEvent(window, 'click'),
                fromEvent(document, 'keydown'),
                fromEvent(document, 'touchstart'),
                fromEvent(document, 'touchend'),
                fromEvent(document, 'touchmove'),
                ...events
            );
        }

        this.idle = from(this.activityEvents);

        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
        // do nothing for now
        this.timerSubscription = this.onTimerStart().subscribe(() => { });

        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }

        // If any of user events is not active for idle-seconds when start timer.
        this.idleSubscription = this.idle
            .pipe(
                bufferTime(this.idleSensitivityMillisec), // Starting point of detecting of user's inactivity
                filter(
                    arr => !arr.length && !this.isIdleDetected && !this.isInactivityTimer
                ),
                tap(() => {
                    this.isIdleDetected = true;
                    this.idleDetected.next(true);
                    this.logger.debug('Idle detected.');
                }),
                switchMap(() =>
                    this._ngZone.runOutsideAngular(() =>
                        interval(1000).pipe(
                            takeUntil(
                                merge(
                                    this.activityEvents,
                                    timer(this.idleValue).pipe(
                                        tap(() => {
                                            this.isInactivityTimer = true;
                                            this.timerStart.next(true);
                                        })
                                    )
                                )
                            ),
                            finalize(() => {
                                this.isIdleDetected = false;
                                this.idleDetected.next(false);
                                this.logger.debug('Idle timer reset.');
                            })
                        )
                    )
                )
            )
            .subscribe();
        this.setupTimer(this.timeoutValue);
    }

    stopWatching() {
        this.stopTimer();
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
    }

    stopTimer() {
        this.isInactivityTimer = false;
        this.timerStart.next(false);
    }

    resetTimer() {
        this.stopTimer();
        this.isTimeout = false;
    }

    /**
    * Return observable for timer's countdown number that emits after idle.
    */
    onTimerStart(): Observable<number> {
        return this.timerStart.pipe(
            distinctUntilChanged(),
            switchMap(start => (start ? this.timer : of(null)))
        );
    }

    /**
    * Return observable for idle status changed
    */
    onIdleStatusChanged(): Observable<boolean> {
        return this.idleDetected.asObservable();
    }

    /**
    * Return observable for timeout is fired.
    */
    onTimeout(): Observable<boolean> {
        return this.timeout.pipe(
            filter(timeout => !!timeout),
            tap(() => (this.isTimeout = true)),
            map(() => true)
        );
    }

    /**
    * Setup timer.
    *
    * Counts every seconds and return n+1 and fire timeout for last count.
    * @param timeoutValue Timeout in seconds.
    */
    setupTimer(timeoutValue: number) {
        this._ngZone.runOutsideAngular(() => {
            this.timer = interval(1000).pipe(
                take(timeoutValue),
                map(() => 1),
                scan((acc, n) => acc + n),
                tap(count => {
                    if (count === timeoutValue) {
                        this.timeout.next(true);
                        this.logger.debug('Timeout reached.');
                    }
                })
            );
        });
    }

    getTimeoutValues() {
        return {
            idle: this.idleValue / 1000,
            timeout: this.timeoutValue
        };
    }
}
