// Common methods for parsing and or manipulating things
export class CommonUtils {

    private static cssColors = {};

    public static isNumber(number) {
        return !isNaN(parseFloat(number)) && isFinite(number);
    }

    public static numberOrDash(number) {
        return this.isNumber(number) ? number : '-';
    }

    // https://gist.github.com/adamawolf/3048717#file-apple_mobile_device_types-txt
    public static disableAnimations() {
        return window['device'] && window['device'].model ? [
            'iPad4,1',
            'iPad4,2',
            'iPad4,3',
            'iPad5,3',
            'iPad5,4'
        ].indexOf(window['device'].model) !== -1 : false;
    }

    /**
     * Returns the css color.
     * @param cssColor - the id of the css color
     */
    public static getColor(cssColor): string {
        if (!CommonUtils.cssColors[name]) {
            const temp = document.createElement('div');
            temp.style.color = cssColor;
            temp.style.display = 'none';

            document.body.appendChild(temp);
            CommonUtils.cssColors[cssColor] = getComputedStyle(temp)['color'];
            document.body.removeChild(temp);
        }
        return CommonUtils.cssColors[cssColor];
    }

    /**
     * Adds a class to an element.
     * @param element - the element to which the class should be added
     * @param clazz - the class to add
     */
    public static addClass(element: HTMLElement, clazz: string) {
        element.classList.add('cdk-drag-preview');
    }

    /**
     * Removes a class from an element.
     * @param element - the element from which to remove the class
     * @param clazz - the class to be removed
     */
    public static removeClass(element: HTMLElement, clazz: string) {
        element.classList.remove('cdk-drag-preview');
    }
}
