import { TestBattery, QuerySubtestContentInfo } from '@app/models';

import { BehaviorSubject, Subject } from 'rxjs';

export const AssessEvents = {
    assessmentDownloadError: 'assessmentDownload:error',
    batteryEdit: 'battery:edited',
    contentDownloadEnded: 'contentDownload:ended',
    contentDownloadStarted: 'contentDownload:started',
    login: 'user:login',
    loginSuccess: 'user:login_success',
    loginFailure: 'user:login_failure',
    loginReset: 'user:login_reset',
    logout: 'user:logout',
    selectDefaultLang: 'app:select_default',
    selectLang: 'app:select_language',
    manualDiscontinue: 'assess:manualDC',
    goHome: 'assess:goHome',
    homePressed: 'assess:homePressed',
    messageReceived: 'assess:messageReceived',
    paused: 'app:paused',
    resumed: 'app:resumed',
    resign: 'app:resign',
    active: 'app:active',
    notify: 'app:notify',
    savedNote: 'notes:saved',
    stimDownloadFailed: 'stimDownload:failed',
    stimDownloadSuccess: 'stimDownload:success',
    stimShown: 'stim:shown',
    stimHidden: new Subject(),
    remoteSession: new BehaviorSubject<boolean>(false),
    remoteSessionContentQuery: new BehaviorSubject<QuerySubtestContentInfo>(null),
    remoteSessionEnded: 'remote:sessionEnded',
    localStreamStarted: 'local:streamStarted',
    remoteStreamStarted: 'remote:streamStarted',
    incomingCall: 'remote:callIncoming',
    timerStart: 'timer:start',
    timerStop: 'timer:stop'
};

export const ConnectionEvents = {
    connected: 'devices:connected',
    disconnected: 'devices:disconnected',
    clientResigned: 'client:resigned',
    clientActive: 'client:active',
    // these are used for dev purposes only.
    disconnected_debug: 'devices:disconnected_debug',
    connected_debug: 'devices:connected_debug'
};

export const ConsoleLogEvents = {
    clear: 'console:clear',
    copy: 'console:copy',
    flush: 'console:flush_buffer',
    show: 'console:show'
};

export const BatteryEvents = {
    onBatteryCreation: new Subject<TestBattery>(),
    patientUpdated: 'battery: patientUpdated',
    nextItem: 'battery:nextItem',
    disconnectModal: 'battery: showDisconnect',
    behaviorShown: 'battery: behaviorShown',
    behaviorHidden: 'battery: behaviorHidden'
};

export const SyncEvents = {
    addBatteryToSync: new Subject<string>(),
    resetLogin: new BehaviorSubject<boolean>(null),
    running: 'sync:running',
    ended: 'sync:ended'
};

export const ToolbarEvents = {
    popover: 'toolbar:showPopover'
};
