import { TestBattery } from '../battery/test-battery';

export enum ContentDownloadStatus {
    DOWNLOADED,
    DOWNLOADING,
    EXTRACTED,
    EXTRACTING,
    FAILED,
    NOT_AVAILABLE,
    STARTED
}

export enum ContentDownloadWorkerMessageType {
    LOG_SUCCESS,
    LOG_WARN,
    LOG_INFO,
    MARK_PENDING,
    RECORD_PROGRESS,
    SAVE_ZIP
}

export interface ContentWorkerMessage {
    type: ContentDownloadWorkerMessageType;
    payload: any;
}

export class DownloadResult {
    blob?: Blob | ArrayBuffer;
    version: ContentVersion;
    subtestGuid: string;
    alreadyDownloaded?: boolean;
    fileUrl?: string;
    state?: ContentDownloadProgress;
}

export abstract class ProgressStatus {
    public abstract get downloadProgress(): number;
    public abstract get extractionProgress(): number;
    public abstract get downloadFailed(): boolean;
}

export class ContentDownloadProgress extends ProgressStatus {
    state: ContentDownloadStatus;
    subtestGuid: string;
    subtestName: string;
    total = 0;
    stimDownloaded = 0;
    contentDownloaded = 0;
    totalFilesToExtract = 0;
    filesExtracted = 0;
    zipfiles: ZipFile[] = [];
    public get downloadProgress(): number {
        if (this.state === ContentDownloadStatus.NOT_AVAILABLE) {
            return 0;
        }

        return Math.round(100 * ((this.stimDownloaded + this.contentDownloaded) / this.total));
    }
    public get extractionProgress(): number {
        if (this.state === ContentDownloadStatus.NOT_AVAILABLE) {
            return 0;
        }
        return this.totalFilesToExtract === 0 ? 0 :
            Math.round(100 * (this.filesExtracted / this.totalFilesToExtract));
    }

    public get hasStimFile(): boolean {
        return this.zipfiles.find(it => it.type === 'stim') !== undefined;
    }

    public get hasContentFile(): boolean {
        return this.zipfiles.find(it => it.type === 'content') !== undefined;
    }

    public get downloadFailed(): boolean {
        return this.state === ContentDownloadStatus.FAILED;
    }
}

export class BatteryContentStatus extends ProgressStatus {

    totalToDownload = 0;
    downloadedSize = 0;
    totalToExtract = 0;
    extracted = 0;
    anyFailedDownload = false;
    anyNotStarted;

    public get downloadProgress(): number {
        return this.totalToDownload === 0 || this.downloadedSize === 0
             ? 0 : (Math.round(100 * ((this.downloadedSize) / this.totalToDownload)));
    }

    public get extractionProgress(): number {
        return this.totalToExtract === 0 ? 0 : (Math.round(100 * ((this.extracted) / this.totalToExtract)));
    }

    public get downloadComplete(): boolean {
        return this.downloadProgress >= 100;
    }

    public get extractionComplete(): boolean {
        return this.extractionProgress >= 100;
    }

    public get allComplete(): boolean {
        return this.downloadComplete && this.extractionComplete;
    }

    public get downloadFailed(): boolean {
        return this.anyFailedDownload;
    }
}

export interface QuerySubtest {
    subtestGuid: string;
    testDisplayName: string;
    subtestName: string;
}

export interface ContentProgressStateMap {
    [key: string]: ContentDownloadProgress;
}

export interface ContentBatteryMap {
    [key: string]: TestBattery;
}

export interface ZipFile {
    file: string;
    type?: 'stim' | 'content';
    hash?: string;
}

export const ContentConstants = {
    MISSING: 'missing',
    CURRENT: 'current',
    UPDATE_AVAILABLE: 'update_available',
    CONTENT_TYPE: 'content',
    STIM_TYPE: 'stim'
};

export interface ContentVersion {
    status: 'missing' | 'current' | 'update_available';
    size?: number;
    url?: string;
    hash?: string;
    type: 'stim' | 'content';
}

export interface SubtestContentVersion {
    subtestGuid: string;
    subtestName: string;
    stimVersion: ContentVersion;
    contentVersion: ContentVersion;
}

export interface QuerySubtestContentInfo {
    availableContentSize: number;
    availableStimSize: number;
    currentContentSize: number;
    currentStimSize: number;
    subtestVersions: SubtestContentVersion[];
}

interface QueryVersionInfo { size: number; status: 'missing' | 'current' | 'update_available'; url: string; hash: string; }

export interface QueryVersionResult {
    success: boolean;
    content: Map<string, QueryVersionInfo>;
    stim: Map<string, QueryVersionInfo>;
}
