import * as datefns from 'date-fns';

export class DateUtils {

    public static isEqual(dateLeft: Date, dateRight: Date) {
        const date1 = new Date(this.format(dateLeft, 'MM/DD/YYYY'));
        const date2 = new Date(this.format(dateRight, 'MM/DD/YYYY'));
        return datefns.isEqual(date1, date2);
    }

    public static format(theDate: Date, theFormat: string) {
        return datefns.format(theDate, theFormat);
    }

    public static dateDifference(firstDate: Date, lastDate: Date) {
        const getDaysInMonthZeroIndex = (mth, year) => {
            const daysInMonth = [31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            return (
                daysInMonth[mth] ||
                ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28)
            );
        };

        const thisDate = {
            year: firstDate.getFullYear() > lastDate.getFullYear() ? firstDate.getFullYear() - 100 : firstDate.getFullYear(),
            month: firstDate.getMonth(),
            day: firstDate.getDate()
        };
        const thatDate = {
            year: lastDate.getFullYear(),
            month: lastDate.getMonth(),
            day: lastDate.getDate()
        };

        let age = thatDate.year - thisDate.year;
        let month = thatDate.month - thisDate.month;
        let day = thatDate.day - thisDate.day;

        if (thatDate.month < thisDate.month) {
            month += 12;
            age -= 1;
        }

        if (thatDate.day < thisDate.day) {
            day += getDaysInMonthZeroIndex(thatDate.month === 0 ? 11 : thatDate.month - 1, thatDate.year);
            if (month === 0) {
                month = 11;
                age -= 1;
            } else {
                month -= 1;
            }
        }

        return {
            year: age,
            month: month,
            day: day
        };
    }

    // requires an assessment patient param, returns the icon based on birthdate
    public static getThumbnailType(patient) {
        const currentYear = new Date().getFullYear();
        const birthYear = patient.dob.value.getFullYear();
        const gender = patient.gender.value.toLowerCase();

        const age = currentYear - birthYear <= 18 ? 'child' : 'adult';

        return [gender, age];
    }
}
