<ion-content class="login-component">
    <ion-fab *ngIf="isNative" class="settings" horizontal="end" slot="fixed">
        <ion-fab-button (click)="openAppSettings()" color="accent">
            <ion-icon name="settings"></ion-icon>
        </ion-fab-button>
    </ion-fab>
    <ion-grid class="login-ctr">
        <ion-row class="login-row ">
            <ion-col class="login-col">
                <ion-card class="login-card">
                    <div class="icon-wrap">
                        <img src="assets/img/q-blue2-a2-w.png" alt="Q Interative Logo">
                    </div>
                    <ion-card-content>
                        <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="login-form" novalidate color="medium">
                            <ion-grid>
                                <ion-row>
                                    <div class="validation-alert ion-padding" *ngIf="unauthorized">
                                        <ion-label class="ion-text-center" color="primary">
                                            {{ unauthorizedMessage }}
                                        </ion-label>
                                    </div>
                                    <div class="validation-alert ion-padding" *ngIf="acctPrelock">
                                        <ion-label class="validation-prelock-msg ion-text-center">
                                            {{ 'auth.login.message.lockWarning' | translate }}
                                        </ion-label>
                                    </div>
                                    <div class="validation-alert ion-padding" *ngIf="acctLock">
                                        <p>{{ 'auth.login.message.locked' | translate }}</p>
                                    </div>
                                </ion-row>
                                <ion-row class="input-row">
                                    <ion-input #username class="login-input" formControlName="username"
                                        (keypress)="onKeypress($event)" (focusin)="onFocus($event)" minlength="3"
                                        placeholder="{{ 'auth.login.label.username' | translate }}" spellcheck="false"
                                        autocapitalize="off" required>
                                    </ion-input>
                                </ion-row>
                                <ion-row class="input-row">
                                    <ion-input #password class="login-input" minlength="6" formControlName="password"
                                        (keypress)="onKeypress($event)" type="password" (focusin)="onFocus($event)"
                                        placeholder="{{ 'auth.login.label.password' | translate }}" required>
                                    </ion-input>
                                </ion-row>
                                <ion-row>
                                    <ion-col>
                                        <ion-button color="warning" size="large" [disabled]="!loginForm.valid || IS_LOCKED_OUT"
                                            type="submit" expand="block">
                                            {{ 'auth.login.label.login' | translate }}
                                        </ion-button>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </form>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>    
</ion-content>