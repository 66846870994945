import { Injectable } from '@angular/core';

import { Howl, Howler } from 'howler';

export interface AudioSprite {
    [key: string]: { start: number; end: number; };
}

export const SOUNDS: { [key: string]: Howl } = {};

export function unlockAudioContext() {
    // Fake a touch event to unlock WebAudio in ios.
    // This event listener is added in howler, when autoUnlock == true
    const evt = new TouchEvent('touchstart', { bubbles: true });
    document.dispatchEvent(evt);
}

/**
* Preload a single audio with or without sprites
* @param key string
* @param asset url to mp3 or any audio src
* @param sprite optional - sprite keys with start and ends
*/
export function preloadSound(key: string, asset: string) {
    Howler.autoUnlock = true;
    if (!SOUNDS[key]) {
        const howlProps: any = {
            src: [asset],
            preload: true,
            autoUnlock: true,
            mute: true
        };
        const sound = new Howl(howlProps);
        SOUNDS[key] = sound;
        if (Howler.ctx.state !== 'running') {
            unlockAudioContext();
        }

        return sound;
    }
}

export function preloadSprite(key: string, asset: string, sprite: AudioSprite = null) {
    if (!SOUNDS[key]) {
        const howlProps: any = {
            src: [asset],
            preload: true,
            mute: true,
            sprite: {}
        };
        Object.keys(sprite).forEach(k => howlProps.sprite[`${key}${k}`] = [sprite[k].start, sprite[k].end]);
        const sound = new Howl(howlProps);

        let spriteIds: string[];
        spriteIds = Object.keys(sprite).map(k => `${key}${k}`);
        SOUNDS[key] = sound;
        if (Howler.ctx.state !== 'running') {
            unlockAudioContext();
        }
        return sound;
    }
}

/**
 * Plays sound effects!
 *
 * Import the service in your component's contructor:
 *      constructor(private soundEffects: SoundEffectsService) { }
 *
 * Preload the sound in the main behavior component:
 *      preloadSound('click', 'assets/soundfx/click.mp3');
 *
 * Play the sound when desired:
 *      this.soundEffects.play('click');
 *
 * // For sprites, preload as:
 * preloadSprite('click', 'assets/soundfx/click.mp3', {
 *  A: { start: 0, end: 10 },
 *  B: { start 11, end: 20 }
 * });
 *
 * To play sprite B
 * this.soundEffects.play('click', 'B');
 */
@Injectable()
export class SoundEffectsService {

    constructor() {
    }

    /**
     *
     * @param key plays sound per key
     * @param spriteKey if present , play the sprite section by sprite key
     */
    play(key: string, spriteKey: string = null) {
        const soundToPlay = SOUNDS[key];
        if (!soundToPlay) {
            throw new Error(`Sound with key ${key} was not preloaded.`);
        }
        if (spriteKey) {
            soundToPlay.mute(false);
            soundToPlay.play(`${key}${spriteKey}`);
        } else {
            soundToPlay.mute(false).play();
        }
        return soundToPlay;
    }

    // this stops an audio track, seekss back to beginning
    stop(key: string) {
        const soundToStop = SOUNDS[key];
        if (!soundToStop) {
            throw new Error(`Sound with key ${key} does not exist.`);
        }
        return soundToStop.stop();
    }

    // this pauses an audio track, does not seek back to beginning
    pause(key: string) {
        const soundToPause = SOUNDS[key];
        if (!soundToPause) {
            throw new Error(`Sound with key ${key} does not exist.`);
        }
        return soundToPause.pause();
    }

    // sets the volume of a track
    setSoundVolume(key: string, val: number) {
        const soundToSet = SOUNDS[key];
        if (!soundToSet) {
            throw new Error(`Sound with key ${key} was not found.`);
        }
        if (val > 1) { val = 1; }
        if (val < 0) { val = 0; }
        soundToSet.volume(val);
        return soundToSet;
    }

    isPlaying(key: string): boolean {
        const theSound = SOUNDS[key];
        if (!theSound) {
            throw new Error(`Sound with key ${key} was not found.`);
        }
        return theSound.playing();
    }

    // loops a sound
    loop(key: string, shouldLoop: boolean) {
        const soundToLoop = SOUNDS[key];
        if (!soundToLoop) {
            throw new Error(`Sound with key ${key} was not found.`);
        }
        return soundToLoop.loop(shouldLoop);
    }

    mute(key: string, shouldMute: boolean) {
        const sound = SOUNDS[key];
        if (!sound) {
            throw new Error(`Sound with key ${key} was not found.`);
        }
        return sound.mute(shouldMute);
    }

    loadSounds() {
        if (Object.keys(SOUNDS).length) {
            // load all sounds
            Object.keys(SOUNDS).forEach((key) => { SOUNDS[key].load(); });
        }
        return this;
    }

    // kills the howler instance and stops all sounds
    unload() {
        if (Object.keys(SOUNDS).length) {
            Howler.unload();
            // clear out the SOUNDS const
            Object.keys(SOUNDS).forEach((key) => { delete SOUNDS[key]; });
        }

        return this;
    }

    getSounds() {
        return SOUNDS;
    }

    stopAllSounds() {
        const keys = Object.keys(this.getSounds());
        keys.forEach((key) => {
            if (this.isPlaying(key)) {
                this.stop(key);
            }
        });
    }
}
