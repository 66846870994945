export class DeepCopy {

    public static copy(src, dest?) {
        // The order of these branches matters -- for instance, arrays are also objects but need special treatment.
        if (!src) {
            return src;
        } else if (typeof src === 'function') {
            throw new Error('Can\'t clone a function.');
        } else if (src instanceof Array) {
            return DeepCopy.copyArray(src);
        } else if (typeof src === 'object') {
            return DeepCopy.copyObject(src, dest);
        } else {
            // Everything else is a value type, except for strings which are immutable and therefore can safely be aliased.
            return src;
        }
    }

    private static copyObject(src, dest?) {
        dest = dest || {};

        if (src.constructor && src.constructor !== Object) {
            throw new Error('Can\'t clone an object with a custom constructor');
        }

        for (const prop in src) {
            if (Object.prototype.hasOwnProperty.call(src, prop)) {
                dest[prop] = DeepCopy.copy(src[prop], null);
            }
        }
        return dest;
    }

    private static copyArray(src) {
        const dest = [];
        for (let i = 0; i < src.length; i++) {
            dest[i] = DeepCopy.copy(src[i], null);
        }
        return dest;
    }
}
