import { Injectable } from '@angular/core';

import { AssessEvents } from '@app/shared';

import { Observable, Subject } from 'rxjs';

import { Events } from './events.service';
import { Logger, LoggingService } from './logging.service';
import { PlatformService } from './platform.service';

/**
 * Provides information on network connection
 */
@Injectable({ providedIn: 'root' })
export class NetworkStatusService {
    private logger: Logger;
    private isOnline: boolean;
    private handleNetworkChange: EventListener;
    private connectionState: Subject<boolean>;

    constructor(private loggingService: LoggingService, private platformService: PlatformService, private events: Events) {
        this.logger = this.loggingService.getLogger('NetworkStatusService');
        this.connectionState = new Subject();
        // setting init native status from navigator as it doesnt have to wait
        this.isOnline = navigator.onLine;
        this.handleNetworkChange = () => {
            this.isOnline = navigator.onLine;
            const connected = this.isConnected();
            this.logger.debug(`Connection changed event fired. Connected ? ${connected}`);
            this.connectionState.next(connected);
        };
        this.initListeners();
        this.events.subscribe(AssessEvents.paused, () => {
            this.removeListeners();
        });
        this.events.subscribe(AssessEvents.resumed, () => {
            this.logger.success('App resumed and resetting network listener');
            this.initListeners();
        });
    }

    // Returns an observable for any subscibers listening for connection events
    public getConnectionState(): Observable<boolean> {
        return this.connectionState.asObservable();
    }

    public isConnected(): boolean {
        if (!this.platformService.isNative()) {
            // this way we can simulate local offline when running `ionic serve`
            // because marking the offline checkbox in chrome dev tools will have the effect of
            // making `navigator.onLine as false, but also has the effect of not able to download
            // the lazy webpack module from the local running ionic (webpack? ) http server. This is
            // not a problem in the device as even if its offline, it still can download local assets.
            // By adding a global window property as any value, we can circumvent that problem and
            // helps a developer to test the offline usecases in the browser
            const overridenOffline = window['local_offline'];
            return overridenOffline ? false : this.isOnline;
        }
        return navigator.onLine;
    }

    private initListeners() {
        this.logger.debug('Initing listeners');
        window.addEventListener('online', this.handleNetworkChange);
        window.addEventListener('offline', this.handleNetworkChange);
    }

    private removeListeners() {
        this.logger.debug('Removing native listeners');
        window.removeEventListener('online', this.handleNetworkChange);
        window.removeEventListener('offline', this.handleNetworkChange);
    }
}
