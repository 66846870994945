import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'battery-status',
    templateUrl: './battery-status.component.html',
    styleUrls: ['./battery-status.component.scss'],
})
export class BatteryStatusComponent implements OnInit {
    @Input() status: string;
    @Input() numSubtests: string;
    @Input() duration: number;
    constructor() {
    }

    ngOnInit() { }

}
