import { ItemTypes } from '@app/models/consts';

import { Subtest } from './subtest';

export class SubtestSummaryPage {

    itemType = ItemTypes.subtestSummary;
    parent: Subtest;
    subtest: Subtest;
    id: string;

    constructor(subtest: Subtest) {
        this.parent = subtest;
        this.subtest = subtest;
        this.id = `__subtestSummary:${this.subtest.subtestInstanceID}`;
    }

    public getUniqueId(): string {
        return this.id;
    }

    getDisplayName() {
        if (this.subtest) {
            return `${this.subtest.getTestDisplayName()} ${this.subtest.getDisplayName()}`;
        }
        return '';
    }
}
