import { Injectable } from '@angular/core';

import {
    BatterySyncData,
    ConsoleLogSyncData,
    ImageSyncData,
    SyncUploadTask,
    SyncWorkerMessage,
    SyncWorkerMessageType,
    UploadMode,
    UploadType
} from '@app/models';
import { AssessEvents, SyncEvents, UUID } from '@app/shared';
import { environment } from '@appenv';

import { Device } from '@ionic-native/device/ngx';

import { BehaviorSubject, Observable, Subject} from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { BatteryService } from '../battery.service';
import { Events } from '../events.service';
import { HttpService } from '../http.service';
import { ImageData, ImageService } from '../image.service';
import { Logger, LoggingService } from '../logging.service';
import { NetworkStatusService } from '../network-status.service';
import { PlatformService } from '../platform.service';
import { TaskQService } from '../taskq';
import { UserStoreService } from '../user-store.service';

const TASK_NAME = 'SYNC_OPERATION';
const SHARE_SYNC_URL = `${environment.centralEndpoint}/sync/syncBatteryData`;
const LOG_SYNC_URL = `${environment.centralEndpoint}/sync/errorlogData`;
const RETURN_CONTROL_TO_SHARE_URL = `${environment.centralEndpoint}/sync/returnControlToShare`;
const UPLOAD_FILE_URL = `${environment.centralEndpoint}/sync/uploadFile`;

/**
 * A facade for all Assess sync related operations.
 */
@Injectable({ providedIn: 'root' })
export class SyncService {

    private logger: Logger;
    private errors: any[] = [];

    constructor(
        private loggingService: LoggingService,
        private taskService: TaskQService,
        private events: Events,
        private batteryService: BatteryService,
        private networkStatusService: NetworkStatusService,
        private userStoreService: UserStoreService,
        private device: Device,
        private authService: AuthService,
        private httpService: HttpService,
        private imageService: ImageService,
        private platformService: PlatformService
    ) {

        this.logger = this.loggingService.getLogger('SyncService');
        const taskEvents = this.taskService.defineTask(TASK_NAME, (task: SyncUploadTask) => this.runSyncOperation(task),
            (task: SyncUploadTask) => this.cancelSyncTask(task));

        taskEvents.onIsEmpty.subscribe(val => {
            if (val) {
                this.events.publish(SyncEvents.ended, this.errors.length > 0 ? true : false);
                this.errors = [];
            }
        });

        taskEvents.onRunning.subscribe(() => this.events.publish(SyncEvents.running));
        taskEvents.onFailed.subscribe(val => this.errors.push({ data: val.data, error: val.error }));
    }

    public initEvents() {
        this.platformService.isPractitionerMode().then(isPracitioner => {
            if (!isPracitioner) {
                return;
            }
            this.events.subscribe(AssessEvents.logout, () => this.cancelPendingSyncs());
            this.networkStatusService.getConnectionState().subscribe(async connected => {
                if (!connected) {
                    this.cancelPendingSyncs();
                } else {
                    SyncEvents.resetLogin.next(true);
                    this.logger.warn(`Running pending syncs after coming back online`);
                    await this.authService.resetLogin();
                    this.events.publish(AssessEvents.loginReset);
                    SyncEvents.resetLogin.next(false);
                    await this.runPendingBatterySyncs();
                }
            });
            SyncEvents.addBatteryToSync.pipe(debounceTime(1000)).subscribe((id) => {
                if (!SyncEvents.resetLogin.value) {
                    this.queueBatteryForSync(id);
                }
            });
            this.events.subscribe(AssessEvents.loginSuccess, async () => await this.runPendingBatterySyncs());
        });
    }

    /**
     * Foreground sync of all eligible assessments. Eligible would mean those assessments that have not been synced before but were
     * changed since last sync.
     */
    public async startManualSync(): Promise<Observable<any>> {
        const manualStatus = new Subject<any>();
        const batteryIds: string[] = await this.userStoreService.getSyncPendingBatteryIds();

        const batteryTasks: SyncUploadTask[] = batteryIds.map(batteryId => {
            const data: BatterySyncData = {
                batteryId,
                isRemove: false,
                destURL: SHARE_SYNC_URL,
                cancelSubject: new BehaviorSubject(null)
            };
            const syncTask: SyncUploadTask = {
                data,
                mode: UploadMode.MANUAL,
                type: UploadType.BATTERY
            };
            return syncTask;
        });

        const batteryImageIds: string[] = await this.userStoreService.getImageSyncPendingBatteryIds();
        let batteryImageTasks: SyncUploadTask[] = [];
        for (let i = 0; i < batteryImageIds.length; i++) {
            const batteryId = batteryImageIds[i];
            const imageTasks = await this.getImageSyncTasksForBattery(batteryId, false);
            if (imageTasks && imageTasks.length) {
                batteryImageTasks = batteryImageTasks.concat(imageTasks);
            } else {
                this.batteryService.removeBatteryFromPendingImageSync(batteryId);
            }
        }

        await this.loggingService.clearConsoleLog();
        const consoleLogTasks: SyncUploadTask[] = await this.getAllConsoleLogsData();

        this.logger.debug(`Starting manual sync.`);

        const allTasks = batteryTasks.concat(batteryImageTasks, consoleLogTasks);
        if (allTasks.length === 0) {
            manualStatus.complete();
            return manualStatus;
        }
        allTasks.forEach(task => this.taskService.addToTask(TASK_NAME, task));

        this.taskService.onIsEmpty(TASK_NAME).subscribe(val => {
            if (val) {
                manualStatus.complete();
            }
        });
        return manualStatus;
    }

    private async getImageSyncTasksForBattery(batteryId: string, isRemove: boolean): Promise<SyncUploadTask[]> {
        const imageData: ImageData[] = await this.imageService.getImageDataForAssessment(batteryId);
        return imageData.map((data) => {
            const syncData: ImageSyncData = {
                batteryId: data.batteryId,
                subtestInstanceId: data.subtestInstanceId,
                imageName: data.imageName,
                nativePath: data.nativePath,
                fullPath: data.fullPath,
                destURL: UPLOAD_FILE_URL,
                isRemove,
                cancelSubject: new BehaviorSubject(null)
            };
            const syncTask: SyncUploadTask = {
                data: syncData,
                mode: UploadMode.MANUAL,
                type: UploadType.IMAGE
            };
            return syncTask;
        });
    }

    /**
     * Transfers a single battery and removes it and gives it 'ownership' to Central.
     * Typically when Assess removes a battery. This also changes the assessment progress state to be 'COMPLETE' in Central
     * @param batteryId
     */
    public async transferSingleBatteryDataToShareAndRemove(batteryId: string): Promise<Observable<any>> {
        this.logger.debug(`Transfering single battery data to central for ${batteryId}`);
        const removeStatus = new Subject<any>();
        if (!this.networkStatusService.isConnected()) {
             return;
        }
        // Syncs Battery to Central
        const data: BatterySyncData = {
            batteryId,
            isRemove: true,
            destURL: RETURN_CONTROL_TO_SHARE_URL,
            cancelSubject: new BehaviorSubject(null)
        };
        const syncTask: SyncUploadTask = {
            data,
            mode: UploadMode.BACKGROUND,
            type: UploadType.BATTERY
        };

        const allTasks: SyncUploadTask[] = await this.getImageSyncTasksForBattery(batteryId, true);
        allTasks.push(syncTask);
        allTasks.forEach(task => this.taskService.addToTask(TASK_NAME, task));

        this.taskService.onIsEmpty(TASK_NAME).subscribe(async (val) => {
            if (val) {
                const isBatteryInRepo = await this.batteryService.isBatteryInRepo(batteryId);
                // Battery not in repo means battery has been removed and sync was successful
                removeStatus.next(!isBatteryInRepo);
                removeStatus.complete();
            }
        });
        return removeStatus;
    }

    /**
     * Enques a battery to be synced in the background
     */
    public async queueBatteryForSync(batteryId: string) {
        this.batteryService.addBatteryToPendingSyncs(batteryId);
        if (!this.networkStatusService.isConnected() || !await this.authService.isLoggedIn()) {
            return;
        }
        const data: BatterySyncData = {
            batteryId,
            isRemove: false,
            destURL: SHARE_SYNC_URL,
            cancelSubject: new BehaviorSubject(null)
        };
        const syncTask: SyncUploadTask = {
            data,
            mode: UploadMode.BACKGROUND,
            type: UploadType.BATTERY
        };

        this.taskService.addToTask(TASK_NAME, syncTask);
    }

    /**
     * Runs all pending battery syncs
     */
    public async runPendingBatterySyncs(): Promise<void> {
        if (await this.authService.isLoggedIn()) {
            const batteryIds = await this.userStoreService.getSyncPendingBatteryIds();
            this.logger.info(`Running battery syncs for pending battery id ${batteryIds.join(',')}`);
            batteryIds.forEach(_ => this.queueBatteryForSync(_));
        }
    }

    /**
     * Returns last sync date
     */
    public getLastSuccessfulSyncDate(): Promise<string> {
        return Promise.resolve('');
    }

    /**
     * Uploads all images for all batteries in the pending repo.
     */
    public uploadAssessmentImages(imageData: ImageSyncData): Promise<any> {
        this.logger.debug(`Uploading battery image ${imageData.imageName} for subtest ${imageData.subtestInstanceId}`);
        return new Promise((resolve, reject) => {
            const subject = new Subject<SyncWorkerMessage>();
            subject.subscribe((result: SyncWorkerMessage) => {
                switch (result.type) {
                    case SyncWorkerMessageType.LOG_SUCCESS:
                        this.logger.success(result.payload);
                        break;
                    case SyncWorkerMessageType.LOG_ERROR:
                        this.logger.error(result.payload);
                        break;
                    default:
                        break;
                }
            }, (e) => {
                reject(e);
            }, () => {
                setTimeout(() => resolve(), 200);
            });
            // upload the image
            this.uploadImage(imageData, subject);
        });
    }

    /**
     * Cancels all pending syncs. Typically when logged out
     */
    public cancelPendingSyncs(): void {
        this.logger.info(`Cancelling all pending syncs`);
        this.taskService.cancelPending(TASK_NAME).then(() => this.logger.debug(`Cancelled all syncs`));
    }

    /**
     * Runs sync operation for battery, images and logs
     * @param task
     */
    public runSyncOperation(task: SyncUploadTask): Promise<any> {
        let promise;
        if (SyncEvents.resetLogin.value) {
            task.data.cancelSubject.next(true);
            return Promise.resolve();
        }
        switch (task.type) {
            case UploadType.CONSOLE_LOG:
                promise = this.uploadConsoleLogs(task.data as ConsoleLogSyncData);
                break;
            case UploadType.BATTERY:
                promise = this.uploadBatteryJson(task.data as BatterySyncData);
                break;
            case UploadType.IMAGE:
                promise = this.uploadAssessmentImages(task.data as ImageSyncData);
                break;
            default:
                // just an example, doesnt really come here
                promise = new Promise(res => {
                    setTimeout(() => {
                        this.logger.info(`Running task ${JSON.stringify(task, null, 5)}`);
                        res();
                    }, 1000);
                });
                break;
        }
        return promise;
    }

    /**
     *
     * @param task
     */
    public cancelSyncTask(task: SyncUploadTask): Promise<any> {
        task.data.cancelSubject.next(true);
        task.data.cancelSubject.complete();
        return Promise.resolve();
    }

    private async getAllConsoleLogsData(): Promise<SyncUploadTask[]> {
        const entries = await this.loggingService.getAllConsoleLogFileEntries();
        const logTasks: SyncUploadTask[] = entries.map(e => {
            const data: ConsoleLogSyncData = {
                isRemove: true,
                destURL: LOG_SYNC_URL,
                fileName: e.name,
                cancelSubject: new BehaviorSubject(null)
            };
            const syncTask: SyncUploadTask = {
                data,
                mode: UploadMode.MANUAL,
                type: UploadType.CONSOLE_LOG
            };
            return syncTask;
        });
        return logTasks;
    }

    /**
     * Uploads battery json to Central and removes it from the pending list
     */
    uploadBatteryJson(batteryData: BatterySyncData): Promise<string> {
        this.logger.debug(`Uploading battery json for ${batteryData.batteryId}`);
        return this.batteryService.getSavedTestBattery(batteryData.batteryId).then(json => {
            return new Promise((res, _rej) => {
                const sub = new Subject<SyncWorkerMessage>();
                sub.subscribe((_res: SyncWorkerMessage) => {
                    switch (_res.type) {
                        case SyncWorkerMessageType.CLEAR_PENDING:
                            this.batteryService.removeBatteryFromPendingSyncs(_res.payload)
                                .then(() => this.logger.debug(`Removed battery from pending`));
                            break;
                        case SyncWorkerMessageType.LOG_SUCCESS:
                            this.logger.success(_res.payload);
                            break;
                        case SyncWorkerMessageType.LOG_ERROR:
                            this.logger.error(_res.payload);
                            break;
                        case SyncWorkerMessageType.LOG_INFO:
                            this.logger.info(_res.payload);
                            break;
                        default:
                            break;
                    }
                }, e => {
                    _rej(e);
                }, () => {
                    json = null;
                    setTimeout(() => res(), 200);
                });
                this.uploadBattery(batteryData, json, sub);
            });
        });
    }


    /**
     * Uploading console log data to /errorLogData endpoint
     * @param logData
     */
    uploadConsoleLogs(logData: ConsoleLogSyncData): Promise<any> {
        this.logger.debug(`Uploading console logs from ${logData.fileName}`);
        return this.loggingService.readLogFile(logData.fileName).then(fileContent => {
            return new Promise((res, _rej) => {
                const sub = new Subject<SyncWorkerMessage>();
                sub.subscribe((_res: SyncWorkerMessage) => {
                    switch (_res.type) {
                        case SyncWorkerMessageType.LOG_SUCCESS:
                            this.logger.success(_res.payload);
                            break;
                        case SyncWorkerMessageType.LOG_ERROR:
                            this.logger.error(_res.payload);
                            break;
                        case SyncWorkerMessageType.LOG_INFO:
                            this.logger.info(_res.payload);
                            break;
                        case SyncWorkerMessageType.DELETE_LOG:
                            this.loggingService.deleteLogFile(_res.payload)
                                .then(() => this.logger.debug(`Deleted ${_res.payload}`));
                            break;
                        default:
                            break;
                    }
                }, e => {
                    _rej(e);
                }, () => {
                    const success = () => {
                        setTimeout(() => res(), 200);
                    };
                    if (logData.fileName === 'console.log.html') {
                        return this.loggingService.flush().then(() => success());
                    } else {
                        success();
                    }
                });
                this.uploadLogs(logData, {
                    model: this.device.model,
                    platform: this.device.platform,
                    uuid: this.device.uuid || UUID.generate().toString(),
                    version: this.device.version
                }, fileContent || '', sub);
            });
        });
    }

    /**
     * Uploads the battery json
     * @param batteryData
     * @param json
     * @param sub
     */
    private uploadBattery(batteryData: BatterySyncData, jsonString: string,
        sub: Subject<SyncWorkerMessage>): Promise<string> {
        return new Promise((res, _rej) => {
            const bodyFormData = { json: jsonString };
            if (SyncEvents.resetLogin.value) {
                sub.next({ type: SyncWorkerMessageType.LOG_ERROR, payload: `Cancelling request as login is being reset` });
                sub.error('Cancelling request as login is being reset');
                sub.complete();
                res();
                return;
            }
            this.httpService.post({ url: batteryData.destURL, data: bodyFormData, cancelSubject: batteryData.cancelSubject})
                .then((data) => {
                    sub.next({
                        type: SyncWorkerMessageType.LOG_SUCCESS,
                        payload: `Successful synced battery json for ${batteryData.batteryId}`
                    });
                    if (batteryData.isRemove && data && data.status === 'success') {
                        this.logger.debug(`Remove Assessment service returned success for ${batteryData.batteryId}`);
                        // delete battery from repo and storage
                        this.batteryService.removeBatteryFromRepo(batteryData.batteryId)
                            .then(() => this.userStoreService.removeBattery(batteryData.batteryId));
                    }
                    return true;
                }).then(() => {
                    sub.next({ type: SyncWorkerMessageType.CLEAR_PENDING, payload: batteryData.batteryId });
                }).catch(error => {
                    sub.next({ type: SyncWorkerMessageType.LOG_ERROR, payload: `Error syncing data ${JSON.stringify(error)}` });
                    sub.error(error);
                }).finally(() => {
                    sub.complete();
                    jsonString = null;
                    res();
                });
        });
    }

    /**
     * Uploads console log files
     * @param logData
     * @param device
     * @param fileContent
     * @param sub
     */
    private async uploadLogs(logData: ConsoleLogSyncData, device: any, fileContent: string,
        sub: Subject<SyncWorkerMessage>): Promise<string> {
        return new Promise((res, _rej) => {
            if (fileContent.trim().length === 0) { // if log is empty Central throws err
                sub.complete();
                res();
                return;
            }
            const json: any = {};
            json.date = new Date().toUTCString();
            json.name = device.model || 'Chrome';
            json.systemName = device.platform || 'MacOS';
            json.systemVersion = device.version || '1.0';
            json.model = device.model || 'Chrome';
            json.uniqueIdentifier = device.uuid;
            json.localizedModel = device.model || 'Chrome';
            json.log = fileContent;
            json.html = true;
            const bodyFormData = { json: JSON.stringify(json) };
            this.httpService.post({ url: logData.destURL, data: bodyFormData, cancelSubject: logData.cancelSubject})
                .then(() => {
                    sub.next({
                        type: SyncWorkerMessageType.LOG_SUCCESS,
                        payload: `Successful log data synced for ${logData.fileName}`
                    });
                    return true;
                }).then(() => {
                    if (logData.fileName === 'console.log.html') {
                        sub.next({
                            type: SyncWorkerMessageType.CLEAR_PENDING,
                            payload: ``
                        });
                    } else {
                        sub.next({
                            type: SyncWorkerMessageType.DELETE_LOG,
                            payload: logData.fileName
                        });
                    }
                    return;
                }).catch(error => {
                    sub.next({ type: SyncWorkerMessageType.LOG_ERROR, payload: `Error syncing data ${JSON.stringify(error)}` });
                    sub.error(error);
                }).finally(() => {
                    sub.complete();
                    res();
                });
        });
    }

    private uploadImage(imageData: ImageSyncData, sub: Subject<SyncWorkerMessage>): Promise<string> {
        const params = {
            assessmentId: imageData.batteryId,
            type: 'image',
            fileName: imageData.imageName,
            subtestInstanceID: imageData.subtestInstanceId
        };

        const success = (data) => {
            sub.next({
                type: SyncWorkerMessageType.LOG_SUCCESS,
                payload: `Successfully synced image ${imageData.imageName} for subtest ${imageData.subtestInstanceId}.`
            });
            if (data && data.status === 'success') {
                this.logger.debug(`Removing image ${imageData.imageName} for subtest ${imageData.subtestInstanceId} from file system.`);
                // delete image from image store
                this.imageService.deleteImageForAssessment(imageData.batteryId, imageData.subtestInstanceId, imageData.imageName);
                this.imageService.hasImages(imageData.batteryId).then((hasImages) => {
                    // if we've successfully synced the last image, remove the battery from the pending image sync list
                    if (!hasImages) {
                        this.batteryService.removeBatteryFromPendingImageSync(imageData.batteryId);
                    }
                });
            }
        };

        const error = (e) => {
            sub.next({ type: SyncWorkerMessageType.LOG_ERROR, payload: `Error syncing image ${JSON.stringify(e)}` });
            sub.error(e);
        };

        if (!this.platformService.isNative()) {
            return new Promise((resolve, _reject) => {
                const form = new FormData();
                Object.keys(params).forEach(p => form.append(p, params[p]));

                this.imageService.getImageBlob(imageData.fullPath).then((blob) => {
                    form.append('data', blob);

                    this.httpService.post(imageData.destURL, form)
                    .then(success)
                    .catch(error)
                    .finally(() => {
                        sub.complete();
                        resolve();
                    });
                }, (e) => this.logger.error('Error retrieving image blob.', e));
            });
        } else {
            return new Promise((resolve, _reject) => {
                this.httpService.uploadFile({
                    url: imageData.destURL,
                    data: params,
                    filePath: imageData.nativePath,
                    name: 'data',
                    cancelSubject: imageData.cancelSubject
                })
                .then(success)
                .catch(error)
                .finally(() => {
                    sub.complete();
                    resolve();
                });
            });
        }
    }
}
