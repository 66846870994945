import { Component, Input } from '@angular/core';

import { IDiscontinueRule } from '@app/rules/discontinue';

import { ModalController } from '@ionic/angular';

@Component({
    selector: 'discontinue',
    templateUrl: './discontinue.component.html',
    styleUrls: ['../overlays.scss', './discontinue.component.scss'],
})
export class DiscontinueComponent {
    discontinueRuleType: any;
    ruleData: any;

    @Input() set rule(theRule: IDiscontinueRule) {
        this.ruleData = theRule;
        this.discontinueRuleType = theRule.ruleType;
    }

    constructor(private modalCtrl: ModalController) { }

    public static getCSSClass() {
        return 'discontinue-modal';
    }

    onDismiss(action: 'discontinue' | 'cancel' | 'testLimits') {
        let didCancel = false;
        switch (action) {
            case 'discontinue':
                this.ruleData.setDiscontinuePoint(false);
                break;
            case 'testLimits':
                this.ruleData.setDiscontinuePoint(true);
                break;
            case 'cancel':
                didCancel = true;
                break;
            default:
                break;
        }

        this.modalCtrl.dismiss(this.ruleData.getDestination(didCancel));
    }
}
