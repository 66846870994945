import { ItemGroup, Subtest, Administrable } from '@app/models';
import { ItemTypes } from '@app/models/consts';
import { IBaseRule, INavResult, SubtestItem } from '@app/rules';
import { StartPointComponent } from '@app/rules/overlays/start-point/start-point.component';
import { IReverseRule, IStartPointRule } from '@app/rules/reverse';
import { ContentQuery } from '@app/shared';

export class ReverseRule implements IBaseRule, IReverseRule, IStartPointRule {
    container: Subtest | ItemGroup;
    subtest: Subtest;

    numFullScores: number;

    skipToQuestionId: string;
    skipFromQuestionId: string;
    skipToItem: Administrable;
    noSkipItem: Administrable;
    startPointIdx: number;

    startPointModalMsg: string;
    noSkipMsg: string;

    items: Array<any>;

    constructor(data, container: Subtest | ItemGroup) {
        this.container = container;
        this.skipToQuestionId = data.skipToQuestionID;
        this.skipFromQuestionId = data.skipFromQuestionID;
        this.noSkipMsg = data.noSkipMsg;
        this.startPointModalMsg = data.startPointModalMsg;

        this.container = container;
        this.subtest = ContentQuery.ancestorOfType(container, ItemTypes.subtest);
    }

    async navigate(currentItem: Administrable): Promise<INavResult> {
        if (!this.items) {
            this.items = ContentQuery.flatSubtest(this.subtest);
        }
        const currentItemIdx = this.items.findIndex(it => it.id === currentItem.id);
        const noSkipItem = this.items[currentItemIdx + 1];

        if (noSkipItem.id === this.skipFromQuestionId) {
            this.skipToItem = this.items.find(it => it.id === this.skipToQuestionId);
            this.noSkipItem = noSkipItem;
            return {
                shouldShowOverlay: true,
                overlayComponent: StartPointComponent,
                cssClass: StartPointComponent.getCSSClass()
            };
        }

        return null;
    }

    priority(): number {
        return 1;
    }

    setSubtestStartPoint(itemId: string) {
        const prevStartPointIfAny = this.items.find(_ => _.isAdministrable && _.isStartingPoint && _.isStartingPoint.value);
        if (prevStartPointIfAny) {
            prevStartPointIfAny.isStartingPoint.next(false);
        }
        this.startPointIdx = this.items.findIndex(i => i.id === itemId);
        this.setSkippedItems(this.startPointIdx, true);

        this.items[this.startPointIdx].isStartingPoint.next(true);
    }

    setSkippedItems(startPointIdx: number, shouldSkip: boolean) {
        for (let i = 0; i < startPointIdx; i++) {
            const item = this.items[i];
            if (item.itemType === ItemTypes.administrable) {
                item.wasSkipped.next(shouldSkip);
            }
        }
    }

    handleSwipeBack(currentItem: SubtestItem, prevItem: SubtestItem) {
        if (this.skipToItem && this.skipToItem.id === currentItem.id) {
            // we swiped back from the skipToItem to the noSkipItem
            this.setSkippedItems(this.startPointIdx, false);
            this.items[this.startPointIdx].isStartingPoint.next(false);
            this.startPointIdx = null;
        }
    }
}
