import { Component, OnInit } from '@angular/core';

import { Events } from '@app/core/providers';
import { AssessEvents } from '@app/shared';
import langs from '@appassets/i18n/langs.json';

import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'language-popover',
    templateUrl: './language-popover.component.html',
    styleUrls: ['./language-popover.component.scss'],
})
export class LanguagePopoverComponent implements OnInit {

    public langs: string[];
    constructor(private events: Events, private popoverCtrl: PopoverController) { }

    ngOnInit() {
        this.langs = langs;
    }

    selectLang(lang: string) {
        this.events.publish(AssessEvents.selectLang, lang);
        this.popoverCtrl.dismiss();
    }

    selectPlatformDefault() {
        this.events.publish(AssessEvents.selectDefaultLang);
        this.popoverCtrl.dismiss();
    }
}
