import { Injectable } from '@angular/core';

import { IBatteryItem, IStatus } from '@app/interfaces/battery-status';
import { TestBattery, Patient } from '@app/models';
import { AssessEvents, BatteryEvents, SyncEvents } from '@app/shared';

import { Events } from './events.service';
import { LoggingService, Logger } from './logging.service';
import { UserStoreService } from './user-store.service';

@Injectable({ providedIn: 'root' })
export class BatteryService {

    private status: IStatus;
    private inited = false;
    logger: Logger;

    constructor(
        private events: Events,
        private loggingService: LoggingService,
        private userStoreService: UserStoreService
    ) {
        this.logger = this.loggingService.getLogger('BatteryService');
        // clear on login as well as logout to handle back button in browser
        this.events.subscribe(AssessEvents.login, () => this.resetState());
        this.events.subscribe(AssessEvents.logout, () => this.resetState());
        this.events.subscribe(BatteryEvents.patientUpdated,
            (patient: Patient, excludedBatteryId: string) => this.updatePatientForBatteries(patient, excludedBatteryId)
                .finally(() => this.logger.success(`Updated patient batteries`)));
    }

    // Saves the subtest data.
    public async saveBattery(battery: TestBattery, skipSync = false): Promise<boolean> {
        const json = JSON.stringify(battery.serializeForSave());
        const batteryId = battery.id;
        try {
            await this.userStoreService.saveBattery(batteryId, json);
            this.logger.success(`Saved battery json.`);
            const res = await this.addBatteryToRepoWithId(batteryId, battery.patient.guid);
            if (!skipSync) {
                SyncEvents.addBatteryToSync.next(batteryId);
            }
            return res;
        } catch (e) {
            this.logger.error(`Battery ${batteryId} could not be saved.`);
            throw e;
        }
    }

    /**
     * Retrieves the saved battery with the given id.
     * @param batteryId - the id of the battery to get
     */
    public async getSavedTestBattery(batteryId: string): Promise<string> {
        return await this.getBatteryContents(batteryId);
    }

    private async getBatteryContents(batteryId: string): Promise<string> {
        const battery = await this.userStoreService.getSavedBattery(batteryId);
        if (!battery) {
            this.logger.error(`Battery for ${batteryId} not found. Removing from battery dao now.`);
            await this.removeBatteryFromRepo(batteryId);
        }
        return battery;
    }

    /**
     * Adds the battery with the provided id to the repo.
     * @param batteryId - the battery id
     */
    public async addBatteryToRepoWithId(batteryId: string, patientId: string): Promise<boolean> {
        this.logger.debug(`Adding battery ${batteryId} to repo.`);

        const status = await this.isBatteryInRepo(batteryId);
        if (!status) {
            const item: IBatteryItem = { id: batteryId };
            const repos: IBatteryItem[] = await this.getRepoItems();

            repos.push(item);
            await this.saveStatus();
            await this.userStoreService.addBatteryIdToPatient(batteryId, patientId);
        }
        return true;
    }

    /**
     * Adds the battery to the pending syncs array
     * @param batteryId - the id of the battery to remove
     */
    public async addBatteryToPendingSyncs(batteryId: string): Promise<string> {
        const status = await this.isBatteryInRepo(batteryId);
        if (status) {
            await this.userStoreService.addBatteryToPendingSyncs(batteryId);
            await this.userStoreService.addBatteryToPendingImageSync(batteryId);
        }
        return batteryId;
    }

    /**
     * Remove battery from pending sync array
     * @param batteryId - the id of the battery to remove
     */
    public async removeBatteryFromPendingSyncs(batteryId: string): Promise<void> {
        const status = await this.isBatteryInRepo(batteryId);
        if (status) {
            await this.userStoreService.removeBatteryFromPendingSyncs(batteryId);
        }
    }

    /**
     * Remove battery from pending image sync array
     * @param batteryId - the id of the battery to remove
     */
    public async removeBatteryFromPendingImageSync(batteryId: string): Promise<void> {
        const status = await this.isBatteryInRepo(batteryId);
        if (status) {
            await this.userStoreService.removeBatteryFromPendingImageSync(batteryId);
        }
    }

    /**
     * Removes the battery with the provided id from the repo.
     * @param batteryId - the id of the battery to remove
     */
    public async removeBatteryFromRepo(batteryId: string): Promise<boolean> {
        const status = await this.isBatteryInRepo(batteryId);
        if (status) {
            const battery = JSON.parse(await this.userStoreService.getSavedBattery(batteryId));

            // remove it from the user's repo
            let repo: IBatteryItem[] = await this.getRepoItems();
            repo = repo.filter(it => it.id !== batteryId);
            this.status.repo = repo;
            await this.saveStatus();

            // remove it from the patient list
            await this.userStoreService.removeBatteryIdFromPatient(batteryId, battery.patient.guid);

            // remove it from the DB
            this.userStoreService.removeBattery(batteryId);
        }
        return status;
    }

    /**
     * Determines if the battery with the given id is in the repo.
     * @param batteryId - the id of the battery
     */
    public async isBatteryInRepo(batteryId: string): Promise<boolean> {
        const repo: IBatteryItem[] = await this.getRepoItems();
        return repo.map(r => r.id).indexOf(batteryId) > -1;
    }

    // Gets the battery items in the repo.
    public async getRepoItems(): Promise<IBatteryItem[]> {
        await this.initIfNot();
        return this.status.repo;
    }

    // Gets the ids of the batteries in the repo.
    public async getRepoIds(): Promise<string[]> {
        const repo: IBatteryItem[] = await this.getRepoItems();
        return repo.map(r => r.id);
    }

    public resetState(): void {
        this.inited = false;
        this.status = null;
    }

    /**
     * Updates battery json's patient data for all batteries belonging to patient
     * @param patient
     * @param excludedBatteryId
     */
    private async updatePatientForBatteries(patient: Patient, excludedBatteryId: string): Promise<void> {
        let batteryIds = await this.userStoreService.getBatteryIdsForPatient(patient.guid);
        batteryIds = batteryIds.filter(_ => _ !== excludedBatteryId);
        for (const id of batteryIds) {
            const content = await this.getBatteryContents(id);
            if (content) {
                try {
                    const json = JSON.parse(content);
                    json.patient = patient.serializeForSave();
                    await this.userStoreService.saveBattery(id, JSON.stringify(json));
                } catch (e) {
                    this.logger.warn(`Error in parsing json for battery id ${id}`);
                }
            }
        }
    }

    private async initIfNot(): Promise<boolean> {
        if (this.inited === false) {
            this.status = await this.loadOrCreateBatteryStatus();
            this.inited = true;
            this.logger.debug(`Got battery status: ${JSON.stringify(status)}.`);
            await this.saveStatus();
        }
        return true;
    }

    private createBatteryItem(batteryId: string): IBatteryItem {
        return {
            id: batteryId || null,
            images: []
        };
    }

    private createNewStatus(): IStatus {
        return {
            repo: []
        };
    }

    private async saveStatus(): Promise<void> {
        this.logger.debug('Saving battery status.');
        try {
            await this.userStoreService.saveBatteryStatus(this.status);
            this.logger.success(`Wrote battery dao status with status ${JSON.stringify(this.status)}`);
        } catch (e) {
            this.logger.error(`Error saving battery dao status.`, e);
        }
    }

    private async loadOrCreateBatteryStatus(): Promise<IStatus> {
        try {
            const batteryStatus: IStatus = await this.userStoreService.getBatteryStatus();

            if (!batteryStatus) {
                this.logger.debug('No status found, creating it.');

                const newStatus: IStatus = this.createNewStatus();
                const repo: IBatteryItem[] = await this.getAllBatteriesAndAddToRepoArray();
                newStatus.repo = repo;
                return newStatus;
            }
            return batteryStatus;
        } catch (e) {
            this.logger.error('Error loading battery status.', e);
            throw e;
        }
    }

    private async getAllBatteriesAndAddToRepoArray(): Promise<IBatteryItem[]> {
        const savedBatteryIds: string[] = await this.userStoreService.getSavedBatteryIds();
        const repo: IBatteryItem[] = [];
        savedBatteryIds.forEach(id => {
            repo.push(this.createBatteryItem(id));
        });
        this.logger.debug('Added all batteries to the repo.');
        return repo;
    }
}
