import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ɵDomEventsPlugin } from '@angular/platform-browser';

/**
 * Registers a 'tap' and binds to touchstart for native and mousedown for desktop.
 * Will be deprecated or removed when we get to NG9
 */
@Injectable({ providedIn: 'root' })
export class TapEventPlugin extends ɵDomEventsPlugin {

    constructor(@Inject(DOCUMENT) doc: any) {
        super(doc);
    }

    supports(eventName: string): boolean {
        return eventName === 'tap';
    }

    addEventListener(element: HTMLElement, eventName: string, handler: Function): Function {
        const zone = this.manager.getZone();
        const hasPointer = window.PointerEvent;
        const documentEvent = hasPointer ? 'pointerdown' : 'touchstart';
        return this.setupEventBinding(element, documentEvent, (event) => zone.run(() => handler(event)));
    }

    private setupEventBinding(target: HTMLElement, eventName: string, handler: Function): Function {
        const proxy = (event: Event) => handler(event);
        const addFunction = () => target.addEventListener(eventName, proxy, false);
        const removeFunction = () => target.removeEventListener(eventName, proxy, false);
        this.manager.getZone().runOutsideAngular(addFunction);
        return removeFunction;
    }
}
