import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginUserInfo } from '@app/models/auth/login.state';
import { BatteryFilterPipe } from '@app/pipes/battery-filter.pipe';
import { SafePipe } from '@app/pipes/safe.pipe';

import { IonicModule } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';

import { TranslateModule } from '@ngx-translate/core';

import { SVGDirective } from './directives/svg.directive';

import { RoundProgressModule } from 'angular-svg-round-progressbar';

@NgModule({
    declarations: [BatteryFilterPipe, SafePipe, SVGDirective ],
    providers: [Device, SafePipe],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        RoundProgressModule,
        TranslateModule
    ],
    exports: [
        BatteryFilterPipe,
        SafePipe,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        RoundProgressModule,
        SVGDirective,
        TranslateModule
    ]
})

export class SharedModule {
    currentLoggedInUser: LoginUserInfo;

    constructor() { }
}

export { TranslateService } from '@ngx-translate/core';
export { DefaultLangHttpLoader } from './translate/translate.http.loader';
