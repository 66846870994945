import { Administrable, ItemGroup, Subtest } from '@app/models';
import { INavResult } from '@app/rules';
import { ReverseRule } from '@app/rules/reverse/reverse-rule';


export class AgeBasedReverseRule extends ReverseRule {
    startPointModalMsg: string;
    noSkipMsg: string;

    minAge: number;
    maxAge: number;

    constructor(data: any, container: Subtest | ItemGroup) {
        super(data, container);

        this.startPointModalMsg = data.startPointModalMsg;
        this.noSkipMsg = data.noSkipMsg;
        this.minAge = data.minAge;
        this.maxAge = data.maxAge;
    }

    async navigate(item: Administrable): Promise<INavResult> {
        if (this.ageInRange()) {
            return super.navigate(item);
        }
        return null;
    }

    /**
     * Determines if this rule applies to the client based on age.
     */
    ageInRange() {
        const age = this.subtest.battery.getPatientAge().year;
        return age >= this.minAge && age <= this.maxAge;
    }
}
