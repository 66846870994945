import { IItemGroup } from '@app/interfaces/battery-components';
import { ItemTypes } from '@app/models/consts';
import { IBaseRule } from '@app/rules';
import { CommonUtils, ContentQuery } from '@app/shared/utils';

import { SubtestRef } from '../battery/subtest-ref';
import { Administrable } from './administrable';
import { Question } from './question';

export class SingleCardItemList<T extends IItemGroup> {

    title: String;
    type: String;
    itemGroups: Array<T>;
    children: Array<any>;
    questions: Array<any>;
    administrables: Array<Administrable>;
    parent: any;
    itemType = ItemTypes.scil;
    id: string;
    subtest: SubtestRef;

    // stopwatch attrs
    warningTime: number;
    hasTimer: boolean;
    maxTime: number;
    elapsedMarkerOnStop: boolean;
    elapsedTime: number;

    rules: Array<IBaseRule> = [];

    constructor(json, private itemGroupConstructorType: new (arg: any) => T) {
        if (!json.title) {
            throw new Error('Single card item list doesn\'t have a title.');
        }
        this.title = json.title;
        this.type = json.type;

        if (json.shouldUseTimer) {
            if (CommonUtils.isNumber(json.maxTime) && json.maxTime >= 0) {
                this.maxTime = json.maxTime;
            } else {
                throw new Error(`Item ${this.title} has invalid maxTime property`);
            }
            this.warningTime = json.warningTime || 10;
            this.hasTimer = true;
            this.elapsedMarkerOnStop = !!json.elapsedMarkerOnStop;
        }

        if (json.itemGroups && json.itemGroups.length > 0) {
            this.itemGroups = this.children = json.itemGroups.map((it) => {
                return new this.itemGroupConstructorType(it);
            });
            this.questions = [];
        } else if (json.questions && json.questions.length > 0) {
            this.questions = this.children = json.questions.map((it) => {
                return Question.createItem(it);
            });
            this.itemGroups = [];
        } else {
            throw new Error('SingleCardItemList doesn\'t have questions.');
        }

        this.administrables = ContentQuery.administrable(this);
        this.id = `SCIL:${this.title}${this.questions.length}`;
    }

    public getUniqueId(): string {
        return this.id;
    }

    public getDisplayName(): string {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return `${subtest.getDisplayName()} ${this.title}`;
    }

    public getSubtestName() {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return subtest.getDisplayName();
    }

    public setParent(parent) {
        this.parent = parent;
        this.children.forEach(q => q.setParent(this));
    }

    public serializeForSave() {
        const result: any = {
            type: this.type,
            questions: this.questions.map(q => q.serializeForSave()),
            itemGroups: this.itemGroups.map(ig => ig.serializeForSave()),
            rules: this.rules.map((rule) => rule.serializeForSave ? rule.serializeForSave() : null)
        };
        if (this.hasTimer) {
            result.hasTimer = true;
            result.completionTime = this.elapsedTime;
            result.elapsedMarkerOnStop = this.elapsedMarkerOnStop;
        }
        return result;
    }

    public restoreSavedData(savedState) {
        if (savedState.rules) {
            if (savedState.rules.length !== this.rules.length) {
                throw new Error(`Saved data rule and current rules length mismatch: ${savedState.rules}-${this.rules}`);
            }

            savedState.rules.forEach((r, idx) => {
                if (this.rules[idx].restoreSavedData) {
                    this.rules[idx].restoreSavedData(r);
                }
            });
        }

        if (this.questions.length > 0) {
            this.questions.forEach((q, idx) => q.restoreSavedData(savedState.questions[idx]));
        }

        if (this.itemGroups.length > 0) {
            this.itemGroups.forEach((l, idx) => l.restoreSavedData(savedState.itemGroups[idx]));
        }
        this.hasTimer = savedState.hasTimer === true;
        if (savedState.hasTimer) {
            this.elapsedMarkerOnStop = savedState.elapsedMarkerOnStop;
        }
    }

    public unload() {
        if (this.questions.length > 0) {
            this.questions.forEach((q) => q.unload());
        }

        if (this.itemGroups.length > 0) {
            this.itemGroups.forEach((ig) => ig.unload());
        }
    }
}
