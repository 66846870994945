import { Injectable } from '@angular/core';

import { IEventData, ITaskOptions, TaskEvents } from '@app/models';

import { Observable, Subject } from 'rxjs';

interface IHandlerMap { [taskName: string]: (task) => Promise<any>; }
interface ITaskOptionsMap { [taskName: string]: ITaskOptions; }
interface ICountersMap { [taskName: string]: number; }
interface IRunningMap { [taskName: string]: boolean; }
interface IStartedMap { [taskName: string]: number; }
interface ITaskEventMap {
    [taskName: string]: {
        empty: Subject<boolean>;
        delayed: Subject<void>;
        failed: Subject<IEventData>;
        finished: Subject<IEventData>;
        maxReached: Subject<void>;
        running: Subject<void>;
        started: Subject<IEventData>;
        success: Subject<IEventData>;
    };
}
interface ITaskEntryMap {
    data: any;
    resolve?: (...args: any[]) => any | void;
    reject?: (err: any) => any | void;
}
interface ITaskMap { [taskName: string]: ITaskEntryMap[]; }

/**
 * A simple promise based que which is FIFO. This emits various observables like when
 * the task is empty, started or whether running
 * Usage
 *   taskQService.defineTask('syncOperation', (task) => {
 *       return new Promise((res, rej) => {
 *           setTimeout(() => {
 *               res(task.c.toUpperCase());
 *           }, 300);
 *       });
 *   }, (task) => { // cancellations
 *         return new Promise.resolve()
 *   });
 *
 *   taskQService.onIsEmpty.subscribe((empty: IEmpty) => {
 *       if (empty.status) {
 *           console.log(`${empty.taskName} is empty now`);
 *       } else {
 *           console.log(`${empty.taskName} NOT empty`);
 *       }
 *   });
 *   taskQService.onRunning.subscribe(task => {
 *       console.log(`Running ${task} `);
 *   });
 *
 *   taskQService.addToTask('syncOperation', {c: 'i'}).then(val => console.log(val));
 *   taskQService.addToTask('syncOperation', {c: 'i'}).then(val => console.log(val));
 *   taskQService.addToTask('syncOperation', {c: 'n'}).then(val => console.log(val));
 *   console.log(`Pending data`, taskQService.getPendingTaskData('syncOperation'));
 *   setTimeout(() => {
 *       console.log(`Pending data`, taskQService.getPendingTaskData('syncOperation'));
 *       taskQService.addToTask('syncOperation', {c: 'd'}).then(val => console.log(val));
 *       taskQService.addToTask('syncOperation', {c: 'i'}).then(val => console.log(val));
 *       console.log(`Pending data`, taskQService.getPendingTaskData('syncOperation'));
 *   }, 1000);
 *
 *   setTimeout(() => {
 *       console.log(`Pending data`, taskQService.getPendingTaskData('syncOperation'));
 *       taskQService.addToTask('syncOperation', {c: 'a'}).then(val => console.log(val));
 *       console.log(`Pending data`, taskQService.getPendingTaskData('syncOperation'));
 *   }, 3000);
 *
 *   setTimeout(() => console.log('Ended after 30 seconds'), 4000);
 */
@Injectable({ providedIn: 'root' })
export class TaskQService {

    private handlers: IHandlerMap = {};
    private cancelHandlers: IHandlerMap = {};
    private taskOptions: ITaskOptionsMap = {};
    private counters: ICountersMap = {};
    private running: IRunningMap = {};
    private starts: IStartedMap = {};
    private tasks: ITaskMap = {};
    private pendingTasksData: ITaskMap = {};
    private taskEvents: ITaskEventMap = {};

    /**
     * Registers a task with a name and handler which gets the context and data for the task to run
     * @param taskName
     * @param handler
     * @param cancelHandler nullable
     * @param options
     */
    public defineTask(taskName: string, handler: (task: any) => Promise<any>,
        cancelHandler: (task: any) => Promise<any> = (task: any) => Promise.resolve(),
        options: ITaskOptions = { concurrency: 1, interval: 0 }): TaskEvents {
        this.handlers[taskName] = handler;
        this.cancelHandlers[taskName] = cancelHandler;
        this.taskOptions[taskName] = options;
        this.counters[taskName] = 0;
        this.running[taskName] = false;

        const delayed = new Subject<void>();
        const empty = new Subject<boolean>();
        const failed = new Subject<IEventData>();
        const success = new Subject<IEventData>();
        const finished = new Subject<IEventData>();
        const started = new Subject<IEventData>();
        const running = new Subject<void>();
        const maxReached = new Subject<void>();

        const taskEvents: TaskEvents = {
            onDelayed: delayed.asObservable(),
            onIsEmpty: empty.asObservable(),
            onFailed: failed.asObservable(),
            onFinished: finished.asObservable(),
            onMaxReached: maxReached.asObservable(),
            onRunning: running.asObservable(),
            onStarted: started.asObservable(),
            onSuccess: success.asObservable()
        };
        this.taskEvents[taskName] = {
            empty,
            delayed,
            failed,
            finished,
            maxReached,
            running,
            started,
            success
        };
        return taskEvents;
    }

    /**
     * Adds a task with its data
     * @param taskName
     * @param taskData
     */
    public addToTask(taskName: string, taskData: any): Promise<any> {
        if (this.pendingTasksData[taskName] === undefined) {
            this.pendingTasksData[taskName] = [];
        }
        this.pendingTasksData[taskName].push({ data: taskData });
        if (!this.handlers[taskName]) {
            throw new Error(`Attempted to add to an undefined task -> ${taskName}`);
        }
        if (!this.handlers[taskName]) {
            throw new Error(`Attempted to add to an undefined task -> ${taskName}`);
        }
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });
        if (this.tasks[taskName] === undefined) {
            this.tasks[taskName] = [];
        }
        this.taskEvents[taskName].empty.next(false);
        this.tasks[taskName].push({
            data: taskData,
            reject,
            resolve
        });
        this.tryRun(taskName);
        return promise;
    }

    /**
     * Gets pending task data
     * @param taskName
     */
    public getPendingTaskData(taskName: string): any[] {
        return this.pendingTasksData[taskName].map(it => it.data);
    }

    /**
     * Cancels pending actions for a taskname
     * @param taskName
     */
    public cancelPending(taskName: string): Promise<void> {
        const pendingOnes = (this.pendingTasksData[taskName] || []).map(it => it.data);
        this.pendingTasksData[taskName] = [];
        this.tasks[taskName] = [];
        this.running[taskName] = false;
        return pendingOnes.reduce((previousPromise, taskData) => {
            return previousPromise.then(() => {
                return this.cancelHandlers[taskName](taskData);
            });
        }, Promise.resolve());
    }

    // -------- Observables which always return  the name of the task thats involved
    public onDelayed(taskName: string): Observable<void> {
        return this.taskEvents[taskName].delayed.asObservable();
    }

    public onIsEmpty(taskName: string): Observable<boolean> {
        return this.taskEvents[taskName].empty.asObservable();
    }

    public onFailed(taskName: string): Observable<IEventData> {
        return this.taskEvents[taskName].failed.asObservable();
    }

    public onSuccess(taskName: string): Observable<IEventData> {
        return this.taskEvents[taskName].success.asObservable();
    }

    public onFinished(taskName: string): Observable<IEventData> {
        return this.taskEvents[taskName].finished.asObservable();
    }

    public onStarted(taskName: string): Observable<IEventData> {
        return this.taskEvents[taskName].started.asObservable();
    }
    public onRunning(taskName: string): Observable<void> {
        return this.taskEvents[taskName].running.asObservable();
    }

    public onMaxReached(taskName: string): Observable<void> {
        return this.taskEvents[taskName].maxReached.asObservable();
    }
    // --------------------------------------------------------------------------

    // ------- PRIVATE METHODS ---------------
    /**
     * Tries to run all tasks serially
     * @param taskName
     */
    private tryRun(taskName: string): void {
        const maxTasks = this.taskOptions[taskName].concurrency;
        const waitTime = this.remainingInterval(taskName);
        if (this.tasks[taskName].length > 0) {
            if (waitTime <= 0) {
                if (this.counters[taskName] < maxTasks) {
                    if (this.running[taskName] === false) {
                        this.markQueueRunning(taskName);
                    }
                    this.runTask(taskName);
                } else {
                    this.taskEvents[taskName].maxReached.next();
                }
            } else {
                this.taskEvents[taskName].delayed.next();

                setTimeout(() => {
                    this.tryRun(taskName);
                }, waitTime);
            }
        } else if (this.running[taskName] === true) {
            this.markEmpty(taskName);
        }
    }

    private runTask(taskName: string): void {
        const task = this.tasks[taskName].shift();
        const waitTime = this.remainingInterval(taskName);
        if (task) {
            this.markStarted(taskName, task);
            this.handlers[taskName](task.data)
                .then(result => {
                    this.markSuccess(taskName, task);
                    this.pendingTasksData[taskName] = this.pendingTasksData[taskName].filter(it => it.data !== task.data);
                    task.resolve(result);
                    return true;
                })
                .catch(e => {
                    this.markFailed(taskName, task, e);
                    this.pendingTasksData[taskName] = this.pendingTasksData[taskName].filter(it => it.data !== task.data);
                    task.reject(e);
                })
                .then(() => this.runTask(taskName));
        } else {
            setTimeout(() => {
                this.tryRun(taskName);
            }, waitTime);
        }
    }

    private remainingInterval(taskName: string): number {
        const taskInterval = this.taskOptions[taskName].interval * 1000;
        const lastTask = this.starts[taskName] || 0;
        return (lastTask + taskInterval) - Date.now();
    }

    private markQueueRunning(taskName: string): void {
        this.taskEvents[taskName].running.next();
        this.running[taskName] = true;
    }

    private markEmpty(taskName: string): void {
        this.taskEvents[taskName].empty.next(true);
        this.running[taskName] = false;
    }

    private markStarted(taskName: string, task: any): void {
        this.counters[taskName] += 1;
        this.starts[taskName] = Date.now();
        this.taskEvents[taskName].started.next({ data: task.data });
    }

    private markFinished(taskName: string, task: any): void {
        this.counters[taskName] -= 1;
        this.taskEvents[taskName].finished.next({ data: task.data });
    }

    private markSuccess(taskName: string, task: any): void {
        this.markFinished(taskName, task);
        this.taskEvents[taskName].success.next({ data: task.data });
    }

    private markFailed(taskName: string, task: any, error: any): void {
        this.markFinished(taskName, task);
        this.taskEvents[taskName].failed.next({ data: task.data, error });
    }
}
