<ion-content class="inner-content">
    <ion-list class="observations-list" lines="none">
        <ion-list-header class="title">
            <ion-label>{{testTitle}} - {{'components.observations.title' | translate}}</ion-label>
        </ion-list-header>
        <ion-item class="subtitle">
            <ion-label>{{subtestTitle}}</ion-label>
        </ion-item>
        <ion-item *ngFor="let observation of observations;">
            <ion-checkbox color="primary" *ngIf="observation.type === 'Boolean'" [checked]="observation.isChecked"
                (ionChange)="toggleChecked(observation)"></ion-checkbox>
            <ion-label class="ion-text-wrap observation-name">{{observation.label}}</ion-label>
        </ion-item>
    </ion-list>
</ion-content>