import { ISubtestRef } from '@app/interfaces/battery-components';
import { Observations } from '@app/interfaces/common';
import { Subtest } from '@app/models/subtest/subtest';
import { TestStates } from '@app/models/consts';
import { UUID } from '@app/shared/utils';

import { Test } from './test';
import { TestBattery } from './test-battery';

/**
 * Represents a reference to a subtest, which may or may not be loaded.  We only load subtest models
 * when it is being administered or reviewed.  Until then, the SubtestRef will hold its state json.
 */
export class SubtestRef {
    private stateJson: any;
    private subtestModel: Subtest;
    private contentJson: any;

    public abbr: string;
    public averageDuration: number;
    public normType: string;
    public subtestDisplayName: string;
    public subtestInstanceID?: string;
    public subtestGUID: string;
    public subtestName: string;
    public testDisplayName: string;
    public testGUID: string;
    public testName: string;

    constructor(
        subtestData: ISubtestRef,
        stateContent?: any
    ) {
        this.contentJson = null;
        this.stateJson = stateContent;

        this.abbr = subtestData.abbr || '';
        this.testName = subtestData.testName;
        this.normType = subtestData.normType;
        this.subtestDisplayName = subtestData.subtestDisplayName;
        this.subtestGUID = subtestData.subtestGUID;
        this.subtestName = subtestData.subtestName;
        this.testDisplayName = subtestData.testDisplayName || subtestData.testName;
        this.testGUID = subtestData.testGUID;
        this.testName = subtestData.testName;
        this.averageDuration = subtestData.averageDuration;
        this.subtestInstanceID = subtestData.subtestInstanceID;

        if (this.stateJson && this.stateJson.neverLoaded) {
            this.stateJson = null;
        }

        if (!this.subtestInstanceID) {
            this.subtestInstanceID = UUID.generate();
        }
    }

    // Returns the state of the subtest.
    public state(): string {
        if (this.subtestModel) {
            return this.subtestModel.state();
        } else if (!this.stateJson || this.stateJson.neverLoaded) {
            return TestStates.notStarted;
        }
        return this.stateJson.derivedState.state;
    }

    // Returns if the subtest has observations
    public hasObservations(): boolean {
        if (this.subtestModel) {
            return this.subtestModel.subtestHasObservations();
        }
    }

    // Sets observation for the subtest
    public setObservationState(observation: Observations) {
        if (this.subtestModel) {
            this.subtestModel.setObservationState(observation);
        }
    }

    // Returns the display name of the subtest.
    public getSubtestDisplayName() {
        if (this.contentJson) {
            return this.contentJson.displayName;
        }
        return this.subtestDisplayName;
    }

    // Returns the display name of the test.
    public getTestDisplayName() {
        return this.testDisplayName;
    }

    public model(): Subtest {
        return this.subtestModel;
    }

    public belongsToTest(test: Test) {
        return this.testName === test.title;
    }

    public setContentJson(json) {
        this.contentJson = json;
    }

    public loadModel(battery: TestBattery): Subtest {
        if (!battery) {
            throw new Error('Battery wasn\'t passed to loadModel');
        }

        if (this.subtestModel) {
            return this.subtestModel;
        }

        if (!this.contentJson) {
            throw new Error(`Content JSON hasn't been specified: ${this.testName} ${this.subtestName}`);
        }
        this.contentJson.averageDuration = this.averageDuration;
        this.contentJson.subtestName = this.subtestName;
        this.subtestModel = new Subtest(this.contentJson, this.subtestInstanceID);
        this.subtestModel.battery = battery;
        this.subtestModel.test = battery.tests.find(t => this.belongsToTest(t));

        if (!this.subtestModel.test) {
            throw new Error(`Couldn't find test model: ${this.contentJson.testTitle}`);
        }

        if (this.stateJson) {
            this.subtestModel.restoreSavedData(this.stateJson);
        }
        delete this.contentJson;
        return this.subtestModel;
    }

    public unloadModel() {
        if (this.subtestModel) {
            this.stateJson = this.subtestModel.serializeForSave();
            this.subtestModel.unload();
            this.subtestModel = null;
        }
    }

    // Serializes the subtest.
    public serializeForSave() {
        let result = null;

        if (this.subtestModel) {
            this.stateJson = this.subtestModel.serializeForSave();
        }

        if (this.stateJson) {
            result = this.stateJson;
        } else {
            result = {
                testName: this.testName,
                averageDuration: this.averageDuration,
                testDisplayName: this.getTestDisplayName(),
                testTitle: this.getTestDisplayName(),
                subtestName: this.subtestName,
                subtestDisplayName: this.getSubtestDisplayName(),
                state: TestStates.notStarted,
                subtestInstanceID: this.subtestInstanceID,
                subtestGUID: this.subtestGUID,
                testGUID: this.testGUID,
                neverLoaded: true,
                normType: this.normType,
            };
        }
        return result;
    }
}
