import { AfterViewInit, Component, ElementRef, Input, OnChanges, NgZone, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { MessagingService, Events } from '@app/core';
import { MessageTypes } from '@app/shared';

import { Subscription, BehaviorSubject } from 'rxjs';

@Component({
    selector: 'video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnChanges, OnDestroy {

    public micEnabled = new BehaviorSubject<boolean>(false);
    public videoEnabled = new BehaviorSubject<boolean>(true);

    private subscriptions: Subscription = new Subscription();

    @Input() stream;
    @Input() isLocal;

    @ViewChild('video', { read: ElementRef }) video: ElementRef;

    constructor(
        private msgService: MessagingService,
        private events: Events,
        private zone: NgZone
    ) {
        this.subscriptions.add(this.events.subscribe(MessageTypes.MUTE_STREAM, (payload) => {
            const streamId = payload.id;
            const isEnabled = payload.value;

            if (this.stream && streamId === this.stream.id) {
                this.zone.run(() => this.micEnabled.next(isEnabled));
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.stream && this.video) {
            this.stream = changes.stream.currentValue;
            this.video.nativeElement.srcObject = this.stream;

            this.subscriptions.add(this.micEnabled.subscribe((val) => {
                if (this.stream && this.stream.getAudioTracks().length) {
                    this.stream.getAudioTracks()[0].enabled = val;
                }
            }));

            this.subscriptions.add(this.videoEnabled.subscribe((val) => {
                if (this.stream && this.stream.getVideoTracks().length) {
                    this.stream.getVideoTracks()[0].enabled = val;
                }
            }));
        }
    }

    ngAfterViewInit(): void {
        this.video.nativeElement.muted = this.isLocal;
        this.video.nativeElement.srcObject = this.stream;
    }

    toggleMicEnabled() {
        this.micEnabled.next(!this.micEnabled.value);
        this.msgService.sendMessage({ type: MessageTypes.MUTE_STREAM, payload: { id: this.stream.id, value: this.micEnabled.value } });
    }

    toggleVideoEnabled() {
        this.videoEnabled.next(!this.videoEnabled.value);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
