import { Component, Input, ChangeDetectorRef, NgZone, OnDestroy } from '@angular/core';

import { Events } from '@app/core';
import { RemoteSessionService } from '@app/core/providers/remote-session.service';
import { preloadSound, SoundEffectsService } from '@app/core/providers/sound-effects.service';
import { AssessEvents } from '@app/shared';

import { Subscription } from 'rxjs';

// preload Sound
preloadSound('ring', 'assets/soundfx/telephone-ring.mp3');

@Component({
    selector: 'remote-session',
    templateUrl: './remote-session.component.html',
    styleUrls: ['./remote-session.component.scss']
})
export class RemoteSessionComponent implements OnDestroy {
    public localStream: MediaStream;
    public remoteStream: MediaStream;
    public isCallIncoming = false;

    public subscriptions: Subscription = new Subscription();

    @Input() isPractitioner;

    constructor(
        private rms: RemoteSessionService,
        private zone: NgZone,
        private events: Events,
        private sounds: SoundEffectsService
    ) {
        this.subscriptions.add(this.events.subscribe(AssessEvents.localStreamStarted, () => {
            this.zone.run(() => this.localStream = this.rms.getLocalStream());
        }));
        this.subscriptions.add(this.events.subscribe(AssessEvents.remoteStreamStarted, () => {
            this.zone.run(() => this.remoteStream = this.rms.getRemoteStream());
        }));
        this.subscriptions.add(this.events.subscribe(AssessEvents.incomingCall, () => {
            this.zone.run(() => {
                this.isCallIncoming = true;
                this.sounds.play('ring');
            });
        }));
    }

    isConnected() {
        return this.rms.isConnected() ? 'Connected' : 'Disconnected';
    }

    callPeer() {
        this.rms.callPeer();
    }

    declineCall() {
        this.isCallIncoming = false;
        this.sounds.stop('ring');
        this.rms.declineCall();
    }

    answerCall() {
        this.isCallIncoming = false;
        this.sounds.stop('ring');
        this.rms.answerCall();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
