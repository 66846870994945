import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { ToastController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

const toastCSS = `
    .speechBlue {
        color: var(--ion-color-secondary);
        font-weight: bold;
    }
    .toast-header {
        padding-bottom: 5px;
        padding-top: 10px;
        background-color: var(--ion-color-new);
        border-radius: 4px 4px 0 0;
        height: 24px;
        font-weight: bold !important;
        color: white;
    }
    .toast-content {
        padding: 0 !important;
    }
    .toast-message {
        padding: 15px;
    }
    .toast-wrapper {
        width: 50% !important;
    }
`;

@Injectable()
export class ToastService {

    private renderer: Renderer2;

    constructor(private toastController: ToastController, private rendererFactory: RendererFactory2, private translate: TranslateService) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    /**
     * Displays a toast message.
     * @param message - the message content in string format (can be html with .speechBlue class included as needed)
     * @param header - the title of the toast
     * @param cssClass - additional classes for custom styles, separate by spaces
     * @param duration - how many milliseconds to wait until the toast is hidden
     * @param position - the position of the toast on the screen (top by default)
     */
    async showToastMessage(message, header?: string, cssClass?: string, duration: number = 5000,
        position?: 'top' | 'bottom' | 'middle'): Promise<any> {
        const options = {
            cssClass,
            message,
            position: position || 'top',
            duration,
            color: 'whitegrey'
        };
        if (header) {
            options['header'] = header;
        }

        const toast = await this.toastController.create(options);
        const shadowStyle = this.renderer.createElement('style');
        shadowStyle.innerHTML = toastCSS;
        toast.shadowRoot.appendChild(shadowStyle);

        await toast.present();
        return toast;
    }

    async showToast(msgKey, type: 'info' | 'danger' = 'info', persist: boolean = false) {
        await this.dismissTop();
        const message = await this.translate.get(msgKey).toPromise();
        const toast = await this.showToastMessage(message, null, `toast-${type}`, persist ? 0 : 5000);
    }

    /**
     * Dismisses the top toast.
     */
    async dismissTop() {
        const top = await this.toastController.getTop();
        if (top) {
            await top.dismiss();
        }
    }
}
