import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'battery-results',
    templateUrl: './battery-results.component.html',
    styleUrls: ['./battery-results.component.scss'],
})
export class BatteryResultsComponent implements OnInit {
    battery: any;
    resultView = 'subtests';
    constructor(private route: ActivatedRoute) {
        this.battery = this.route.snapshot.data.battery;
        this.battery.subtestRefs.forEach(st => {
            st.iconPath = 'assets/img/test-icons/default-thumbnail.png';
        });
    }

    ngOnInit() {
        // TODO: lots..
    }

    segmentChanged(evt: any) {
        this.resultView = evt.detail.value;
    }
}
