<ion-list class="ion-no-padding">
    <ion-list-header>
        <ion-label class="fw700">{{ 'shared.menu.selectLang' | translate }}</ion-label>
    </ion-list-header>

    <ion-item *ngFor="let lang of langs" (click)="selectLang(lang)">
        <ion-icon name="play" color="secondary"></ion-icon>
        <ion-label *ngIf="lang === 'en'">{{ lang }} (default)</ion-label>
        <ion-label *ngIf="lang !== 'en'">{{ lang }}</ion-label>
    </ion-item>
</ion-list>