import { Injectable } from '@angular/core';

import { IStatus } from '@app/interfaces/battery-status';
import { LoginConstants, LoginUserInfo } from '@app/models/auth/login.state';
import { AssessEvents } from '@app/shared';

import { Storage } from '@ionic/storage';

import { Events } from './events.service';
import { Logger, LoggingService } from './logging.service';

@Injectable()
export class UserStoreService {

    private static PATIENT_BATTERY_KEY = 'batteriesForPatient';

    logger: Logger;

    constructor(
        private loggingService: LoggingService,
        private storage: Storage,
        private events: Events
    ) {
        this.logger = this.loggingService.getLogger('UserStoreService');
    }

    /**
     * Stores the logged in user's info
     * @param userInfo - the logged in user's info
     */
    public async setLoggedInUserInfo(userInfo: LoginUserInfo): Promise<void> {
        this.logger.success(`setting logged in user ${userInfo.userName}`);
        await this.storage.set('loggedInUser', JSON.stringify(userInfo));
        await this.storage.set(LoginConstants.HAS_LOGGED_IN, true);
        this.events.publish(AssessEvents.loginSuccess, userInfo);
    }

    // Retrieves the details of the logged in user.
    public async getLoggedInUserDetails(): Promise<LoginUserInfo> {
        const userInfo = await this.storage.get('loggedInUser');
        return userInfo ? JSON.parse(userInfo) : {};
    }

    /**
     * Returns if the user loggedin in offline mode
     */
    public async isAuthenticatedOffline(): Promise<boolean> {
        const userInfo: LoginUserInfo = await this.getLoggedInUserDetails();
        return userInfo.isOffline;
    }

    // Retrieves the id of the currently logged in clinician.
    public async getLoggedInUserId(): Promise<string> {
        const loginInfo: LoginUserInfo = await this.getLoggedInUserDetails();
        return loginInfo.userId;
    }

    // Retrieves the name of the currently logged in clinician.
    public async getLoggedInUserName(): Promise<string> {
        const loginInfo: LoginUserInfo = await this.getLoggedInUserDetails();
        return loginInfo.userName;
    }

    // Retrieves the sign in name of the currently logged in clinician.
    public async getSignInUserName(): Promise<string> {
        const loginInfo: LoginUserInfo = await this.getLoggedInUserDetails();
        return loginInfo.signInId;
    }

    // Retrieves the list of eligible subtest GUIDS of the currently logged in clinician.
    public async getEligibleSubtestGUIDs(): Promise<string[]> {
        const loginInfo: LoginUserInfo = await this.getLoggedInUserDetails();
        return loginInfo.eligibleSubtestGUIDs;
    }

    // Updates eligible subtest GUIDs when license is updated
    public async setEligibleSubtestGUIDs(eligibleSubtestGUIDs: string[]): Promise<void> {
        const loginInfo: LoginUserInfo = await this.getLoggedInUserDetails();
        loginInfo.eligibleSubtestGUIDs = eligibleSubtestGUIDs;
        await this.storage.set('loggedInUser', JSON.stringify(loginInfo));
    }

    /**
     * Saves the battery status for the logged in user.
     * @param status - the battery status
     */
    public async saveBatteryStatus(status: IStatus): Promise<any> {
        const userId = await this.getLoggedInUserId();
        return await this.storage.set(`${userId}_status`, JSON.stringify(status));
    }

    /**
     * Updates and saves the patient to battery map incase there are multiple batteries for a patient
     * @param batteryId
     * @param patientId
     */
    public async addBatteryIdToPatient(batteryId: string, patientId: string): Promise<void> {
        const data: any = JSON.parse((await this.storage.get(UserStoreService.PATIENT_BATTERY_KEY)) || '{}');
        if (!data[patientId]) {
            data[patientId] = [];
        }
        if (data[patientId].indexOf(batteryId) === -1) {
            data[patientId].push(batteryId);
        }
        await this.storage.set(UserStoreService.PATIENT_BATTERY_KEY, JSON.stringify(data));
    }

    /**
     * Removes the provided battery from the patient's battery store
     * @param batteryId - the id of the battery to remove
     * @param patientId - the patient guid
     */
    public async removeBatteryIdFromPatient(batteryId: string, patientId: string): Promise<void> {
        const data: any = JSON.parse((await this.storage.get(UserStoreService.PATIENT_BATTERY_KEY)) || '{}');
        const batteryIndex = data[patientId].indexOf(batteryId);
        if (batteryIndex !== -1) {
            data[patientId].splice(batteryIndex, 1);
        }
        await this.storage.set(UserStoreService.PATIENT_BATTERY_KEY, JSON.stringify(data));
    }

    /**
     * Returns batteryIds from the repo that belong to a patient
     * @param patientId
     */
    public async getBatteryIdsForPatient(patientId: string): Promise<string[]> {
        const data: any = JSON.parse((await this.storage.get(UserStoreService.PATIENT_BATTERY_KEY)) || '{}');
        return data[patientId] || [];
    }

    /**
     * Add battery to pending sync array
     * @param batteryId - the battery id
     */
    public async addBatteryToPendingSyncs(batteryId: string): Promise<void> {
        return await this.addBatteryToPending(batteryId, '_pending_sync');
    }

    /**
     * Add battery to pending image sync array
     * @param batteryId - the battery id
     */
    public async addBatteryToPendingImageSync(batteryId: string): Promise<void> {
        return await this.addBatteryToPending(batteryId, '_pending_image_sync');
    }

    /**
     * Remove battery id from pending sync array
     */
    public async removeBatteryFromPendingSyncs(batteryId: string): Promise<void> {
        await this.removeBatteryFromPending(batteryId, '_pending_sync');
    }

    /**
     * Remove battery id from pending image sync array
     */
    public async removeBatteryFromPendingImageSync(batteryId: string): Promise<void> {
        await this.removeBatteryFromPending(batteryId, '_pending_image_sync');
    }

    /**
     * Returns battery ids pending sync
     * @return an array of batteryids ready to be synced
     */
    public async getSyncPendingBatteryIds(): Promise<string[]> {
        return await this.getPendingBatteryIds('_pending_sync');
    }

    /**
     * Returns battery ids pending image sync
     * @return an array of batteryids with images ready to be synced
     */
    public async getImageSyncPendingBatteryIds(): Promise<string[]> {
        return await this.getPendingBatteryIds('_pending_image_sync');
    }

    // Retrieves the battery status for the logged in user.
    public async getBatteryStatus(): Promise<IStatus> {
        const userId = await this.getLoggedInUserId();
        try {
            const userStatus = await this.storage.get(`${userId}_status`);
            return JSON.parse(userStatus);
        } catch (e) {
            this.logger.debug('No status saved for user.');
        }
        return null;
    }

    // Gets the saved battery ids for the logged in user.
    public async getSavedBatteryIds(): Promise<string[]> {
        let savedBatteryIds = [];
        try {
            const userId = await this.getLoggedInUserId();
            const json = await this.storage.get(`${userId}_savedBatteries`);
            if (json) {
                savedBatteryIds = JSON.parse(json);
            }
        } catch (e) {
            this.logger.debug('No saved batteries for user.');
        }
        return savedBatteryIds;
    }

    public async saveNoteForBattery(batteryId: string, noteId: string, noteImg: string) {
        let notes = {};
        const json = await this.storage.get(`${batteryId}_notes`);
        if (json) {
            notes = JSON.parse(json);
        }
        notes[noteId] = noteImg;
        await this.storage.set(`${batteryId}_notes`, JSON.stringify(notes));
        this.events.publish(AssessEvents.savedNote, noteId);
    }

    public async getNoteForBattery(batteryId: string, noteId: string): Promise<string> {
        const json = await this.storage.get(`${batteryId}_notes`);
        if (json) {
            return JSON.parse(json)[noteId];
        }
    }

    public async deleteNotesForBattery(batteryId: string) {
        await this.storage.remove(`${batteryId}_notes`);
    }

    /**
     * Saves the provided battery to storage.
     * @param batteryId - the battery to save
     * @param json - the battery json
     */
    public async saveBattery(batteryId: string, json: string): Promise<boolean> {
        try {
            const userId = await this.getLoggedInUserId();
            const savedBatteryIds = await this.getSavedBatteryIds();
            if (!savedBatteryIds.includes(batteryId)) {
                savedBatteryIds.push(batteryId);
                await this.storage.set(`${userId}_savedBatteries`, JSON.stringify(savedBatteryIds));
            }
            // save that battery
            return await this.storage.set(batteryId, json);
        } catch (e) {
            this.logger.error('Unable to save battery for user.', e);
        }
    }

    /**
     * Gets the saved battery with the given id.
     * @param batteryId - the id of the battery to get
     */
    public async getSavedBattery(batteryId: string): Promise<string> {
        return await this.storage.get(batteryId);
    }

    /**
     * Removes the provided battery from storage.
     * @param batteryId - the battery to remove
     * @param json - the battery json
     */
    public async removeBattery(batteryId: string): Promise<void> {
        try {
            const userId = await this.getLoggedInUserId();
            const savedBatteryIds = await this.getSavedBatteryIds();
            if (savedBatteryIds.includes(batteryId)) {
                const updatedBatteryIds = savedBatteryIds.filter(e => e !== batteryId);
                this.storage.set(`${userId}_savedBatteries`, JSON.stringify(updatedBatteryIds));
                this.storage.remove(batteryId);
            }
            this.deleteNotesForBattery(batteryId);
        } catch (e) {
            this.logger.error('Unable to remove battery for user.', e);
        }
    }

    private async addBatteryToPending(batteryId: string, type: string): Promise<void> {
        const userId = await this.getLoggedInUserId();
        const pending: any = JSON.parse((await this.storage.get(`${userId}${type}`)) || '{}');
        pending[batteryId] = true;
        return await this.storage.set(`${userId}${type}`, JSON.stringify(pending));
    }

    private async removeBatteryFromPending(batteryId: string, type: string): Promise<void> {
        const userId = await this.getLoggedInUserId();
        const pending: any = JSON.parse((await this.storage.get(`${userId}${type}`)) || '{}');
        delete pending[batteryId];
        await this.storage.set(`${userId}${type}`, JSON.stringify(pending));
    }

    private async getPendingBatteryIds(type): Promise<string[]> {
        const userId = await this.getLoggedInUserId();
        const pending: any = JSON.parse((await this.storage.get(`${userId}${type}`)) || '{}');
        return Object.keys(pending);
    }
}
