import { ItemTypes, QuestionTypes } from '@app/models/consts';
import { Administrable } from '@app/models/subtest/administrable';
import { ItemGroup } from '@app/models/subtest/item-group';
import { Question } from '@app/models/subtest/question';
import { Subtest } from '@app/models/subtest/subtest';
import { IBaseRule, INavResult, INavItem, SubtestItem } from '@app/rules';
import { IDiscontinueItem, IDiscontinueRule } from '@app/rules/discontinue';
import { DiscontinueComponent } from '@app/rules/overlays/discontinue/discontinue.component';
import { ContentQuery } from '@app/shared';

/**
* Consecutive Zeros Discontinue Rule
*/
export class ConsecutiveZerosRule implements IBaseRule, IDiscontinueRule {
    public ruleType = 'consecutiveZerosDiscontinue';

    description: string;
    numZeros: number;
    numQuestions: number;
    countsDemoItems: boolean;

    dcItem: Administrable | Question;
    dcItems: Array<IDiscontinueItem> = [];
    isTestingLimits: boolean;

    container: ItemGroup | Subtest;
    subtest: Subtest;
    items: Array<any>;

    constructor(json, container: ItemGroup | Subtest) {
        this.description = json.description;
        this.numZeros = json.value;
        this.numQuestions = json.value;
        this.countsDemoItems = json.countsDemoItems;

        this.container = container;
    }

    async navigate(): Promise<INavResult> {
        if (!this.subtest) {
            this.subtest = ContentQuery.ancestorOfType(this.container, ItemTypes.subtest);
        }

        if (!this.items) {
            this.items = ContentQuery.flatSubtest(this.subtest);
        }

        if (this.isTestingLimits) {
            return null;
        }

        this.dcItems = this.getItemsTriggeringDiscontinue();
        if (this.dcItems.length) {
            return {
                shouldShowOverlay: true,
                overlayComponent: DiscontinueComponent,
                cssClass: DiscontinueComponent.getCSSClass()
            };
        }
        return null;
    }

    handleSwipeBack(currentItem: SubtestItem, prevItem: SubtestItem) {
        if (prevItem === this.dcItem) {
            if (this.dcItem instanceof Question) {
                this.dcItem.trials[this.dcItem.trials.length - 1].isDiscontinuePoint.next(false);
            } else {
                this.dcItem.isDiscontinuePoint.next(false);
            }
            this.dcItem = null;
            this.isTestingLimits = false;
            // TODO: unset test limits things on the subtest
        }
    }

    setDiscontinuePoint(isTestingLimits: boolean) {
        if (this.dcItem instanceof Question) {
            this.dcItem.trials[this.dcItem.trials.length - 1].isDiscontinuePoint.next(true);
        } else {
            this.dcItem.isDiscontinuePoint.next(true);
        }
        this.isTestingLimits = !!isTestingLimits;
        // TODO: set test limits things on the subtest
    }

    getDestination(cancel?: boolean): INavItem {
        if (this.isTestingLimits) {
            return { destId: null };
        }
        if (cancel) {
            this.dcItem = null;
            return {
                destId: null,
                cancel: true
            };
        }

        const dcIdx = this.items.indexOf(this.dcItem);
        const remainingItems = this.items.slice(dcIdx, this.items.length);
        const length = remainingItems.length;

        let destItemId;
        for (let i = 0; i < length; i++) {
            const it = remainingItems[i];
            if (it.itemType === ItemTypes.subtestSummary || it.itemType === ItemTypes.itemGroup) {
                destItemId = it.id;
                break;
            }
        }
        return { destId: destItemId };
    }

    priority(): number {
        return 0; // highest priority
    }

    private trialsWereAdministered(container: Question): boolean {
        return container.trials.every((t) => t.wasAdministered.value || t.wasSkipped.value);
    }

    private getItemWasAdministered(item: Question | Administrable): boolean {
        // trial containers don't have wasAdministered, so check its children instead
        if (item instanceof Question) {
            return item.trials && this.trialsWereAdministered(item);
        }
        return item.wasAdministered && item.wasAdministered.value;
    }

    private isEligibleItem(item: Administrable): boolean {
        return (
            !item.getWasSkippedForStartingPoint() &&
            (item.questionType === QuestionTypes.score || (this.countsDemoItems && item.questionType === QuestionTypes.demo))
        );
    }

    private getEligibleItems(): Array<Administrable> {
        return ContentQuery.find(this.container, (it) => {
            return (it.itemType === ItemTypes.administrable && this.isEligibleItem(it) && this.getItemWasAdministered(it));
        });
    }

    private isLastItemInContainer(item: Administrable): boolean {
        const administrables = ContentQuery.find(this.container, (q) => q.itemType === ItemTypes.administrable);
        return item === administrables[administrables.length - 1];
    }

    private itemShouldCountTowardDiscontinue(score: number): boolean {
        return score === 0;
    }

    private getItemsTriggeringDiscontinue(): Array<IDiscontinueItem> {
        const items = this.getEligibleItems();
        const itemLength = items.length;

        if (itemLength < this.numZeros) {
            // if there are fewer items to evaluate than failures required, just return
            return [];
        }

        let evalLength = this.numQuestions;
        if (itemLength < this.numQuestions) {
            // there are fewer items in the list than the max evaluation number, so check for num failed instead
            evalLength = this.numZeros;
        }

        for (let i = 0; i <= itemLength - evalLength; i++) {
            let numFailedFound = 0;
            const itemsInDiscontinue = [];

            for (let j = 0; j < evalLength; j++) {
                const item = items[i + j];
                const score = item.rawScore.value;

                // save item information
                itemsInDiscontinue.push({
                    title: item.title,
                    itemType: item.itemType,
                    score
                });

                const isLastItem = this.isLastItemInContainer(item);
                if (this.itemShouldCountTowardDiscontinue(score) && !isLastItem && ++numFailedFound >= this.numZeros) {
                    this.dcItem = item;
                    return itemsInDiscontinue;
                }
            }
        }
        return [];
    }
}

