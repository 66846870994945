import { BehaviorSubject } from 'rxjs';

export enum UploadMode {
    BACKGROUND, MANUAL
}

export enum UploadType {
    BATTERY, IMAGE, CONSOLE_LOG
}

export interface SyncData {
    destURL: string;
    isRemove: boolean;
    cancelSubject: BehaviorSubject<boolean>;
}

export interface BatterySyncData extends SyncData {
    batteryId: string;
}

export interface ImageSyncData extends SyncData {
    batteryId: string;
    nativePath: string;
    fullPath: string;
    subtestInstanceId: string;
    imageName: string;
}

export interface ConsoleLogSyncData extends SyncData {
    fileName: string;
}

export interface SyncUploadTask {
    type: UploadType;
    mode: UploadMode;
    data: SyncData;
}

export enum SyncWorkerMessageType {
    CLEAR_PENDING,
    DELETE_LOG,
    LOG_ERROR,
    LOG_INFO,
    LOG_SUCCESS
}

export interface SyncWorkerMessage {
    type: SyncWorkerMessageType;
    payload: any;
}
