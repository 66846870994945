import { Injectable } from '@angular/core';

import { FileUtilService } from './file-util.service';
import { Logger, LoggingService } from './logging.service';
import { TestStateService } from './test-state.service';

const IMAGE_SUFFIX = '.png';
const CONTENT_TYPE = 'image/png';

export interface ImageData {
    batteryId: string;
    subtestInstanceId: string;
    imageName: string;
    fullPath: string;
    nativePath: string;
}

/**
 * Service for storing images.
 */
@Injectable()
export class ImageService {

    logger: Logger;

    constructor(
        private testStateService: TestStateService,
        private fileUtil: FileUtilService,
        private loggingService: LoggingService
    ) {
        this.logger = this.loggingService.getLogger('ImageService');
     }

    /**
     * Saves the canvas as a PNG on the file system.
     * @param canvas - the canvas to be saved
     * @param name - the name of the file to save the image to
     */
    public async saveCanvasAsPNG(canvas: HTMLCanvasElement, name: string): Promise<void> {
        const assessmentId = await this.testStateService.getCurrentBatteryId();
        const subtestId = await this.testStateService.getCurrentSubtestId();

        const imageDir = await this.fileUtil.getOrCreateSubtestImageDir(assessmentId, subtestId);
        const blob = await this.getCanvasBlob(canvas);

        this.fileUtil.writeFile(imageDir, `${name}${IMAGE_SUFFIX}`, blob);
    }

    /**
     * Returns true if there are images for this assessment, false otherwise.
     * @param assessmentId - the assessment id
     */
    public async hasImages(assessmentId: string): Promise<boolean> {
        try {
            await this.fileUtil.getAssessmentImageDir(assessmentId);
            this.logger.debug(`Found images for assessment ${assessmentId}.`);
            return true;
        } catch (e) {
            this.logger.debug(`No images for assessment ${assessmentId}.`);
            return false;
        }
    }

    /**
     * Returns data for each image stored for an assessment.
     * @param assessmentId - the id of the assessment
     */
    public async getImageDataForAssessment(assessmentId: string): Promise<ImageData[]> {
        let imageData: ImageData[] = [];
        if (await this.hasImages(assessmentId)) {
            const subtestEntries = await this.fileUtil.getSubtestImageDirsForBattery(assessmentId);
            this.logger.debug(`${subtestEntries.length} subtests with images for assessment ${assessmentId}.`);
            for (let i = 0; i < subtestEntries.length; i++) {
                const subtestId = subtestEntries[i].name;
                const imageEntries = await this.fileUtil.getImageEntriesForSubtest(assessmentId, subtestId);
                this.logger.debug(`${imageEntries.length} images for subtest ${subtestId}.`);
                imageData = imageData.concat(imageEntries.map((imageEntry) => {
                    const image: ImageData = {
                        batteryId: assessmentId,
                        subtestInstanceId: subtestId,
                        imageName: imageEntry.name,
                        nativePath: imageEntry.nativeURL,
                        fullPath: imageEntry.fullPath.split('/').slice(2).join('/')
                    };
                    return image;
                }));
            }
        }
       return imageData;
    }

    /**
     * Removes an image from the filesystem, as well as parent folders, if empty.
     * @param batteryId - the assessment id
     * @param subtestInstanceId - the subtest instance id
     * @param fileName - the file name of the image to delete
     */
    public async deleteImageForAssessment(batteryId: string, subtestInstanceId: string, fileName: string) {
        const assessmentDir = await this.fileUtil.getAssessmentImageDir(batteryId);
        const subtestDir = await this.fileUtil.getChildDirectory(assessmentDir, subtestInstanceId);

        // delete the image file
        this.fileUtil.deleteFile(subtestDir, fileName).then(async (success) => {
            // if there are no more images for the subtest, delete the subtest dir
            const imageEntries = await this.fileUtil.getImageEntriesForSubtest(batteryId, subtestInstanceId);
            if (!imageEntries.length) {
                this.logger.debug(`Deleting image dir for subtest ${subtestInstanceId}.`);
                await this.fileUtil.deleteDir(assessmentDir, subtestInstanceId);

                // if there are no more subtest images for the assessment, delete the assessment dir
                const subtestEntries = await this.fileUtil.getSubtestImageDirsForBattery(batteryId);
                if (!subtestEntries.length) {
                    this.logger.debug(`Deleting image dir for assessment ${batteryId}.`);
                    await this.fileUtil.deleteDir(await this.fileUtil.getImageRoot(), batteryId);
                }
            }
        });
    }

    /**
     * Removes all image from the filesystem, as well as parent folders.
     * @param batteryId - the assessment id
     */
    public async deleteAllImagesForAssessment(batteryId: string) {
        await this.fileUtil.deleteDir(await this.fileUtil.getImageRoot(), batteryId);
    }

    /**
     * Returns an image as a Blob
     * @param fullPath - the path of the image
     */
    public getImageBlob(fullPath: string): Promise<Blob> {
        this.logger.debug(`Getting image blob ${fullPath}.`);
        return this.fileUtil.getFileAsBlob(fullPath, CONTENT_TYPE);
    }

    private getCanvasBlob(canvas: HTMLCanvasElement): Promise<Blob> {
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => resolve(blob), CONTENT_TYPE);
        });
    }
}
