import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BatteryContentResolver } from './core/resolvers/battery-content.resolver.js';
import buildjson from '../environments/.build.json';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'assess/:id',
        loadChildren: () => import('./assess/assess.module').then(m => m.AssessModule),
        resolve: { battery: BatteryContentResolver }
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'stim',
        loadChildren: () => import('./stim/stim.module').then(m => m.StimModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    providers: [
        {
            provide: LocationStrategy,
            useFactory: (platformLocation: PlatformLocation) => {
                return buildjson.ghpages ? new HashLocationStrategy(platformLocation) :
                    new PathLocationStrategy(platformLocation);
            },
            deps: [PlatformLocation]
        },
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
