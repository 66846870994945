import { Injectable } from '@angular/core';

import { environment } from '@appenv';

import { Platform } from '@ionic/angular';
import { Entry, DirectoryEntry, FileEntry, File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';

const STIM_DIR = 'content/';
const IMAGE_DIR = 'images/';

@Injectable()
export class FileUtilService {
    private rootDir: DirectoryEntry;
    private imageDir: DirectoryEntry;

    constructor(private file: File, private platform: Platform, private webView: WebView) { }

    /**
     * Gets the root path for the file system
     */
    public async getRootPath(): Promise<DirectoryEntry> {
        if (this.rootDir != null) {
            return Promise.resolve(this.rootDir);
        }
        if (!this.isNative()) {
            return new Promise<DirectoryEntry>((res, rej) => {

                // TODO: update this to use navigator.webkitPersistentStorage in place of window
                // chromestore has a nice little api that makes this stuff trivial.
                window['webkitStorageInfo'].requestQuota(
                    window['PERSISTENT'],
                    1024 * 1024 * 1024 * 2, // 2 GB ? :-)
                    granted => {
                        window['webkitRequestFileSystem'](
                            window['PERSISTENT'],
                            granted,
                            fs => {
                                fs.root.getDirectory(
                                    'assessRoot',
                                    { create: true },
                                    (dir: DirectoryEntry) => {
                                        this.rootDir = dir;
                                        res(dir);
                                    },
                                    (e: any) => rej(e)
                                );
                            },
                            e => {
                                rej(e);
                            }
                        );
                    },
                    e => rej(e)
                );
            });
        } else {
            return await this.file.resolveDirectoryUrl(this.file.dataDirectory);
        }
    }

    /**
     * Returns files under directory from a given path.
     * @param dirName
     */
    public async getStimsFromStorage(dirName: string): Promise<any> {
        const root: DirectoryEntry = await this.getRootPath();
        const stimDir: DirectoryEntry = await this.getChildDirectory(root, STIM_DIR);
        const itemDir: DirectoryEntry = await this.getChildDirectory(stimDir, dirName);

        if (!this.isNative()) {
            return await this.getEntriesAsPromise(itemDir);
        }
        return await this.file.listDir(itemDir.toURL(), '');
    }

    /**
     * Returns the root image directory.
     */
    public async getImageRoot(doCreate: boolean = false): Promise<DirectoryEntry> {
        if (this.imageDir != null) {
            return Promise.resolve(this.imageDir);
        }
        return await this.getChildDirectory(await this.getRootPath(), IMAGE_DIR, doCreate);
    }

    /**
     * Returns a DirectoryEntry containing the file path to the image store for a given assessment.
     * @param assessmentId - the id of the assessment
     */
    public async getAssessmentImageDir(assessmentId: string): Promise<DirectoryEntry> {
        const imageDir = await this.getImageRoot(true);
        return await this.getChildDirectory(imageDir, assessmentId);
    }

    /**
     * Returns image directory for the given subtest and assessment or creates it if it doesn't exist.
     * @param assessmentId - the id of the assessment
     * @param subtestInstanceId - the instance id of the subtest
     */
    public async getOrCreateSubtestImageDir(assessmentId: string, subtestInstanceId: string) {
        const imageDir = await this.getImageRoot(true);
        const assessmentDir: DirectoryEntry = await this.createAndGetDirectory(imageDir, assessmentId);
        return await this.createAndGetDirectory(assessmentDir, subtestInstanceId);
    }

    /**
     * Returns the contents of the assessment's image directory.
     * @param assessmentId - the assessment id
     */
    public async getSubtestImageDirsForBattery(assessmentId: string): Promise<Entry[]> {
        const assessmentDir = await this.getAssessmentImageDir(assessmentId);
        if (!this.isNative()) {
            return await this.getEntriesAsPromise(assessmentDir);
        }
        return await this.file.listDir(assessmentDir.toURL(), '');
    }

    /**
     * Returns the contents of a subtest's image directory.
     * @param assessmentId - the assessment id
     * @param subtestId - the subtest instance id
     */
    public async getImageEntriesForSubtest(assessmentId: string, subtestId: string): Promise<Entry[]> {
        const assessmentDir = await this.getAssessmentImageDir(assessmentId);
        const subtestDir = await this.getChildDirectory(assessmentDir, subtestId);
        if (!this.isNative()) {
            return await this.getEntriesAsPromise(subtestDir);
        }
        return await this.file.listDir(subtestDir.toURL(), '');
    }

    /**
    * Returns a DirectoryEntry containing the file path to content (ie; stim images)
    */
    public async getContentRoot(): Promise<DirectoryEntry> {
        return await this.getChildDirectory(await this.getRootPath(), STIM_DIR);
    }

    /**
    * Returns a DirectoryEntry containing the path to the child content directory
    */
    public async getContentChildDirectory(childDir: string): Promise<DirectoryEntry> {
        const contentDir = await this.getContentRoot();
        return await this.getChildDirectory(contentDir, childDir);
    }

    /**
     * Returns entries inside a directory
     * @param dirEntry:DirectoryEntry
     */
    public async getEntriesAsPromise(stimDir: DirectoryEntry): Promise<Entry[]> {
        return await new Promise<Entry[]>((resolve, reject) => {
            const result: Entry[] = [];
            const reader = stimDir.createReader();
            const doBatch = () => {
                reader.readEntries(entries => {
                    if (entries.length > 0) {
                        entries.forEach(e => result.push(e));
                        doBatch();
                    } else {
                        resolve(result);
                    }
                }, reject);
            };
            doBatch();
        });
    }

    /**
     * Deletes a file in the root path.
     * @param fileName - the file, relative to the root path
     */
    public async deleteFile(parentDir: DirectoryEntry, fileName: string): Promise<boolean> {
        if (!this.isNative()) {
            return new Promise((resolve, reject) => {
                parentDir.getFile(fileName, { create: false }, (fileEntry) => {
                        fileEntry.remove(() => resolve(true), () => resolve(false));
                    },
                    () => resolve(false)
                );
            });
        }
        return (await this.file.removeFile(parentDir.toURL(), fileName)).success;
    }

    /**
     * Deletes a directory in the root path and all of its contents.
     * @param dirName - the directory name, relative to the root path
     */
    public async deleteDir(parentDir: DirectoryEntry, dirName: string): Promise<boolean> {
        if (!this.isNative()) {
            return new Promise((resolve, reject) => {
                parentDir.getDirectory(dirName, { create: false }, (dirEntry) => {
                    dirEntry.removeRecursively(() => resolve(true), () => resolve(false));
                    },
                    () => resolve(false)
                );
            });
        }
        return (await this.file.removeRecursively(parentDir.toURL(), dirName)).success;
    }

    /**
     * Returns a file under the parent dir
     * @param parentDir
     * @param filename
     */
    public async getNewFile(parentDir: DirectoryEntry, fileName: string): Promise<FileEntry> {
        let file: FileEntry;
        if (!this.isNative()) {
            // deletes file if already present/ To conserve disk space
            await new Promise((res, rej) => {
                parentDir.getFile(fileName,
                    { create: false }, fileEntry => {
                        fileEntry.remove(() => res(true), () => res(false));
                    }, e => res(false));
            });
            file = await new Promise<FileEntry>((res, rej) => {
                parentDir.getFile(fileName, { create: true, exclusive: false }, fileEntry => {
                    res(fileEntry);
                }, e => {
                    rej(e);
                });
            });
        } else {
            file = await this.file.getFile(parentDir, fileName, { create: true });
        }
        return file;
    }

    /**
   * Downloads a file with name to a folder
   * @param url
   * @param filename
   * @param targetDir
   */
    public downloadUrlToDir(url: string, filename: string, targetDir: DirectoryEntry, progressCb): Promise<FileEntry> {
        return new Promise((res, rej) => {
            const transfer = new FileTransfer();
            const uri = encodeURI(url);
            transfer.onprogress = (progressEvent: ProgressEvent) => {
                progressCb(progressEvent);
            };
            transfer.download(
                uri,
                `${targetDir.toInternalURL()}${filename}`,
                (entry: any) => {
                    console.log(`Downloaded ${url} to ${entry.toInternalURL()}`);
                    res(entry);
                },
                e => rej(e),
                true,
                {
                    timeout: environment.slowHttpTimeout
                }
            );
        });
    }

    /**
     * Returns a file under the parent dir
     * @param parentDir
     * @param filename
     */
    public async getFile(parentDir: DirectoryEntry, fileName: string): Promise<FileEntry> {
        let file: FileEntry;
        if (!this.isNative()) {
            file = await new Promise<FileEntry>((res, rej) => {
                parentDir.getFile(fileName, { create: false }, fileEntry => {
                    res(fileEntry);
                }, e => {
                    rej(e);
                });
            });
        } else {
            file = await this.file.getFile(parentDir, fileName, { create: false });
        }
        return file;
    }


    public async getFileFromPath(absolutePath: string): Promise<FileEntry> {
        return await this.file.resolveLocalFilesystemUrl(absolutePath) as FileEntry;
    }

    /**
     * Gets a directory within the parent folder
     * @param parentDir
     * @param dirName
     */
    public async getChildDirectory(parentDir: DirectoryEntry, dirName: string, isCreate: boolean = false): Promise<DirectoryEntry> {
        if (!this.isNative()) {
            const childDir = await new Promise<DirectoryEntry>((res, rej) => {
                parentDir.getDirectory(
                    dirName,
                    { create: isCreate },
                    dir => res(dir),
                    e => rej(e)
                );
            });
            console.log(`FileUtilService >> Getting web child dir ${childDir.toURL()}`);
            return childDir;
        }
        const childDir1 = await this.file.getDirectory(parentDir, dirName, {
            create: isCreate
        });
        console.log(`FileUtilService >> Getting native child dir ${childDir1.fullPath}`);
        return childDir1;
    }

    /**
     * Creates and gets a directory within the parent folder
     * @param parentDir
     * @param dirName
     */
    public async createAndGetDirectory(parentDir: DirectoryEntry, dirName: string): Promise<DirectoryEntry> {
        if (!this.isNative()) {
            const childDir = await new Promise<DirectoryEntry>((res, rej) => {
                parentDir.getDirectory(
                    dirName,
                    { create: true, exclusive: false },
                    dir => res(dir),
                    e => rej(e)
                );
            });
            return childDir;
        } else {
            const childDir = await this.file.getDirectory(parentDir, dirName, {
                create: true,
                exclusive: false
            });
            return childDir;
        }
    }

    /**
     * Writes a blob to a file in the directory
     * @param dir
     * @param fileName
     * @param data
     */
    public async writeFile(dir: DirectoryEntry, fileName: string, data: Blob): Promise<FileEntry> {
        if (this.isNative()) {
            return this.file
                .writeFile(dir.toURL(), fileName, data, {
                    replace: true
                })
                .catch(e => {
                    console.error(
                        `FileUtilService >> Error in writing file ${fileName} to ${dir.toURL()} with ${JSON.stringify(
                            e
                        )}`
                    );
                    throw e;
                });
        }

        return new Promise<FileEntry>((res, rej) => {
            dir.getFile(
                fileName,
                { create: true },
                fileEntry => {
                    fileEntry.createWriter(writer => {
                        // cleaning up
                        writer.onwriteend = () => {
                            fileEntry.createWriter(writer1 => {
                                writer1.write(data);
                                res(fileEntry);
                            });
                        };
                        writer.truncate(0);
                    });
                },
                e => {
                    rej(e);
                }
            );
        });
    }

    /**
     * Converts a fileentry to a blob
     * @param source
     * @param type - the content type
     */
    public async toBlob(source: FileEntry, type: string): Promise<Blob> {
        return new Promise<Blob>((res, rej) => {
            source.file(file => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const blob = new Blob([reader.result], { type });
                    res(blob);
                };
                reader.onerror = (err) => rej(err);
                reader.readAsArrayBuffer(file);
            }, err => rej(err));
        });
    }

    public async getFileAsBlob(filename: string, type: string): Promise<Blob> {
        const file: FileEntry = await this.getFile(await this.getRootPath(), filename);
        return this.toBlob(file, type);
    }

    /**
     * Writes a blob to the file
     * @param file
     * @param blob
     */
    public async writeBlob(parentDir: DirectoryEntry, file: FileEntry, blob: Blob | ArrayBuffer): Promise<void> {
        if (this.isNative()) {
            await this.file.writeFile(parentDir.toURL(), file.name, blob, { replace: true });
            return;
        }
        let _blob = blob;
        if (blob instanceof ArrayBuffer) {
            _blob = new Blob([blob]);
        }
        return await new Promise<void>((res, rej) => {
            file.createWriter(writer => {
                writer.seek(0);
                writer.write(_blob);
                res();
            }, err => rej(err));
        });
    }

    public async readAsText(parentDir: DirectoryEntry, filename: string): Promise<string> {
        if (!this.isNative()) {
            return new Promise<string>((res, rej) => {
                parentDir.getFile(filename, { create: false }, fileEntry => {
                    fileEntry.file(file => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            res(reader.result as string);
                        };
                        reader.readAsText(file, 'UTF-8');
                    });
                }, e => rej(e));
            });
        }
        return this.file.readAsText(parentDir.toURL(), filename);
    }

    /**
     * Make dirs recursively under a parent dir
     * @param parentDir
     * @param path
     */
    public async mkDirs(parentDir: DirectoryEntry, path: string): Promise<DirectoryEntry> {
        const paths = path.split('/').reverse();
        const p = new Promise<DirectoryEntry>((res, rej) => {
            let prevDir = parentDir;
            const createDir = (dir: string) => {
                prevDir.getDirectory(
                    dir,
                    { create: true, exclusive: false },
                    newDir => {
                        prevDir = newDir;
                        if (paths.length > 0) {
                            createDir(paths.pop() as string);
                        } else {
                            res(newDir);
                        }
                    },
                    e => {
                        rej(e);
                    }
                );
            };

            createDir(paths.pop() as string);
        });
        return p;
    }

    /**
     * Gets stim root as an abs path string
     */
    public async getStimRoot(): Promise<string> {
        const stimRootPath = await this.getContentRoot();
        return this.isNative() ? this.webView.convertFileSrc(stimRootPath.toURL()) : stimRootPath.toURL() + '/';
    }

    private isNative() {
        return (!this.platform.is('desktop') && !this.platform.is('mobileweb'));
    }
}
