import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';

import { PlatformService } from '@app/core/providers';

import { concat, fromEvent, interval, merge, Observable } from 'rxjs';
import { first, repeat, takeUntil, takeWhile } from 'rxjs/operators';

import { Adjacency, Point, Position } from '.';
import { Pen } from './pen';

@Component({
    selector: 'writing-surface',
    templateUrl: './writing-surface.component.html',
    styleUrls: ['./writing-surface.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WritingSurfaceComponent implements AfterViewInit, OnDestroy {

    @ViewChild('canvas', { read: ElementRef, static: true }) canvasRef: ElementRef;

    @Input() width = 500;
    @Input() height = 500;
    @Input() ignoreOffset = false;

    @Output() strokeStarted = new EventEmitter<Point>();
    @Output() strokeEnded = new EventEmitter();
    @Output() canvasDown = new EventEmitter<Point>();
    @Output() canvasUp = new EventEmitter();
    @Output() writingUpdated = new EventEmitter<Array<Point>>();

    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    pen: Pen;
    stroke: Array<Point>;
    drawing: Array<Array<Point>>;
    bounds: any;

    isWriting = false;
    isLocked = false;
    intervalUpdatesEnabled = false;
    lastUpdatePoint: number;
    upperBounds = 0;

    // defaults for pen
    lineWidth = 1.2;
    lineColor = 'rgba(0,0,0,1)';

    constructor(private platform: PlatformService, private renderer: Renderer2, private el: ElementRef, private zone: NgZone) {
        this.pen = new Pen(this.lineWidth, this.lineColor);
        this.stroke = [];
        this.drawing = [];
    }

    ngAfterViewInit() {
        this.initCanvas();
        this.zone.runOutsideAngular(() => this.initEvents());
    }

    ngOnDestroy() {
        this.canvas.width = 0;
        this.canvas.height = 0;
        this.el.nativeElement.remove();
    }

    /**
     * Determines if this component has writing.
     */
    hasWriting() {
        return this.drawing.length > 0;
    }

    /**
     * Saves the drawing.
     */
    capture() {
        return this.drawing.slice(0);
    }

    /**
     * Clears the canvas.
     */
    clear() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.drawing = [];
        this.stroke = [];
    }

    /**
     * Restores a drawing on the canvas.
     * @param drawing - the drawing to restore
     * @param moveToOrigin - true to move to origin
     * @param scale - the scale to draw
     */
    restoreWritingFromPoints(drawing: Array<Array<Point>>, moveToOrigin?: boolean, scale?: number) {
        this.drawing = drawing;

        const updatePoint = (point: Point): Point => {
            let pt = point;
            if (moveToOrigin) {
                pt = {
                    x: pt.x - this.bounds.xMin,
                    y: pt.y - this.bounds.yMin
                };
            }
            if (scale) {
                pt = {
                    x: point.x * scale,
                    y: point.y * scale
                };
            }
            return pt;
        };

        this.drawing.forEach((stroke) => {
            this.pen.start(this.canvas);
            if (stroke.length === 1) {
                this.pen.drawDot(updatePoint(stroke[0]));
            } else {
                stroke.forEach((point) => {
                    this.pen.drawWithoutSmoothing(updatePoint(point));
                });
            }
            this.pen.stop();
            this.strokeEnded.emit();
        });
    }

    /**
     * Update the drawing with a given segment of points
     * @param points - points to be drawn
     */
    updateWritingFromPoints(points) {
        let currentStroke;

        if (this.drawing.length === 0) {
            this.drawing = [[]];
            currentStroke = 0;
        } else {
            currentStroke = this.drawing.length - 1;
        }

        // draw the points
        this.pen.start(this.canvas);
        for (let i = 0; i < points.length; i++) {
            this.pen.drawWithoutSmoothing(points[i]);
            this.drawing[currentStroke].push(points[i]);
        }
        this.pen.stop();
    }

    /**
     * Sets the locked state of the canvas.
     * @param locked - true to lock the canvas, false to unlock
     */
    lockCanvas(locked: boolean) {
        this.isLocked = locked;
    }

    /**
     * Enables updates on an interval.
     */
    enableIntervalUpdates() {
        this.intervalUpdatesEnabled = true;
    }

    /**
     * Sets the upper bounds of the canvas, i.e. - the point above which drawing should not occur.
     * @param upperBounds - the minimum y value for drawing
     */
    setUpperBounds(upperBounds: number) {
        this.upperBounds = upperBounds;
    }

    /**
     * Returns the bounding box using origin and size objects
     * @return {Object} an object with origin (x, y) and size (width, height) sub objects
     */
    getBoundingBox() {
        return {
            origin: { x: this.bounds.xMin, y: this.bounds.yMin },
            size: {
                width: this.bounds.xMax - this.bounds.xMin,
                height: this.bounds.yMax - this.bounds.yMin
            }
        };
    }

    /**
     * Returns the canvas element.
     */
    getCanvas(): HTMLCanvasElement {
        return this.canvas;
    }

    /**
     * Sets the color of the pen.
     * @param r - the r value
     * @param g - the green value
     * @param b - the blue value
     * @param o - the opacity
     */
    setPenColor(r: number, g: number, b: number, o: number) {
        this.pen.setColor(`rgba(${r},${g},${b},${o})`);
    }

    /**
     * Interrupts the active drawing and completes the stroke.
     */
    interruptWriting() {
        this.onCanvasUp(null, true);
    }

    /**
     * Determine if the given region is adjacent to (touching) the specified region.
     * @param regionA - first region to compare
     * @param regionA - 2nd region to compare
     * @param buffer - amount of buffer to allow
     * @return - true if region "A" is touching region "B"
     */
    isAdjacentRegion(regionA: Position, regionB: Position, buffer: number) {
        const regionAMax = { x: regionA.x + regionA.w, y: regionA.y + regionA.h };
        const regionBMax = { x: regionB.x + regionB.w, y: regionB.y + regionB.h };
        const results: Adjacency = { left: false, right: false, top: false, bottom: false };

        // left side of A is touching right side of B
        results.left = ((regionA.x >= regionBMax.x - buffer) && (regionA.x <= regionBMax.x + buffer)) &&
            (regionAMax.y > (regionB.y - buffer) && regionA.y < (regionBMax.y + buffer));

        // right side of A is touching left side of B
        results.right = (regionAMax.x >= regionB.x - buffer) && (regionAMax.x <= regionB.x + buffer) &&
            (regionAMax.y > (regionB.y - buffer) && regionA.y < (regionBMax.y + buffer));

        // top side of A is touching bottom side of B
        results.top = (regionA.y >= (regionBMax.y - buffer) && (regionA.y <= (regionBMax.y + buffer))) &&
            (regionAMax.x > (regionB.x - buffer) && regionA.x < (regionBMax.x + buffer));

        // bottom
        results.bottom = (regionAMax.y <= (regionB.y + buffer) && (regionAMax.y >= (regionB.y - buffer))) &&
            (regionAMax.x > (regionB.x - buffer) && regionA.x < (regionBMax.x + buffer));

        return results;
    }

    /**
     * Determine if the given point is in the specified region.
     * @param point - a point
     * @param ptMin - point object specifying top left coordinate of region
     * @param ptMax - point object specifying bottom right coordinate of region
     * @param scale - scale factor
     * @return - true if the point is in the specified region
     */
    isPointInRegion(point: Point, ptMin: Point, ptMax: Point, scale?: number) {
        const pt = {
            x: scale ? point.x * scale : point.x,
            y: scale ? point.y * scale : point.y
        };
        // returns true if the point is within the region
        return (pt.x >= ptMin.x && pt.x <= ptMax.x && pt.y >= ptMin.y && pt.y <= ptMax.y);
    }

    /**
     * Determines if there is writing in the given region.
     * @param min - the min bounds of the region
     * @param max - the max bounds of the region
     * @param scale - the scale
     */
    isWritingInRegion(min: Point, max: Point, scale?: number) {
        return this.drawing.some((stroke) => this.isStrokeInRegion(stroke, min, max, scale));
    }

    /**
     * Determines if the given stroke contains any points in the given region.
     * @param stroke - array containing points in the stroke
     * @param min - the min bounds of the region
     * @param max - the max bounds of the region
     * @param scale - the scale
     */
    isStrokeInRegion(stroke: Array<Point>, min: Point, max: Point, scale?: number) {
        return stroke.some(pt => this.isPointInRegion(pt, min, max, scale));
    }

    private initCanvas() {
        this.bounds = {
            xMax: this.width,
            yMax: this.height,
            xMin: 0,
            yMin: 0
        };
        this.canvas = this.canvasRef.nativeElement;
        this.renderer.setAttribute(this.canvas, 'width', `${this.width}`);
        this.renderer.setAttribute(this.canvas, 'height', `${this.height}`);

        this.ctx = this.canvas.getContext('2d');
        this.ctx.lineJoin = 'round';
        this.ctx.lineCap = 'round';
    }

    private initEvents() {
        const isNative = this.platform.isNative();
        const startEventType = isNative ? 'touchstart' : 'mousedown';
        const moveEventType = isNative ? 'touchmove' : 'mousemove';
        const endEventType = isNative ? 'touchend' : 'mouseup';
        const leaveEventType = isNative ? 'touchleave' : 'mouseleave';

        const startWritingEvent: Observable<any> = fromEvent(this.canvas, startEventType);
        const endWritingEvent: Observable<any> = merge(fromEvent(this.canvas, endEventType), fromEvent(this.canvas, leaveEventType));
        const writingEvent: Observable<any> = concat(
            fromEvent(this.canvas, startEventType).pipe(first()),
            fromEvent(this.canvas, moveEventType).pipe(takeUntil(endWritingEvent))
        ).pipe(repeat());

        // TODO:  Emit events for container component
        startWritingEvent.forEach((e) => {
            this.canvasDown.emit({
                x: e.clientX || e.touches[0].clientX,
                y: e.clientY || e.touches[0].clientY
            });
            if (!this.isLocked) {
                this.onCanvasDown(e);
            }
        });
        writingEvent.subscribe((e: Event) => {
            if (e.type === moveEventType && !this.isLocked) {
                this.canvasMove(e);
            }
        });
        endWritingEvent.forEach((e) => {
            if (this.isWriting && !this.isLocked) {
                this.onCanvasUp(e);
                this.isWriting = false;
            }
            this.canvasUp.emit();
        });
    }

    // called on each start event
    private onCanvasDown(event) {
        event.preventDefault();
        event.stopPropagation();

        this.strokeStarted.emit({
            x: event.clientX || event.touches[0].clientX,
            y: event.clientY || event.touches[0].clientY
        });

        this.isWriting = true;

        this.pen.start(this.canvas);

        // fire update event if interval updating enabled
        if (this.intervalUpdatesEnabled) {
            this.lastUpdatePoint = null;
            interval(50).pipe(takeWhile(() => this.isWriting)).subscribe(() => this.updateWriting());
        }

        this.capturePoint(this.getPointFromEvent(event));
    }

    // called on each move event
    private canvasMove(event) {
        event.preventDefault();
        this.capturePoint(this.getPointFromEvent(event));
    }

    // called on each end event
    private onCanvasUp(event, isInterrupt?: boolean) {
        if (event) {
            event.preventDefault();
        }

        if (isInterrupt) {
            this.lockCanvas(true);
            // cleans up the interval
            this.isWriting = false;
        }

        this.pen.stop();
        this.strokeEnded.emit();

        if (this.stroke.length) {
            this.drawing.push(this.stroke);
        }
        this.stroke = [];
    }

    private updateWriting() {
        const sliceStart = this.lastUpdatePoint;
        const sliceEnd = this.stroke.length;
        const points = this.stroke.slice(sliceStart, sliceEnd);

        // calculate latest data to send, make sure it's not an empty array before firing
        if (points.length > 1) {
            this.writingUpdated.emit(points);
        }

        // update last point sent (subtract 1 to create continuous line segment)
        this.lastUpdatePoint = sliceEnd > 0 ? sliceEnd - 1 : 0;
    }

    private getPointFromEvent(event): Point {
        event = event.touches && event.touches.length ? event.touches.item(0) : event;

        const point = { x: event.pageX, y: event.pageY };

        if (this.canvas.offsetParent) {
            let tempCanvas: any = this.canvas;
            while (tempCanvas && !this.ignoreOffset || (tempCanvas && (tempCanvas.offsetLeft || tempCanvas.offsetTop))) {
                point.x -= tempCanvas.offsetLeft;
                point.y -= tempCanvas.offsetTop;
                tempCanvas = tempCanvas.offsetParent;
            }
        }
        if (point.y < this.upperBounds) {
            point.y = this.upperBounds;
        }
        return point;
    }

    private capturePoint(pt: Point) {
        const len = this.stroke.length;
        if (len === 0 || (this.stroke[len - 1].x !== pt.x || this.stroke[len - 1].y !== pt.y)) {
            this.stroke.push(pt);
        }
        this.pen.draw(pt);
        this.updateBoundingBox(pt);
    }

    /**
     * Updates the bounding box using the passed point, if needed
     * @param pt {Object} an object with x and y coords
     */
    private updateBoundingBox(pt) {
        this.bounds.xMax = Math.max(pt.x, this.bounds.xMax);
        this.bounds.yMax = Math.max(pt.y, this.bounds.yMax);
        this.bounds.xMin = Math.min(pt.x, this.bounds.xMin);
        this.bounds.yMin = Math.min(pt.y, this.bounds.yMin);
    }
}
