import { Injectable, NgZone } from '@angular/core';

import {
    BatteryContentStatus,
    ContentBatteryMap,
    ContentConstants,
    ContentDownloadProgress,
    ContentDownloadStatus,
    ContentProgressStateMap,
    ContentVersion,
    DownloadResult,
    QuerySubtest,
    QuerySubtestContentInfo,
    QueryVersionResult,
    SubtestContentVersion,
    TestBattery,
    ZipFile
} from '@app/models';
import { UUID } from '@app/shared';
import { AssessEvents, BatteryEvents } from '@app/shared/assess-events';
import { environment } from '@appenv';

import { Storage } from '@ionic/storage';
import { DirectoryEntry, FileEntry } from '@ionic-native/file/ngx';

import axios, { AxiosResponse } from 'axios';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeLast } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { DeployService } from './deploy.service';
import { Events } from './events.service';
import { HttpService } from './http.service';
import { FileUtilService } from './file-util.service';
import { LoggingService, Logger } from './logging.service';
import { PlatformService } from './platform.service';
import { TaskQService } from './taskq';
import { ZipService, ZipTaskProgress } from './zip';

const CONTENT_ARCHIVE_DIR = 'contentArchiveDir';
const PENDING_CONTENT_JSON = 'pendingContentGuids';
const PENDING_STIM_JSON = 'pendingStimGuids';
const EXTRACTED_CONTENT_HASHES = 'extractedContentHashes';
const EXTRACTED_STIM_HASHES = 'extractedStimHashes';
const DOWNLOAD_STATUS_KEY = 'contentDownloadStatus';
const QUERY_CONTENT_URL_PREFIX = `${environment.centralEndpoint}/content`;
const CONTENT_FOLDER = 'content';
const TASK_NAME = 'DOWNLOAD_CONTENT';

interface DownloadContentTask {
    id: string;
    downloadableVersions: SubtestContentVersion[];
}

/**
 * Stim content downloaded and extraction service
 */
@Injectable()
export class ContentDownloaderService {
    private logger: Logger;

    private statusChanged: BehaviorSubject<ContentProgressStateMap>;

    private batteryMap: ContentBatteryMap = {};

    private downloadsStarted: any = {};
    private extractionStarted: any = {};
    private statusSubscription: Subscription;

    constructor(
        private loggingService: LoggingService,
        private storage: Storage,
        private http: HttpService,
        private zipService: ZipService,
        private platformService: PlatformService,
        private deployService: DeployService,
        private fileUtil: FileUtilService,
        private events: Events,
        private authService: AuthService,
        private zone: NgZone,
        private taskService: TaskQService
    ) {
        this.logger = this.loggingService.getLogger('ContentDownloaderService');
        this.statusChanged = new BehaviorSubject({});
        BatteryEvents.onBatteryCreation.subscribe((bat: TestBattery) => {
            bat.contentDownloadStatus = this.calculateBatteryContentStatus(bat);
            this.batteryMap[bat.id] = bat;
        });
        this.statusSubscription = this.onStatusChanged().subscribe(() => {
            this.zone.run(() => {
                Object.keys(this.batteryMap).forEach(id => {
                    const battery = this.batteryMap[id];
                    battery.contentDownloadStatus = this.calculateBatteryContentStatus(battery);
                });
            });
        });
        // when the app is resumed, we restart download process
        this.events.subscribe(AssessEvents.paused, () => {
            this.saveProgress().then();
        });

        const recheckDownloads = new Subject();
        this.events.subscribe(AssessEvents.resumed, () => recheckDownloads.next());
        this.events.subscribe(AssessEvents.loginReset, () => recheckDownloads.next());
        recheckDownloads.pipe(debounceTime(500)).subscribe(() => {
            if (this.statusSubscription) {
                this.statusSubscription.unsubscribe();
            }
            this.saveProgress().then(() => {
                this.authService.isLoggedIn()
                    .then(status => {
                        if (status) {
                            this.zone.run(() => {
                                const batIds = Object.keys(this.batteryMap);
                                if (batIds.length > 0) {
                                    this.statusSubscription = this.onStatusChanged().subscribe(() => {
                                        batIds.forEach(id => {
                                            const battery = this.batteryMap[id];
                                            battery.contentDownloadStatus = this.calculateBatteryContentStatus(battery);
                                        });
                                    });
                                    this.logger.info(`Checking downloads when app resumes`);
                                    this.startForBattery(...batIds.map(_ => this.batteryMap[_]))
                                        .then(() => this.logger.info(`After resuming`));
                                }
                            });
                        }
                    });
            });
        });
    }

    public onStatusChanged(): Observable<ContentProgressStateMap> {
        return this.statusChanged.asObservable();
    }

    public async initStatus() {
        this.storage.get(DOWNLOAD_STATUS_KEY).then(val => {
            const json = val || '{}';
            this.statusChanged.next(JSON.parse(json));
        });
        const taskEvents = this.taskService.defineTask(TASK_NAME, (task: any) => this.runDownloadContentOperation(task));

        taskEvents.onIsEmpty.subscribe(val => {
            if (val) {
                this.unzipPending([], true).then(() => this.saveProgress()).then(() => true);
            }
        });

        this.events.subscribe(AssessEvents.logout, () => this.cancelPendingDownloads());
    }

    /**
     * Returns content versions for all subtestguids in a battery
     * @param battery TestBattery
     */
    public async queryBatteryVersions(battery: TestBattery): Promise<QuerySubtestContentInfo> {
        this.logger.info(`Getting updateable content version for battery guids for branch`);
        return await this.querySubtestVersions(battery.subtestRefs);
    }

    public async getExtractedHashes(): Promise<any> {
        const existingContent = JSON.parse(await this.storage.get(EXTRACTED_CONTENT_HASHES) || '{}');
        const existingStims = JSON.parse(await this.storage.get(EXTRACTED_STIM_HASHES) || '{}');
        return { existingContent, existingStims };
    }

    /**
     * Returns content versions for all subtestguids in a battery
     * @param battery TestBattery
     */
    public async queryBatteryVersionsForRemoteStim(battery: TestBattery, existingContent, existingStims): Promise<QuerySubtestContentInfo> {
        this.logger.info(`Getting updateable content version for battery guids for branch`);
        const subtestRefs: QuerySubtest[] = battery.subtestRefs.map(_ => {
            return {
                testDisplayName: _.testDisplayName,
                subtestGuid: _.subtestGUID,
                subtestName: _.subtestName
            };
        }).filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj.subtestGuid).indexOf(obj.subtestGuid) === pos;
        });
        return await this.querySubtestVersions(subtestRefs, existingContent, existingStims);
    }

    /**
     * Returns content versions for all subtests provided
     * @param subtestRefs array of SubtestRef
     */
    public async querySubtestVersions(subtestRefs: QuerySubtest[], existingContent = null,
        existingStims = null): Promise<QuerySubtestContentInfo> {
        existingContent = existingContent || JSON.parse(await this.storage.get(EXTRACTED_CONTENT_HASHES) || '{}');
        existingStims = existingStims || JSON.parse(await this.storage.get(EXTRACTED_STIM_HASHES) || '{}');
        const branch: string = await this.getBranchName();
        this.logger.info(`Getting updateable content version for subtestguids for branch ` +
            ` ${branch} and config ${environment.config}`);
        try {
            const result: QueryVersionResult = await this.http.post(`${QUERY_CONTENT_URL_PREFIX}/queryA2Content`, {
                branch: branch,
                config: environment.config,
                existingContent,
                existingStims,
                subtestGuids: subtestRefs.map(it => it.subtestGuid)
            });
            if (!result.success) {
                this.logger.error(`Query version failed`);
                throw new Error(JSON.stringify(result));
            }
            const stims = result.stim;
            const contents = result.content;
            const contentVersionMap = {};
            let availableStimSize = 0;
            let availableContentSize = 0;
            let currentStimSize = 0;
            let currentContentSize = 0;
            Object.keys(stims).forEach(subtestGuid => {
                let stimVersion: ContentVersion;
                const subtestRef = subtestRefs.find(it => it.subtestGuid === subtestGuid);
                const stim = stims[subtestGuid];
                if (stim && subtestRef) {
                    stimVersion = {
                        ...stim, type: ContentConstants.STIM_TYPE
                    };
                    if (!contentVersionMap[subtestGuid]) {
                        contentVersionMap[subtestGuid] = {};
                    }
                    contentVersionMap[subtestGuid].stim = stimVersion;
                    contentVersionMap[subtestGuid].name = `${subtestRef.testDisplayName} ${subtestRef.subtestName}`;
                    if (stimVersion.status === ContentConstants.UPDATE_AVAILABLE) {
                        availableStimSize += stimVersion.size;
                    } else if (stimVersion.status === ContentConstants.CURRENT) {
                        currentStimSize += stimVersion.size;
                    }
                }
            });
            Object.keys(contents).forEach(subtestGuid => {
                let contentVersion: ContentVersion;
                const subtestRef = subtestRefs.find(it => it.subtestGuid === subtestGuid);
                const content = contents[subtestGuid];
                if (content && subtestRef) {
                    contentVersion = {
                        ...content, type: ContentConstants.CONTENT_TYPE
                    };
                    if (!contentVersionMap[subtestGuid]) {
                        contentVersionMap[subtestGuid] = {};
                    }
                    contentVersionMap[subtestGuid].content = contentVersion;
                    contentVersionMap[subtestGuid].name = `${subtestRef.testDisplayName} ${subtestRef.subtestName}`;

                    if (contentVersion.status === ContentConstants.UPDATE_AVAILABLE) {
                        availableContentSize += contentVersion.size;
                    } else if (contentVersion.status === ContentConstants.CURRENT) {
                        currentContentSize += contentVersion.size;
                    }
                }
            });

            const subtestVersions: SubtestContentVersion[] = [];
            Object.keys(contentVersionMap).forEach(subtestGuid => {
                subtestVersions.push({
                    contentVersion: contentVersionMap[subtestGuid].content,
                    stimVersion: contentVersionMap[subtestGuid].stim,
                    subtestGuid,
                    subtestName: contentVersionMap[subtestGuid].name
                });
            });
            const subtestsInfo: QuerySubtestContentInfo = {
                availableContentSize,
                availableStimSize,
                currentContentSize,
                currentStimSize,
                subtestVersions
            };
            return subtestsInfo;
        } catch (e) {
            this.logger.error(`Error in query subtest version ${JSON.stringify(e, null, 5)}`);
            throw e;
        }
    }
    /**
     * Kickstarts downloads per subtes guids for all batteries passed only for the ones that are available
     * @param batteries
     */
    public startForBattery(...batteries: TestBattery[]): Promise<any> {
        const subtestRefs: QuerySubtest[] = [].concat(...batteries.map(it => it.subtestRefs)).map(_ => {
            return {
                testDisplayName: _.testDisplayName,
                subtestGuid: _.subtestGUID,
                subtestName: _.subtestName
            };
        }).filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj.subtestGuid).indexOf(obj.subtestGuid) === pos;
        });
        return this.startForSubtests(...subtestRefs);
    }

    /**
     * Determins if any of the subtests provided have stim updates available.
     * @param subtestRefs - a list of subtests
     */
    public async isStimUpdateAvailable(...subtestRefs: QuerySubtest[]): Promise<boolean> {
        const uniqueRefs = subtestRefs.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj.subtestGuid).indexOf(obj.subtestGuid) === pos;
        });
        const queryResult = await this.querySubtestVersions(uniqueRefs);
        const available = (queryResult.availableStimSize !== 0);
        if (available) {
            const availableSubtestVersions: SubtestContentVersion[] = this.getAvailableSubtestContents(queryResult);
            const downloadableVersions = availableSubtestVersions.filter(_ => {
                return (!this.downloadsStarted[_.subtestGuid])
                    && !this.extractionStarted[_.subtestGuid];
            });
            await this.addOrUpdateSubtestProgress(downloadableVersions);
        }
        return available;
    }

    /**
     * Kickstarts downloads per subtest guids for subtest refs passed only for the ones that are available
     * @param batteries
     */
    public startForSubtests(...subtestRefs: QuerySubtest[]): Promise<boolean> {
        const uniqueRefs = subtestRefs.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj.subtestGuid).indexOf(obj.subtestGuid) === pos;
        });
        return this.querySubtestVersions(uniqueRefs)
            .then(queryResult => this.startForSubtestVersions(queryResult));
    }

    public startForSubtestVersions(queryResult: QuerySubtestContentInfo): Promise<boolean> {
        return Promise.resolve()
            .then(() => {
                this.logger.info(`Got query versions for assessments.` +
                    `There are ${(queryResult.availableContentSize / (1024 * 1024)).toFixed(2)} MB ` +
                    `of content and ${(queryResult.availableStimSize / (1024 * 1024)).toFixed(2)} MB of stims to download`);
                const availableSubtestVersions: SubtestContentVersion[] = this.getAvailableSubtestContents(queryResult);
                return availableSubtestVersions;
            })
            .then(availableSubtestVersions => {
                if (availableSubtestVersions.length === 0) {
                    this.logger.success('Yay! We are caught up');
                    return [];
                } else {
                    this.events.publish(AssessEvents.contentDownloadStarted);
                    const eligibleSubtestVersions = availableSubtestVersions.filter(_ => {
                        return (!this.downloadsStarted[_.subtestGuid])
                            && !this.extractionStarted[_.subtestGuid];
                    });
                    return eligibleSubtestVersions;
                }
            })
            .then(downloadableVersions => {
                downloadableVersions.forEach(_ => this.downloadsStarted[_.subtestGuid] = true);
                return this.addOrUpdateSubtestProgress(downloadableVersions).then(() => downloadableVersions);
            })
            .then(downloadableVersions => {
                if (downloadableVersions.length > 0) {
                    this.taskService.addToTask(TASK_NAME,
                        { downloadableVersions, id: downloadableVersions.map(_ => _.subtestName).join(',') });
                }
                return true;
            });
    }

    private runDownloadContentOperation(task: DownloadContentTask): Promise<any> {
        return this.startDownloadVersions(task.downloadableVersions);
    }

    private cancelPendingDownloads(): void {
        this.taskService.cancelPending(TASK_NAME).then(() => this.logger.debug(`Cancelled all downloads`));
    }

    private async startDownloadVersions(_downloadableVersions: SubtestContentVersion[]): Promise<boolean> {
        return this.startDownloads(_downloadableVersions)
            .then(res => this.logger.warn(`download workers finished with ${res}`))
            .then(() => this.saveProgress())
            .then(() => {
                if (_downloadableVersions.length > 0) {
                    this.logger.success(`Downloaded content for all subtests in batteries`);
                    return this.unzipPending(_downloadableVersions);
                }
                this.logger.success('No new subtests in dashboard found');
                return;
            })
            .then(() => true);
    }

    private async addOrUpdateSubtestProgress(downloadableVersions: SubtestContentVersion[]): Promise<void> {
        for (const version of downloadableVersions) {
            const state: ContentDownloadProgress = new ContentDownloadProgress();
            state.state = ContentDownloadStatus.STARTED;
            state.subtestGuid = version.subtestGuid;
            state.subtestName = version.subtestName;
            const itemsToDownload: ContentVersion[] = [];
            let totalFilesToExtract = 0;
            let existingStim, existingContent;
            if (version.stimVersion && version.stimVersion.status === ContentConstants.UPDATE_AVAILABLE) {
                itemsToDownload.push(version.stimVersion);
                totalFilesToExtract++;
                existingStim = await this.isDownloaded('stim', version.subtestGuid, version.stimVersion.hash);
            }
            if (version.contentVersion && version.contentVersion.status === ContentConstants.UPDATE_AVAILABLE) {
                itemsToDownload.push(version.contentVersion);
                totalFilesToExtract++;
                existingContent = await this.isDownloaded('content', version.subtestGuid, version.contentVersion.hash);
            }
            state.totalFilesToExtract = totalFilesToExtract;

            Object.assign(state, {
                contentDownloaded: 0,
                stimDownloaded: 0,
                totalFilesToExtract,
                total: itemsToDownload.map(i => i.size).reduce((acc, v) => acc + v)
            });

            if (existingContent && existingStim) {
                const info = this.getDownloadStatus(version.subtestGuid);
                state.state = ContentDownloadStatus.DOWNLOADED;
                state.contentDownloaded = info.contentDownloaded;
                state.stimDownloaded = info.stimDownloaded;
                state.zipfiles = info.zipfiles;
            }

            this.recordProgress(state);
        }
    }

    /**
     * Unzips any pending zips
     */
    public unzipPending(versions: SubtestContentVersion[], clearDownloaded: boolean = false): Promise<void> {
        return this.getContentDir()
            .then(contentFolder => {
                return this.getContentArchiveDir().then(archiveDir => {
                    return { archiveDir, contentFolder };
                });
            })
            .then((folders) => {
                return Promise.resolve()
                    .then(() => {
                        if (clearDownloaded) {
                            return this.getAllDownloaded().filter(_ => !this.extractionStarted[_.subtestGuid]);
                        }
                        return this.getAllPendingExtractions().then((pending: ContentDownloadProgress[]) => {
                            const eligibleUnzips = pending.filter(_ => !this.extractionStarted[_.subtestGuid]);
                            return eligibleUnzips;
                        });
                    })
                    .then((pending: ContentDownloadProgress[]) => {
                        pending.forEach(_ => this.extractionStarted[_.subtestGuid] = true);
                        // serial unzipping
                        return pending.reduce((previousPromise, progress) => {
                            return previousPromise.then(() => {
                                return this.unzipFiles(progress, folders.contentFolder, folders.archiveDir);
                            });
                        }, Promise.resolve()).then(() => {
                            pending.forEach(_ => delete this.downloadsStarted[_.subtestGuid]);
                        });
                    }).catch(e => {
                        // just log and return;
                        this.logger.error(`Error in unziping ${JSON.stringify(e)}`);
                        return;
                    }).finally(() => {
                        this.saveProgress();
                        this.events.publish(AssessEvents.contentDownloadEnded);
                    });
            });
    }

    /**
    * Returns battery status of all subtestGUID
    * @param battery
    */
    public calculateBatteryContentStatus(battery: TestBattery): BatteryContentStatus {
        const subtestGuids = battery.subtestRefs.map(it => it.subtestGUID).filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj).indexOf(obj) === pos;
        });
        const progress = [];
        for (const guid of subtestGuids) {
            progress.push(this.getDownloadStatus(guid));
        }
        const b: BatteryContentStatus = new BatteryContentStatus();

        let totalToDownload = 0;
        let downloadedSize = 0;
        let totalToExtract = 0;
        let extracted = 0;

        for (const p of progress) {
            totalToExtract += p.totalFilesToExtract;
            totalToDownload += (p.total || 0);
            extracted += p.filesExtracted;
            downloadedSize += ((p.stimDownloaded || 0) + (p.contentDownloaded || 0));
        }
        b.totalToDownload = totalToDownload;
        b.extracted = extracted;
        b.downloadedSize = downloadedSize;
        b.totalToExtract = totalToExtract;
        b.anyFailedDownload = progress.filter(_ => _.state === ContentDownloadStatus.FAILED).length > 0;
        b.anyNotStarted = progress.filter(_ => _.state === ContentDownloadStatus.NOT_AVAILABLE).length > 0;
        return b;
    }

    /**
     * Gets those subtest content which has updates
     * @param subtestInfo
     */
    public getAvailableSubtestContents(subtestInfo: QuerySubtestContentInfo): SubtestContentVersion[] {
        return subtestInfo.subtestVersions.filter(it => this.hasUpdates(it));
    }

    /**
     * For now , mark it as to download if any has updates available
     * @param subtestContent
     */
    public hasUpdates(subtestContent: SubtestContentVersion): boolean {
        return subtestContent.stimVersion.status === ContentConstants.UPDATE_AVAILABLE
            || subtestContent.contentVersion.status === ContentConstants.UPDATE_AVAILABLE;
    }

    /**
     * Returns pending stim guids whose downloads were started
     */
    public async getPendingStimsGuids(): Promise<string[]> {
        const json = JSON.parse(await this.storage.get(PENDING_STIM_JSON) || '{}');
        return Object.keys(json);
    }

    /**
     * Returns pending content guids whose downloads were started
     */
    public async getPendingContentGuids(): Promise<string[]> {
        const json = JSON.parse(await this.storage.get(PENDING_CONTENT_JSON) || '{}');
        return Object.keys(json);
    }

    public async isPendingComplete(subtestGuid: string): Promise<boolean> {
        return (await this.getPendingContentGuids()).indexOf(subtestGuid) !== -1 &&
            (await this.getPendingStimsGuids()).indexOf(subtestGuid) !== -1;
    }

    /**
     * Returns boolean if already extracted
     * @param type
     * @param subtestGuid
     */
    public async isAlreadyExtracted(type: 'stim' | 'content', subtestGuid: string, hash: string): Promise<boolean> {
        let key: string;
        key = type === ContentConstants.STIM_TYPE ? EXTRACTED_STIM_HASHES : EXTRACTED_CONTENT_HASHES;
        const jsonStr = await this.storage.get(key) || '{}';
        const json = JSON.parse(jsonStr);
        return json[subtestGuid] === hash;
    }

    /**
     * Returns fileUrl if already downloaded
     * @param type
     * @param subtestGuid
     */
    public isDownloaded(type: 'stim' | 'content', subtestGuid: string, hash: string): string {
        const info = this.getDownloadStatus(subtestGuid);
        if (info &&
            (info.state === ContentDownloadStatus.DOWNLOADED)) {
            const zipFile = info.zipfiles.find(it => it.type === type && it.hash === hash);
            return zipFile ? zipFile.file : null;
        } else {
            return null;
        }
    }

    /**
     *
     * @param subtestguid
     */
    public getDownloadStatus(subtestguid: string): ContentDownloadProgress {
        const allStatus: ContentProgressStateMap = this.statusChanged.value;
        const state: ContentDownloadProgress = new ContentDownloadProgress();
        if (allStatus[subtestguid]) {
            Object.assign(state, allStatus[subtestguid]);
        } else {
            state.state = ContentDownloadStatus.NOT_AVAILABLE;
        }
        return state;
    }

    public async getAllPendingExtractions(): Promise<ContentDownloadProgress[]> {
        const json: ContentProgressStateMap = this.statusChanged.value;
        const pending: ContentDownloadProgress[] = [];
        const anyPendingStims = await this.getPendingStimsGuids();
        const anyPendingContent = await this.getPendingContentGuids();
        Object.keys(json).forEach(guid => {
            const progress: ContentDownloadProgress = json[guid];
            if (progress.state === ContentDownloadStatus.DOWNLOADED && progress.zipfiles.length > 0) {
                pending.push(progress);
            } else if (progress.state === ContentDownloadStatus.EXTRACTED &&
                anyPendingContent.indexOf(guid) === -1 && anyPendingStims.indexOf(guid) === -1) {
                progress.filesExtracted = progress.filesExtracted;
            }
        });
        return pending;
    }

    public getAllDownloaded(): ContentDownloadProgress[] {
        const json: ContentProgressStateMap = this.statusChanged.value;
        const pending: ContentDownloadProgress[] = [];
        Object.keys(json).forEach(guid => {
            const progress: ContentDownloadProgress = json[guid];
            if (progress.state === ContentDownloadStatus.DOWNLOADED && progress.zipfiles.length > 0) {
                pending.push(progress);
            } else if (progress.state === ContentDownloadStatus.EXTRACTING) {
                pending.push(progress);
            }
        });
        return pending;
    }

    /**
     * Starts downloads
     * @param downloadableVersions
     */
    public async startDownloads(downloadableVersions: SubtestContentVersion[]): Promise<string> {
        this.logger.debug(`Starting downloads`);
        for (const version of downloadableVersions) {
            const itemsToDownload: ContentVersion[] = [];
            if (version.stimVersion && version.stimVersion.status === ContentConstants.UPDATE_AVAILABLE) {
                itemsToDownload.push(version.stimVersion);
            }
            if (version.contentVersion && version.contentVersion.status === ContentConstants.UPDATE_AVAILABLE) {
                itemsToDownload.push(version.contentVersion);
            }
            const state = this.getDownloadStatus(version.subtestGuid);
            if (state.state !== ContentDownloadStatus.DOWNLOADED) {
                state.state = ContentDownloadStatus.DOWNLOADING;
            }
            this.recordProgress(state);
            for (const i of itemsToDownload) {
                await this.markPending(i.type, version.subtestGuid);
            }
            await new Promise((res, _rej) => {
                this.runDownloads(this.platformService.isNative(), version, itemsToDownload, state).finally(() => {
                    this.saveProgress().then(() => {
                        setTimeout(() => res(), 200);
                    });
                });
            });
        }
        return 'downloaded';
    }

    /**
     * Native download url using file transfer
     * @param url
     * @param state
     * @param version
     * @param sub
     */
    public nativeDownload(url: string, state: ContentDownloadProgress, version: ContentVersion): Promise<DownloadResult> {
        const fileName = `${version.type}-${state.subtestGuid}.zip`;
        return this.getContentArchiveDir()
            .then(contentArchiveDir => {
                return this.fileUtil.downloadUrlToDir(url, fileName, contentArchiveDir,
                    (progressEvent: ProgressEvent) => {
                        switch (version.type) {
                            case ContentConstants.STIM_TYPE:
                                state.stimDownloaded = progressEvent.loaded;
                                break;
                            case ContentConstants.CONTENT_TYPE:
                                state.contentDownloaded = progressEvent.loaded;
                                break;
                        }
                        state.state = ContentDownloadStatus.DOWNLOADING;
                        this.recordProgress(state);
                    });
            }).then((file: FileEntry) => {
                this.recordProgress(state);
                return { version, subtestGuid: state.subtestGuid, alreadyDownloaded: true, fileUrl: file.name, state };
            });
    }

    /**
     * Gets a stim zip as a blob. Throws exception if not found
     * @param subtestGuid
     */
    public async getStimZip(subtestGuid: string): Promise<Blob> {
        const contentArchiveDir = await this.getContentArchiveDir();
        const fileName = `stim-${subtestGuid}.zip`;
        const zipFile: FileEntry = await this.fileUtil.getFile(contentArchiveDir, fileName);
        return await this.fileUtil.toBlob(zipFile, 'application/zip');
    }

    /**
     * Unzips a blob object to the content folder. Can be used to send a blob via MPC using `getStimZip`
     * method and unzip that in the stim device
     * @param blob zip blob
     */
    public async unzipBlobToContentDir(blob: Blob): Promise<Observable<ZipTaskProgress>> {
        const contentDir = await this.getContentDir();
        const blobFile = await this.createTempZipFile(blob);
        const observable = this.zipService.unzip(blobFile, contentDir);
        const removeFile = () => {
            blobFile.remove(() => this.logger.debug(`Temp file ${blobFile.toURL()} removed`),
                e => this.logger.error(`Error removing temp file ${blobFile.toURL()} ${JSON.stringify(e)}`));
        };
        observable.pipe(takeLast(1)).subscribe(progress => removeFile(), err => removeFile());
        return observable;
    }

    /**
     * Saves the blob into the content archive folder
     * @param downloadResult
     */
    private async saveZipFile(downloadResult: DownloadResult, subtestContent: SubtestContentVersion): Promise<FileEntry> {
        this.logger.debug(`Saving ${subtestContent.subtestName} - ${downloadResult.subtestGuid}` +
            ` zip of ${downloadResult.version.type} to content archive`);
        const contentArchiveDir = await this.getContentArchiveDir();
        const fileName = `${downloadResult.version.type}-${downloadResult.subtestGuid}.zip`;
        const zipFile: FileEntry = await this.fileUtil.getNewFile(contentArchiveDir, fileName);
        await this.fileUtil.writeBlob(contentArchiveDir, zipFile, downloadResult.blob);
        this.logger.success(`Saved zip file to ${zipFile.toURL()}`);
        return zipFile;
    }

    /**
     * Unzip each file belonging to a subtest to the content folder
     * @param state ContentDownloadProgress
     */
    private unzipFiles(state: ContentDownloadProgress, contentFolder: DirectoryEntry, archiveDir: DirectoryEntry): Promise<void> {
        state.state = ContentDownloadStatus.EXTRACTING;
        state.totalFilesToExtract = state.zipfiles.length;
        this.recordProgress(state);
        let filesExtracted = 0;

        return state.zipfiles.reduce((previousPromise, zipfile) => {
            return previousPromise.then(() => {
                return this.fileUtil.getFile(archiveDir, zipfile.file)
                    .then(file => {
                        return new Promise((res, rej) => {
                            this.zipService.unzip(file, contentFolder).subscribe((zipState: ZipTaskProgress) => {
                                // ignore
                            }, err => rej(err), () => {
                                filesExtracted++;
                                state.filesExtracted = filesExtracted;
                                this.recordProgress(state);
                                return this.onSuccessFulExtraction(zipfile.type, state.subtestGuid, zipfile.hash)
                                    .then(() => res()).finally(() => {
                                        this.logger.success(`Extracted ${zipfile.file} of type ${zipfile.type} successfully`);
                                    });

                            });
                        });
                    });
            });
        }, Promise.resolve(null))
            .then(() => {
                state.state = ContentDownloadStatus.EXTRACTED;
                state.filesExtracted = state.totalFilesToExtract;
                delete this.extractionStarted[state.subtestGuid];
                this.recordProgress(state);
            }, e => {
                state.state = ContentDownloadStatus.FAILED;
                this.recordProgress(state);
            }).finally(() => delete this.extractionStarted[state.subtestGuid]);
    }

    /**
     * Returns the content archive dir where the zips are stored
     */
    private async getContentArchiveDir(): Promise<DirectoryEntry> {
        const root: DirectoryEntry = await this.fileUtil.getRootPath();
        return this.fileUtil.createAndGetDirectory(root, CONTENT_ARCHIVE_DIR);
    }

    /**
     * Returns the content dir where the zips are extracted
     */
    private async getContentDir(): Promise<DirectoryEntry> {
        const root: DirectoryEntry = await this.fileUtil.getRootPath();
        return this.fileUtil.createAndGetDirectory(root, CONTENT_FOLDER);
    }

    /**
     * We mark the download as pending when started
     * @param type
     * @param subtestGuid
     */
    private async markPending(type: 'stim' | 'content', subtestGuid: string): Promise<void> {
        let key: string;
        key = type === ContentConstants.STIM_TYPE ? PENDING_STIM_JSON : PENDING_CONTENT_JSON;
        const jsonStr = await this.storage.get(key) || '{}';
        const json = JSON.parse(jsonStr);
        json[subtestGuid] = true;
        await this.storage.set(key, JSON.stringify(json));
    }

    /**
     * After a zip is successfully extracted we clear the pending list and add to the extracted hashes
     * @param version
     * @param subtestGuid
     */
    private async onSuccessFulExtraction(type: 'stim' | 'content', subtestGuid: string, hash: string): Promise<void> {
        let key: string;
        key = type === ContentConstants.STIM_TYPE ? PENDING_STIM_JSON : PENDING_CONTENT_JSON;
        let jsonStr = await this.storage.get(key) || '{}';
        let json = JSON.parse(jsonStr);
        delete json[subtestGuid];
        await this.storage.set(key, JSON.stringify(json));

        key = type === ContentConstants.STIM_TYPE ? EXTRACTED_STIM_HASHES : EXTRACTED_CONTENT_HASHES;
        jsonStr = await this.storage.get(key) || '{}';
        json = JSON.parse(jsonStr);
        json[subtestGuid] = hash;
        await this.storage.set(key, JSON.stringify(json));
    }

    /**
     * Records the progress for later use
     * @param version
     * @param progress
     */
    private recordProgress(progress: ContentDownloadProgress): void {
        const currentVal = this.statusChanged.value;
        currentVal[progress.subtestGuid] = progress;
        this.statusChanged.next(currentVal);
    }


    /**
     * Saves the progress for later us
     */
    private async saveProgress(): Promise<void> {
        const currentVal = this.statusChanged.value;
        await this.storage.set(DOWNLOAD_STATUS_KEY, JSON.stringify(currentVal));
    }


    /**
     * Writes a temp file so that we cna pass a url to zip service
     * @param blob
     */
    private async createTempZipFile(blob: Blob): Promise<FileEntry> {
        const fileName = `${UUID.generate()}-temp.zip`;
        const rootDir = await this.fileUtil.getRootPath();
        const tempFile = await this.fileUtil.writeFile(rootDir, fileName, blob);
        return tempFile;
    }

    /**
     * Returns the branch name in lower case
     */
    private async getBranchName(): Promise<string> {
        if (this.platformService.isNative()) {
            const channelName = await this.deployService.getChannelName();
            return channelName.toLowerCase();
        }
        return environment.branch;
    }

    private runDownloads(isNative: boolean, version: SubtestContentVersion, itemsToDownload: ContentVersion[],
        state: ContentDownloadProgress): Promise<string> {

        return new Promise(async (res, _rej) => {
            try {
                const result: DownloadResult[] = await this.downloadZipFiles(isNative, itemsToDownload, state);
                const zipFiles: ZipFile[] = [];
                for (const downloadResult of result) {
                    if (!downloadResult) {
                        continue;
                    }
                    let fileUrl;
                    if (downloadResult.alreadyDownloaded) {
                        fileUrl = downloadResult.fileUrl;
                    } else {
                        await this.saveZipFile(downloadResult, version);
                        fileUrl = `${downloadResult.version.type}-${downloadResult.subtestGuid}.zip`;
                    }

                    zipFiles.push({ file: fileUrl, type: downloadResult.version.type, hash: downloadResult.version.hash });
                }
                state.zipfiles = zipFiles;
                state.state = ContentDownloadStatus.DOWNLOADED;
                this.recordProgress(state);
                this.logger.success(`From worker: Downloaded for ${version.subtestName}`);
                res();
            } catch (error) {
                state.state = ContentDownloadStatus.FAILED;
                this.recordProgress(state);
                delete this.downloadsStarted[state.subtestGuid];
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    this.logger.warn(`falls out of the range of 2xx: ` +
                        `Failed for ${version.subtestName} with error ` +
                        JSON.stringify({ data: error.response.data, status: error.response.status, headers: error.response.headers })
                    );
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    this.logger.warn(`No response received: ` +
                        `Failed for ${version.subtestName} with error ` +
                        JSON.stringify(error.request)
                    );
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.logger.warn(`Unknown received: ` +
                        `Failed for ${version.subtestName} with error ` +
                        JSON.stringify(error.message)
                    );
                }
                this.logger.warn(`Config: ` +
                    `Failed for ${version.subtestName} with error ` +
                    JSON.stringify(error.config)
                );
                res();
            }
        });
    }

    /**
     * Downloads zip files per subtest guid
     * @param versions
     * @param subtestGuid
     * @param state
     * @param sub
     */
    private async downloadZipFiles(isNative: boolean, versions: ContentVersion[],
        state: ContentDownloadProgress): Promise<DownloadResult[]> {
        const result = [];
        try {
            for (const version of versions) {
                const fileUrl: string = this.isDownloaded(version.type, state.subtestGuid, version.hash);
                if (fileUrl) {
                    // why you wanna download again!
                    this.logger.warn(`Download Worker: We already have the latest download ${state.subtestName} ${version.type} done.`);
                    result.push(await new Promise<DownloadResult>((res, _rej) => {
                        this.recordProgress(state);
                        res({ version, subtestGuid: state.subtestGuid, alreadyDownloaded: true, fileUrl });
                    }));
                } else {
                    switch (version.type) {
                        case ContentConstants.STIM_TYPE:
                            state.stimDownloaded = 0;
                            break;
                        case ContentConstants.CONTENT_TYPE:
                            state.contentDownloaded = 0;
                            break;
                    }
                    this.recordProgress(state);
                    this.logger.info(`Download worker: Downloading ${state.subtestName} ${version.type} from ${version.url}`);
                    if (isNative) {
                        // proxy methods are always promises
                        result.push(await new Promise<DownloadResult>(async (res, rej) => {
                            try {
                                const downloadResult = await this.nativeDownload(version.url, state, version);
                                Object.assign(state, downloadResult.state);
                                this.logger.success(`Download Worker: ${state.subtestName} ${version.type} done.`);
                                this.recordProgress(state);
                                res(downloadResult);
                            } catch (e) {
                                rej(e);
                            }
                        }));
                        await new Promise(res => setTimeout(() => res(), 200));
                    } else {
                        const instance = axios.create({
                            method: 'GET',
                            responseType: 'arraybuffer'
                        });
                        const axiosResult: AxiosResponse = await instance.request({
                            url: version.url,
                            onDownloadProgress: (event: ProgressEvent) => {
                                switch (version.type) {
                                    case ContentConstants.STIM_TYPE:
                                        state.stimDownloaded = event.loaded;
                                        break;
                                    case ContentConstants.CONTENT_TYPE:
                                        state.contentDownloaded = event.loaded;
                                        break;
                                }
                                this.recordProgress(state);
                            }
                        });
                        this.logger.success(`Download Worker: ${state.subtestName} ${version.type} done.`);
                        this.recordProgress(state);
                        result.push({ blob: axiosResult.data, version, subtestGuid: state.subtestGuid });
                        await new Promise(res => setTimeout(() => res(), 200));
                    }
                }
            }
        } catch (e) {
            this.logger.error(`Err: ${JSON.stringify(e, null, 5)}`);
            throw e;
        }

        return result;
    }
}
