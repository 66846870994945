import { Injectable } from '@angular/core';

import { DirectoryEntry, FileEntry } from '@ionic-native/file/ngx';
import { Zip } from '@ionic-native/zip/ngx';

import { Observable, Subject } from 'rxjs';

import { ZipTaskProgress } from '.';
import { Logger, LoggingService } from '../logging.service';
import { ZipService } from './zip.service';

/**
 * For ios and android which uses the cordova plugin
 */
@Injectable()
export class PluginZipService extends ZipService {
    private logger: Logger;

    constructor(
        private loggingService: LoggingService,
        private zip: Zip
    ) {
        super();
        this.logger = this.loggingService.getLogger('PluginZipService');
    }

    public unzip(file: FileEntry, targetDir: DirectoryEntry): Observable<ZipTaskProgress> {
        this.logger.debug(`Will unzip ${file.toURL()} into ${targetDir.toURL()}`);
        const sub = new Subject<ZipTaskProgress>();
        const observable = sub.asObservable();
        const state: ZipTaskProgress = {
            active: true,
            current: 0,
            total: 1
        };
        sub.next(state);
        this.zip.unzip(file.toURL(), targetDir.toURL(),
            (progress) => {
                state.total = progress.total;
                state.current = progress.loaded;
                sub.next(state);
            }).then(result => {
                if (result === -1) {
                    sub.error('Failed to unzip');
                    sub.complete();
                } else {
                    state.active = false;
                    state.current = state.total;
                    this.logger.warn(`Progress ${JSON.stringify(state, null, 5)}`);
                    sub.next(state);
                    sub.complete();
                }
            }).catch(e => {
                sub.error('Failed to unzip');
                sub.complete();
                throw e;
            });

        return observable;
    }
}
