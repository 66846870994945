import { Injectable } from '@angular/core';

import { Administrable, Subtest } from '@app/models';
import { AssessEvents, ContentQuery } from '@app/shared';
import { Message, MessageTypes } from '@app/shared/message-types';

import { Events } from './events.service';
import { DeviceConnectivityService } from './device-connectivity.service';
import { Logger, LoggingService } from './logging.service';
import { BaseModel } from '@app/behaviors/base.model';

/**
 * Messaging service for practitioner and client devices.
 */
@Injectable()
export class MessagingService {

    logger: Logger;

    constructor(
        private connectivity: DeviceConnectivityService,
        private loggingService: LoggingService,
        private events: Events
    ) {
        this.logger = this.loggingService.getLogger('MessagingService');

        this.connectivity.onReceiveData((data) => this.receivedMessage(data));
    }

    receivedMessage(data) {
        try {
            const msg: Message = JSON.parse(data);
            this.events.publish(msg.type, msg.payload, msg.uniqueId);
        } catch (e) {
            this.logger.error('Error parsing message.', e);
        }
        // send generic event that a message has been received
        this.events.publish(AssessEvents.messageReceived);
    }

    async sendMessage(message: Message): Promise<void> {
        const msg = JSON.stringify(message);
        return await this.connectivity.sendData(msg);
    }

    async showStim(item: Administrable, model: BaseModel): Promise<void> {
        const subtest: Subtest = ContentQuery.ancestorOfType(item, 'subtest');
        const payload = {
            model: model.serializeForSave(),
            patientAge: subtest.battery.getPatientAge().year,
            questionId: item.id,
            questionType: item.predefinedBehavior.type,
            subtest: subtest.title,
            subtestInstanceId: subtest.subtestInstanceID,
            test: subtest.testTitle
        };
        return await this.sendMessage({
            type: MessageTypes.SHOW_STIM,
            payload,
            uniqueId: item.getUniqueId()
        });
    }

    async hideStim(): Promise<void> {
        AssessEvents.stimHidden.next(true);
        return await this.sendMessage({ type: MessageTypes.HIDE_STIM });
    }
}
