import { Administrable } from '@app/models';
import { ItemTypes } from '@app/models/consts';
import { ContentQuery } from '@app/shared';

import { IBaseRule, INavResult, SubtestItem } from '..';

export class SkipSubsequentTrialsRule implements IBaseRule {

    skipToItem: Administrable;

    constructor() { }

    async navigate(item: SubtestItem): Promise<INavResult> {
        if (this.doesRuleApplyToItem(item)) {
            item = item as Administrable;
            if (item.model.getMaxScore() === item.rawScore.value) {
                // skip the subsequent trials
                const allTrials = item.parent.trials;
                allTrials.forEach((t) => {
                    if (!t.wasAdministered.value) {
                        t.wasSkipped.next(true);
                        t.wasAdministered.next(true);
                        t.rawScore.next(0);
                    }
                });
                const subtest = ContentQuery.ancestorOfType(item, ItemTypes.subtest);
                const flatSubtest = ContentQuery.flatSubtest(subtest);
                // find the next unadministered item that wasn't skipped
                this.skipToItem = flatSubtest.find(it => it.isAdministrable && !it.wasAdministered.value && !it.wasSkipped.value);
                return { destId: this.skipToItem.id };
            }
        }
        return null;
    }

    priority(): number {
        return 2;
    }

    handleSwipeBack(currentItem: SubtestItem, prevItem: SubtestItem) {
        if (this.skipToItem === currentItem) {
            // we navigated back from the item we skipped, so unset all the things
            const allTrials = (prevItem as Administrable).parent.trials;
            allTrials.forEach((t) => {
                t.wasSkipped.next(false);
                t.wasAdministered.next(false);
                t.rawScore.next(null);
            });
            this.skipToItem = null;
        }
    }

    private doesRuleApplyToItem(item: SubtestItem) {
        if (item.itemType === ItemTypes.trial) {
            const allTrials = (item as Administrable).parent.trials;
            // we don't care if it's the last item in the set of trials
            return allTrials[allTrials.length - 1] !== item;
        }
        return false;
    }
}
