import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';

import { Observable } from 'rxjs';

/**
 * Sourced from Central and AssessV1 i18n keys
 */
const DEFAULT_LANG_MAP: any = {
    'da-DK': 'da',
    'de-DE': 'de',
    'en-US': 'en',
    'fr-FR': 'fr',
    'nb-NO': 'nb',
    'nl-NL': 'nl',
    'sv-SE': 'sv'
};

/**
 * Resolves lang based on defaults instead of us having to maintain duplicates.
 * For eg, 'en' i18n string are always a copy of en-US , 'fr' is a copy of 'fr-FR'.
 * Inspired from
 * github.com/ngx-translate/http-loader/blob/master/projects/ngx-translate/http-loader/src/lib/http-loader.ts
 */
export class DefaultLangHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient, public prefix: string = '/assets/i18n/',
        public suffix: string = '.json') { }

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<Object> {
        const resolvedLang: string = DEFAULT_LANG_MAP[lang] || lang;
        return this.http.get(`${this.prefix}${resolvedLang}${this.suffix}`);
    }
}
