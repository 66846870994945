import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { LoggingService, PlatformService } from '@app/core/providers';
import { environment } from '@appenv';

import { ModalController } from '@ionic/angular';
import { Pro } from '@ionic/pro';

@Component({
    selector: 'console-log-modal',
    templateUrl: './console-log.modal.html',
    styleUrls: ['./console-log.modal.scss'],
})
export class ConsoleLogModal implements OnInit {

    logs: SafeHtml;
    appVersion: string;
    binaryVersion: string;

    @ViewChild('logContainer') logsContainer: ElementRef;

    constructor(
        private modalController: ModalController,
        private loggingService: LoggingService,
        private sanitizer: DomSanitizer,
        private platform: PlatformService,
        private renderer: Renderer2,
        private el: ElementRef
    ) { }

    async ngOnInit() {
        const logEntries = `<pre style="white-space:pre-line"> ${await this.loggingService.getConsoleLog()} </pre>`;
        this.logs = this.sanitizer.bypassSecurityTrustHtml(logEntries);
        if (this.platform.isNative()) {
            this.appVersion = await Pro.getApp().getSnapshotId();
            this.binaryVersion = Pro.getApp().version;
        } else {
            this.appVersion = environment.version;
        }
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    async copyLogs() {
        const el = this.logsContainer.nativeElement;
        const tempText = this.renderer.createElement('textarea');
        const text = this.renderer.createText(el.innerText);
        this.renderer.appendChild(tempText, text);
        this.renderer.appendChild(this.el.nativeElement, tempText);

        if (this.platform.is('ios')) {
            const selection = window.getSelection();
            selection.removeAllRanges();
            tempText.setSelectionRange(0, 999999999);
        } else {
            tempText.select();
        }
        document.execCommand('copy');
        this.renderer.removeChild(this.el.nativeElement, tempText);
    }

    async backupAndClearLogs() {
        this.logs = '';
        await this.loggingService.clearConsoleLog();
    }
}
