import { Injectable } from '@angular/core';

import { Observable, Subscription, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class Events {
    private c = new Map<string, any>();

    constructor() {
        // only for dev debug to fire events via dev console
        window['ASSESS_EVENTS'] = this;
    }

    observe(topic: string): Observable<any> {
        let topics = this.c.get(topic);
        const subject = new Subject<any>();
        if (!topics) {
            this.c.set(topic, topics = subject);
        }
        return subject.asObservable();
    }

    subscribe(topic: string, handler: Function): Subscription {
        let topics = this.c.get(topic);
        if (!topics) {
            this.c.set(topic, topics = new Subject<any>());
        }
        return topics.subscribe((args: any[]) => handler.apply(null, args));
    }

    subscribeAll(allTopics: Array<string>, handler: Function): void {
        allTopics.forEach((topic) => {
            let topics = this.c.get(topic);
            if (!topics) {
                this.c.set(topic, topics = new Subject<any>());
            }
            topics.subscribe((args: any[]) => handler.apply(null, args));
        });
    }

    publish(topic: string, ...args: any[]): void {
        const subject = this.c.get(topic);
        if (subject) {
            subject.next(args);
        }
    }
}
