<div class="battery-status">
    <ion-grid>
        <ion-row class="status-row">
            <ion-col size="4">
                <ion-item lines="none">
                    <ion-icon name="cog-outline" size="large"></ion-icon>
                    <ion-label>
                        {{status}}
                    </ion-label>
                </ion-item>
            </ion-col>
            <ion-col size="4">
                <ion-item lines="none">
                    <ion-icon name="apps-outline" size="large"></ion-icon>
                    <ion-label>{{numSubtests}} {{'components.batteryStatus.label.subtests' | translate}}
                    </ion-label>
                </ion-item>
            </ion-col>
            <ion-col size="4">
                <ion-item lines="none">
                    <ion-icon name="time-outline" size="large"></ion-icon>
                    <ion-label>{{duration}} min</ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>