import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HTTP_HEADERS } from '@app/models/consts';
import { SyncEvents } from '@app/shared';
import { environment } from '@appenv';

import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Logger, LoggingService } from './logging.service';
import { PlatformService } from './platform.service';

export interface Request {
    url: string;
    options?: any;
    clearCookies?: boolean;
    cancelSubject?: BehaviorSubject<boolean>;
}

export interface PostRequest extends Request {
    data: any;
}

export interface UploadFileRequest extends Request {
    data: any;
    filePath: string;
    name: string;
}

@Injectable()
export class HttpService {

    private sessionExpiredSub = new Subject<void>();
    public sessionExpired: Observable<void> = this.sessionExpiredSub.asObservable();
    private logger: Logger;

    constructor(
        private nativeHttp: HTTP,
        private webHttp: HttpClient,
        private platform: PlatformService,
        private loggingService: LoggingService
    ) {
        this.logger = this.loggingService.getLogger(HttpService.name);
        if (this.platform.isNative()) {
            // sets a header for all future native requests
            this.nativeHttp.setRequestTimeout(environment.slowHttpTimeout);
            this.nativeHttp.setHeader(`${environment.centralEndpoint}`, 'X-Requested-With', HTTP_HEADERS['X-Requested-With']);
        }
    }

    public async get(req: string | Request, options?: any, clearCookies: boolean = false): Promise<any> {
        let url;
        let cancelSubject: BehaviorSubject<boolean>;
        if (typeof req === 'string') {
            url = req;
        } else {
            url = req.url;
            clearCookies = !!req.clearCookies;
            options = req.options;
            cancelSubject = req.cancelSubject;
        }
        cancelSubject = cancelSubject || new BehaviorSubject(null);
        if (this.platform.isNative() && url.startsWith('http')) {
            if (clearCookies) {
                await this.removeCookie(url);
            }
            try {
                const response: HTTPResponse = await this.nativeHttp.get(
                    url,
                    this.parseParamsForNativeHttp(options),
                    this.parseHeadersForNativeHttp(options)
                );
                if (cancelSubject.value || (SyncEvents.resetLogin.value && !clearCookies)) {
                    this.logger.warn(`Http get cancelled by subject for ${url}`);
                    throw new Error('Request cancelled');
                }
                return await this.toPlainResponse(response);
            } catch (e) {
                if (!(cancelSubject.value || (SyncEvents.resetLogin.value && !clearCookies)) && e.error && e.status === 401) {
                    this.logger.error(`Http get 401 stat for url ${url}`);
                    this.sessionExpiredSub.next();
                }
                throw e;
            }
        }

        return await this.webHttp.get(url, this.parseOptionsForWebHttp(options)).toPromise();
    }

    public async post(req: string | PostRequest, data: any = {}, options?: any, clearCookies: boolean = false): Promise<any> {
        let url;
        let cancelSubject: BehaviorSubject<boolean>;
        if (typeof req === 'string') {
            url = req;
        } else {
            url = req.url;
            clearCookies = !!req.clearCookies;
            options = req.options;
            data = req.data || {};
            cancelSubject = req.cancelSubject;
        }
        cancelSubject = cancelSubject || new BehaviorSubject(null);
        if (this.platform.isNative()) {
            if (clearCookies) {
                this.removeCookie(url);
            }
            const responseType = options ? options.responseType || 'json' : 'json';
            if (data !== null && Object.keys(data).length > 0) {
                // we can safely assume that we are sending json
                this.nativeHttp.setDataSerializer('json');
            }
            try {
                const response: HTTPResponse = await this.nativeHttp.sendRequest(url, {
                    method: 'post',
                    params: (options && options['params']) || {},
                    data: data,
                    responseType,
                    headers: this.parseHeadersForNativeHttp(options)
                });

                if (cancelSubject.value || (SyncEvents.resetLogin.value && !clearCookies)) {
                    this.logger.warn(`Http post cancelled by subject for ${url}`);
                    throw new Error('Request cancelled');
                }
                return await this.toPlainResponse(response, responseType);
            } catch (e) {
                if (!(cancelSubject.value || (SyncEvents.resetLogin.value && !clearCookies)) && e.error && e.status === 401) {
                    this.logger.error(`Http post 401 stat for url ${url}`);
                    this.sessionExpiredSub.next();
                }
                throw e;
            } finally {
                // reset any data serialization overrides
                this.nativeHttp.setDataSerializer('urlencoded');
            }
        }

        return await this.webHttp.post(url, data, this.parseOptionsForWebHttp(options)).toPromise();
    }

    public async uploadFile(req: string | UploadFileRequest, data: any = {}, filePath: string = null, name: string = null, options?: any) {
        let url;
        let cancelSubject: BehaviorSubject<boolean>;
        if (typeof req === 'string') {
            url = req;
        } else {
            url = req.url;
            filePath = req.filePath;
            name = req.name;
            options = req.options;
            data = req.data || {};
            cancelSubject = req.cancelSubject;
        }
        cancelSubject = cancelSubject || new BehaviorSubject(null);
        if (this.platform.isNative()) {
            try {
                const response = await this.nativeHttp.uploadFile(url, data, this.parseHeadersForNativeHttp(options), filePath, name);
                if (cancelSubject.value || SyncEvents.resetLogin.value) {
                    this.logger.warn(`Http upload file cancelled by subject for ${url}`);
                    throw new Error('Request cancelled');
                }
                return await this.toPlainResponse(response);
            } catch (e) {
                if (!(cancelSubject.value || SyncEvents.resetLogin.value) && e.error && e.status === 401) {
                    this.logger.error(`Http uploadfile 401 stat for url ${url}`);
                    this.sessionExpiredSub.next();
                }
                throw e;
            }
        }

        return await this.webHttp.post(url, data, this.parseOptionsForWebHttp(options)).toPromise();
    }

    /**
     * Sends an `HTTPRequest` and returns a stream of `HTTPEvents`.
     *
     * @return An `Observable` of the response, with the response body as a stream of `HTTPEvents`.
     */
    public request<R>(req: HttpRequest<any>): Observable<HttpEvent<R>> {
        return this.webHttp.request(req);
    }

    private parseParamsForNativeHttp(options) {
        return options && options.params ? options.params : {};
    }

    private parseHeadersForNativeHttp(options) {
        return options && options.headers ? options.headers : {};
    }

    private parseOptionsForWebHttp(options) {
        const theOptions = options || {};
        return {
            withCredentials: true,
            headers: {
                ...theOptions.headers,
                ...HTTP_HEADERS
            },
            params: {
                ...theOptions.params
            },
            responseType: theOptions.responseType || 'json'
        };
    }

    private toPlainResponse(response: HTTPResponse, responseType = 'json') {
        return new Promise<any>((res, rej) => {
            if (response.error) {
                rej(response.error);
            } else {
                try {
                    res(responseType === 'json' ? JSON.parse(response.data) : response.data);
                } catch (e) {
                    res(response.data);
                }
            }
        });
    }

    private async removeCookie(url: string): Promise<void> {
        await new Promise<void>((res) => {
            this.nativeHttp.removeCookies(url, () => {
                // this has the effect of resetting everything
                this.nativeHttp.setCookie(url, `jsessionid=-1`);
                res();
            });
        });
    }
}
