import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AuthModule } from '@app/auth/auth.module';
import { DefaultLangHttpLoader, SharedModule } from '@app/shared';

import { IonicModule } from '@ionic/angular';
import { Pro } from '@ionic/pro';
import { IonicStorageModule } from '@ionic/storage';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { getNavAnimation } from './app.animations';
import { AppRoutingModule } from './app-routing.module';
import { ControlsComponent } from './components/remote-session/controls/controls.component';
import { RemoteSessionComponent } from './components/remote-session/remote-session.component';
import { VideoPlayerComponent } from './components/remote-session/video-player/video-player.component';
import { CoreModule } from './core';
import { TapEventPlugin } from './core/providers/tap.event.plugin';
import { environment } from '../environments/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new DefaultLangHttpLoader(http, './assets/i18n/');
}
const origConsoleLog = console.log;
window['initLogs'] = [];
window['origConsoleLog'] = origConsoleLog;
console.log = (...args: any) => {
    window['initLogs'].push(args);
};
Pro.init('153aa61f', {
    appVersion: environment.version
});

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DragDropModule,
        HttpClientModule,
        IonicModule.forRoot({
            navAnimation: getNavAnimation
        }),
        IonicStorageModule.forRoot({
            name: 'assess.db',
            driverOrder: ['sqlite', 'indexeddb', 'websql']
        }),
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: false
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            useDefaultLang: true
        }),
        CoreModule,
        AuthModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: false })
    ],
    exports: [SharedModule],
    declarations: [AppComponent, RemoteSessionComponent, VideoPlayerComponent, ControlsComponent],
    providers: [InAppBrowser, SplashScreen, StatusBar, HTTP, File, FirebaseAnalytics,
        {
            provide: EVENT_MANAGER_PLUGINS,
            useClass: TapEventPlugin,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
