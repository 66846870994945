import { Injectable } from '@angular/core';

import { LoginUserInfo, OfflineLoginRepo, OfflineLoginState } from '@app/models';

import { Storage } from '@ionic/storage';

import { EncryptDecryptService } from './encryption.service';
import { Logger, LoggingService } from './logging.service';

const OFFLINE_STORE_KEY = `offline_user_store`;

/**
 * Provides crud for user info for offline logins. USe ionic storage as the repo.
 * The structure of the repo is a JSON object which looks like:-
 * <code>
 *  {
 *      "userName1": {
 *          "loginInfo": Json rep of LoginUserInfo,
 *          "passwordHash": pwdHash
 *      },
 *      "userName2": ....
 *  }
 * </code>
 */
@Injectable()
export class OfflineLoginStoreService {
    private logger: Logger;
    constructor(private storage: Storage, private loggingService: LoggingService, private encryptionService: EncryptDecryptService) {
        this.logger = this.loggingService.getLogger('OfflineLoginStoreService');
    }

    /**
     * Adds the creds into the offline store. Replaces if already there.
     * @param username: string - the username
     * @param loginInfo: LoginUserInfo - the online authentication result
     * @param password: string - the password used for the successful online authentication
     */
    public async addOrUpdateLoginInfo(username: string, loginInfo: LoginUserInfo, password: string): Promise<void> {
        this.logger.debug(`Adding user ${username}'s info to offline user store`);
        const offlineRepo = await this.resolveRepo();
        const passwordHash = await this.encryptionService.encrypt(password);
        offlineRepo[username] = {
            loginInfo, passwordHash
        };
        await this.storage.set(OFFLINE_STORE_KEY, JSON.stringify(offlineRepo));
    }

    /**
     * Returns the logged in user info from the offline user repo. If not found returns null
     * @param username: string
     */
    public async getUserInfo(username: string): Promise<OfflineLoginState> {
        const offlineRepo = await this.resolveRepo();
        return offlineRepo[username];
    }

    /**
     * Checks whether the creds in the store matches whats being passed
     * @param offlineState : OfflineLoginState
     * @param password : string
     */
    public async isValidCredentials(offlineState: OfflineLoginState, password: string): Promise<boolean> {
        this.logger.debug(`Checking if the offline user credentials are matching`);
        return await this.encryptionService.decrypt(offlineState.passwordHash) === password;
    }

    // Removes creds for a user
    public async removeCredentials(username: string): Promise<void> {
        const offlineRepo = await this.resolveRepo();
        if (offlineRepo && offlineRepo[username]) {
            delete offlineRepo[username];
            await this.storage.set(OFFLINE_STORE_KEY, JSON.stringify(offlineRepo));
        }
    }

    private async resolveRepo(): Promise<OfflineLoginRepo> {
        const offlineRepoStr: string = await this.storage.get(OFFLINE_STORE_KEY);
        let offlineRepo: OfflineLoginRepo;
        if (!offlineRepoStr) {
            offlineRepo = {};
        } else {
            offlineRepo = JSON.parse(offlineRepoStr);
        }
        return offlineRepo;
    }
}
