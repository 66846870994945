import { Injectable } from '@angular/core';

import { AssessEvents } from '@app/shared';

import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';

import { Events } from './events.service';
import { Logger, LoggingService } from './logging.service';


@Injectable()
export class PlatformService {

    private logger: Logger;
    private cachedAppMode: string;
    constructor(
        private platform: Platform,
        private appPreferences: AppPreferences,
        private loggingService: LoggingService,
        private events: Events
    ) {
        this.logger = this.loggingService.getLogger('PlatformService');
        this.initEvents();
    }

    public isNative() {
        return (!this.platform.is('desktop') && !this.platform.is('mobileweb'));
    }

    public is(platformName: Platforms) {
        return this.platform.is(platformName);
    }

    public async isPractitionerMode() {
        if (!this.isNative()) {
            return !window.location.href.includes('stim/');
        }
        if (!this.cachedAppMode) {
            this.cachedAppMode = await this.appPreferences.fetch('application_mode_preference');
        }
        return this.cachedAppMode === 'practitioner';
    }

    public async shouldShowConsoleOnStim() {
        if (!this.isNative()) {
            return false;
        }
        const pref = await this.appPreferences.fetch('show_console_preference');
        return pref && !await this.isPractitionerMode();
    }

    public async isExternalAuthentication() {
        if (!this.isNative()) {
            return false;
        }
        const pref = await this.appPreferences.fetch('enable_field_research_preference');
        return pref;
    }

    public async overrideTimeout() {
        if (!this.isNative()) {
            return false;
        }
        const pref = await this.appPreferences.fetch('enable_override_timeout');
        return pref;
    }

    initEvents() {
        if (this.isNative()) {
            this.events.subscribe(AssessEvents.logout, async () => {
                this.cachedAppMode = null;
            });
        }
    }
}
