import { Injectable } from '@angular/core';

import { environment } from '@appenv';

import { Pro } from '@ionic/pro';

import { CheckForUpdateResponse, IAppInfo } from 'cordova-plugin-ionic/dist/IonicCordova';

import { HttpService } from './http.service';
import { LoggingService, Logger } from './logging.service';
import { NetworkStatusService } from './network-status.service';

@Injectable()
export class DeployService {

    logger: Logger;

    constructor(
        private loggingService: LoggingService,
        private httpService: HttpService,
        private networkStatusService: NetworkStatusService
    ) {
        this.logger = this.loggingService.getLogger('DeployService');
    }

    async checkForUpdates(): Promise<boolean> {
        const updateInfo = await this.checkForUpdate();
        if (updateInfo && updateInfo.available) {
            this.logger.info(`App update is available: ${updateInfo.build}.  Downloading...`);
            await Pro.deploy.downloadUpdate();
            this.logger.info(`Extracting update ${updateInfo.build}.`);
            await Pro.deploy.extractUpdate();
            this.logger.info(`Reloading app.`);
            return await Pro.deploy.reloadApp();
        }
        return false;
    }

    async getChannelName(): Promise<string> {
        return await Pro.getApp().getChannel();
    }

    /**
     * Inspired from https://github.com/ionic-team/cordova-plugin-ionic/blob/master/www/common.ts#L158
     * all because of this https://github.com/ionic-team/cordova-plugin-ionic/blob/master/www/common.ts#L185
     */
    private async checkForUpdate(): Promise<CheckForUpdateResponse> {
        if (!this.networkStatusService.isConnected() || (Pro.deploy as any).disabled) {
            return null;
        }

        const plugin: any = await Pro.getApp().getPlugin();
        const delegate = await plugin.deploy.delegate;
        const appInfo: IAppInfo = await plugin.getAppDetails();
        const prefs = delegate._savedPreferences;
        const endpoint = `${prefs.host}/apps/${prefs.appId}/channels/check-device`;
        const device_details = {
            binary_version: prefs.binaryVersionName,
            device_id: appInfo.device || null,
            platform: appInfo.platform,
            platform_version: appInfo.platformVersion,
            snapshot: prefs.currentVersionId,
            build: prefs.currentBuildId
        };
        const body = {
            channel_name: prefs.channel,
            app_id: prefs.appId,
            device: device_details,
            plugin_version: environment.ionicPluginVersion,
            manifest: true
        };

        this.logger.info(`Checking IONIC updates with ${JSON.stringify(body, null, 5)}`);
        const result: any = await this.httpService.post(endpoint, body, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        this.logger.success(`Got IONIC check update result ${JSON.stringify(result, null, 5)}`);

        const checkForUpdateResp: CheckForUpdateResponse = result ? result.data : {};
        if (checkForUpdateResp.available && checkForUpdateResp.url && checkForUpdateResp.snapshot && checkForUpdateResp.build) {
            prefs.availableUpdate = {
                binaryVersionCode: prefs.binaryVersionCode,
                binaryVersionName: prefs.binaryVersionName,
                channel: prefs.channel,
                state: 'available',
                lastUsed: new Date().toISOString(),
                url: checkForUpdateResp.url,
                versionId: checkForUpdateResp.snapshot,
                buildId: checkForUpdateResp.build
            };
            await this.savePreferences(prefs, delegate);
            this.logger.success(`Updating PRO Preferences from updates ${JSON.stringify(prefs, null, 5)}`);
        }
        return checkForUpdateResp;
    }

    private async savePreferences(prefs: any, delegate: any): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                delegate._savedPreferences = prefs;
                cordova.exec(async (_savedPrefs: any) => {
                    resolve();
                }, reject, 'IonicCordovaCommon', 'setPreferences', [prefs]);
            } catch (e) {
                reject(e.message);
            }
        });
    }
}
