export interface IMfaType {
    communicatorAddress?: string;
    expirationTimeInMillis?: number;
    mfaTypeKey: string;
    orderOfPreference: number;
}

export interface IMfaDetails {
    mfaVerified: boolean;
    codeEntryFldName?: string;
    mfaTypes?: IMfaType[];
    postUrl?: string;
    rememberMeFldName?: string;
    typeFldName?: string;
    reAuthTimeout?: number;
}

export interface LoginUserInfo {
    userName: string;
    userId: string;
    eligibleSubtestGUIDs: string[];
    isOffline?: boolean;
    signInId?: string;
}

export enum LoginFailureReason {
    ONLINE_UNAUTHORISED,
    INCORRECT_OFFLINE_CREDS,
    OFFLINE_CREDS_NOT_FOUND,
    UNKNOWN
}

export const LoginConstants = {
    HAS_LOGGED_IN: 'hasLoggedIn'
};

export interface OfflineLoginState {
    loginInfo: LoginUserInfo;
    passwordHash: string;
}

export interface OfflineLoginRepo {
    [k: string]: OfflineLoginState;
}
