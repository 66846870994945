<ion-header>
    <ion-toolbar color="accent" mode="ios">
        <ion-title>{{ 'components.consoleLog.title' | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button size="small" icon-only (click)="closeModal()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <div class="logs" [innerHtml]="logs" #logContainer></div>
</ion-content>

<ion-footer>
    <ion-toolbar color="medium">
        <ion-label class="ion-padding">{{ 'components.consoleLog.version' | translate }} {{ binaryVersion }}
            {{ appVersion }}
        </ion-label>
        <ion-buttons class="console-log-buttons" slot="end">
            <ion-button size="large" icon-only (click)="copyLogs()">
                <ion-icon name="copy"></ion-icon>
                {{ 'components.consoleLog.buttons.copy' | translate }}
            </ion-button>
            <ion-button size="large" icon-only (click)="backupAndClearLogs()">
                <ion-icon name="cut"></ion-icon>
                {{ 'components.consoleLog.buttons.backup' | translate }}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>