<div class="remote-wrapper">
    <span class="grabber" [ngStyle]="{'display': isPractitioner ? 'flex' : 'none'}">
        <ion-icon name="move"></ion-icon>
    </span>
    <ion-grid class="grid-container">
        <ion-row class="status-row">
            <ion-col>
                <p class="status-text">{{isConnected()}}</p>
                <div class="call-wrapper" *ngIf="isPractitioner">
                    <ion-button *ngIf="!rms.isCallActive()" class="call-btn" color="success" type="icon-only"
                        fill="solid" (click)="callPeer()">
                        <ion-icon name="videocam-outline"></ion-icon>
                    </ion-button>
                    <ion-button *ngIf="rms.isCallActive()" class="call-btn" color="danger" type="icon-only" fill="solid"
                        (click)="rms.disconnectCall()">
                        <ion-icon name="videocam-outline"></ion-icon>
                    </ion-button>
                </div>
            </ion-col>
        </ion-row>
        <ion-row class="video-row">
            <ion-col>
                <p>Examiner</p>
                <video-player class="video-player" #host
                    [stream]="isPractitioner ? this.localStream : this.remoteStream" [isLocal]="isPractitioner">
                </video-player>
            </ion-col>
        </ion-row>
        <ion-row class="video-row">
            <ion-col>
                <p>Client</p>
                <video-player class="video-player" #client
                    [stream]="isPractitioner ? this.remoteStream : this.localStream" [isLocal]="!isPractitioner">
                </video-player>
            </ion-col>
        </ion-row>
        <div class="status-row call-button-row" *ngIf="!isPractitioner && isCallIncoming">
            <p class="status-text">Incoming call...</p>
            <div class="call-wrapper">
                <ion-button class="call-btn" color="danger" type="icon-only" fill="solid" (click)="declineCall()">
                    <ion-icon name="call-outline"></ion-icon>
                </ion-button>
                <ion-button class="call-btn margin-right" color="success" type="icon-only" fill="solid"
                    (click)="answerCall()">
                    <ion-icon name="call-outline"></ion-icon>
                </ion-button>
            </div>
        </div>
    </ion-grid>
</div>