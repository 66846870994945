import { IItemGroup } from '@app/interfaces/battery-components';
import { ItemTypes } from '@app/models/consts';
import { IBaseRule } from '@app/rules';
import { RulesFactory } from '@app/rules/rules-factory';
import { ContentQuery } from '@app/shared/utils';

import { Administrable } from './administrable';
import { Question } from './question';
import { SingleCardItemList } from './single-card-item-list';

import { SubtestRef } from '../battery/subtest-ref';

export class ItemGroup implements IItemGroup {

    itemType: string = ItemTypes.itemGroup;
    questions: Array<any> = [];
    singleCardItemLists: Array<SingleCardItemList<ItemGroup>> = [];
    discontinuedState = false;
    administrables: Array<Administrable>;
    children: Array<any>;
    baseUrl: string;
    title: string;
    parent: any;
    shouldSuppressCoverSheet: boolean;
    subtest: SubtestRef;
    id: string;
    isUsingCustomIButton: boolean;
    abbr: string;
    rules: Array<IBaseRule> = [];
    hideStimWhenLeavingItemGroup: boolean;

    constructor(json) {
        if (!json.title) {
            throw new Error('Item group has no title.');
        }

        this.title = json.title;

        if (json.singleCardItemLists && json.singleCardItemLists.length > 0) {
            this.singleCardItemLists = json.singleCardItemLists.map((itemListJson) => {
                return new SingleCardItemList<ItemGroup>(itemListJson, ItemGroup);
            });

            this.children = this.singleCardItemLists.slice();
        }

        if (json.questions && json.questions.length > 0) {
            if (this.singleCardItemLists.length > 0) {
                throw new Error('Item group contains both questions and single card item lists.');
            }

            json.questions.forEach((questionJson) => {
                this.questions.push(Question.createItem(questionJson));
            });
            this.children = this.questions.slice();
        }

        this.baseUrl = json.baseUrl;
        this.isUsingCustomIButton = json.isUsingCustomIButton || false;
        this.shouldSuppressCoverSheet = json.shouldSuppressCoverSheet;
        this.hideStimWhenLeavingItemGroup = json.hideStimWhenLeavingItemGroup;
        this.administrables = ContentQuery.administrable(this);
        this.id = `IG:${this.title}${this.questions.length}`;
        this.abbr = json.abbr;
        this.rules = RulesFactory.create(json, this);
    }

    public setParent(parent) {
        this.parent = parent;
        this.children.forEach(q => q.setParent(this));
    }

    public getDisplayName(): string {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return `${subtest.getDisplayName()} ${this.title}`;
    }

    public getTestDisplayName(): string {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return subtest.getTestDisplayName();
    }

    public getSubtestName() {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return subtest.getDisplayName();
    }

    public getUniqueId(): string {
        return this.id;
    }

    public serializeForSave() {
        const json = {
            abbr: this.abbr,
            questions: this.questions.map(q => q.serializeForSave()),
            singleCardItemLists: this.singleCardItemLists.map(l => l.serializeForSave()),
            title: this.title,
            discontinued: this.discontinuedState,
            rules: this.rules.map((rule) => rule.serializeForSave ? rule.serializeForSave() : null)
        };
        return json;
    }

    public restoreSavedData(savedState) {
        this.discontinuedState = savedState.discontinued;

        if (savedState.rules) {
            if (savedState.rules.length !== this.rules.length) {
                throw new Error(`Saved data rule and current rules length mismatch: ${savedState.rules}-${this.rules}`);
            }

            savedState.rules.forEach((r, idx) => {
                if (this.rules[idx].restoreSavedData) {
                    this.rules[idx].restoreSavedData(r);
                }
            });
        }

        if (this.questions.length) {
            this.questions.forEach((q, idx) => q.restoreSavedData(savedState.questions[idx]));
        }

        if (this.singleCardItemLists.length) {
            this.singleCardItemLists.forEach((l, idx) => l.restoreSavedData(savedState.singleCardItemLists[idx]));
        }
    }

    public unload() {
        if (this.questions.length > 0) {
            this.questions.forEach((q) => q.unload());
        }

        if (this.singleCardItemLists.length > 0) {
            this.singleCardItemLists.forEach((scil) => scil.unload());
        }
    }
}
