import { EventEmitter } from '@angular/core';

import { BaseModel } from '@app/behaviors/base.model';
import { ItemTypes, QuestionTypes, ScoreDisplayTypes } from '@app/models/consts';
import { IBaseRule } from '@app/rules';
import { ContentQuery, DeepCopy } from '@app/shared/utils';

import { BehaviorSubject, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IScoreDisplay } from '.';
import { SubtestRef } from '../battery/subtest-ref';

export class Administrable {
    isAdministrable = true;
    isDirty = false;
    baseUrl: any;
    id: any;
    itemType: string;
    parent: any;
    title: string;
    questionType: string;
    serializedState: any;
    subtest: SubtestRef;
    predefinedBehavior: any;
    savedPredefinedState: any;
    questionStimIds: Array<any> = [];
    stimPaths: Array<any> = [];
    startTime: number;
    endTime: number;
    isTeachingItem: boolean;
    // contextualEvents attributes
    contextualEvents: Array<any> = [];
    contextualEventButtons: any = {};

    scoreDisplay: IScoreDisplay;
    enteredRawScore: number | string;

    rawScore = new BehaviorSubject<number>(null);
    isShowInstruction: boolean;
    isWritingRequired: boolean;
    usesStims: boolean;
    appWasBackgrounded: boolean;

    // timer attrs from json
    shouldUseTimer: boolean;
    elapsedTime: number;
    warningTime: number;
    maxTime: number;
    shouldStopTimerOnHidden = true;

    wasAdministered: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isManualDiscontinuePoint: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isStartingPoint: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isReversePoint: BehaviorSubject<boolean> = new BehaviorSubject(false);
    wasSkipped: BehaviorSubject<boolean> = new BehaviorSubject(false);
    wasSkippedForStartingPoint: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isDiscontinuePoint: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // to control forward and backward navs based on pdb
    public shouldSwipeBack: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public shouldSwipeForward: BehaviorSubject<boolean> = new BehaviorSubject(true);

    // Used to indicate a BT disconnect on this question.  Disables the todo badge for an unscored test items.
    public wasInterrupted: BehaviorSubject<boolean> = new BehaviorSubject(false);

    rules: Array<IBaseRule> = [];
    topInstructionsHTML: string;
    correctInstructionsHTML: string;
    incorrectInstructionsHTML: string;

    hideLeftCol = false;

    reset: EventEmitter<boolean> = new EventEmitter<boolean>();
    model: BaseModel;

    private unloaded = new Subject<void>();

    constructor(public json: any, itemType: string = ItemTypes.administrable) {
        DeepCopy.copy(json, this);

        if (!(this.predefinedBehavior && this.predefinedBehavior.data && this.predefinedBehavior.type)) {
            throw new Error(`Item id ${json.id} doesn't have a predefined behavior.`);
        }

        if (this.baseUrl && !this.baseUrl.endsWith('/')) {
            this.baseUrl += '/';
        }

        this.itemType = itemType;
        this.title = json.title;
        this.isShowInstruction = json.isShowInstruction;
        this.isWritingRequired = json.isWritingRequired;
        this.usesStims = json.usesStims;
        this.topInstructionsHTML = json.topInstructionsHTML || null;
        this.correctInstructionsHTML = json.correctInstructionsHTML || null;
        this.incorrectInstructionsHTML = json.incorrectInstructionsHTML || null;
        this.contextualEvents = json.contextualEventBtn;

        // mark the question as dirty any time the score changes
        this.rawScore.asObservable().subscribe((val) => {
            this.isDirty = true;

            // TODO: fix this temp hack for scoreDisplay in central
            if (val == null) {
                this.scoreDisplay = {
                    displayType: ScoreDisplayTypes.hide,
                    score: val
                };
            } else if (this.questionType === QuestionTypes.sample || this.questionType === QuestionTypes.demo) {
                if (val > 0) {
                    this.scoreDisplay = {
                        displayType: ScoreDisplayTypes.showLetteredScore,
                        score: 'C'
                    };
                } else if (val <= 0) {
                    this.scoreDisplay = {
                        displayType: ScoreDisplayTypes.showLetteredScore,
                        score: 'I'
                    };
                }
            } else {
                this.scoreDisplay = {
                    displayType: ScoreDisplayTypes.showScore,
                    score: val
                };
            }
        });

        // listen for changes
        merge(
            this.isManualDiscontinuePoint.asObservable(),
            this.isDiscontinuePoint.asObservable(),
            this.isStartingPoint.asObservable(),
            this.isReversePoint.asObservable(),
            this.wasSkipped.asObservable(),
            this.wasAdministered.asObservable()
        ).pipe(takeUntil(this.unloaded)).subscribe(() => this.isDirty = true);
        delete this.json;
    }

    setParent(parent) {
        this.parent = parent;
    }

    getSubtestBaseUrl() {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return subtest.getBaseUrl();
    }

    getSubtestName() {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return subtest.getDisplayName();
    }

    getTestDisplayName() {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return subtest.getTestDisplayName();
    }

    getTestTitle() {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return subtest.getTestTitle();
    }

    getDisplayName(): string {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return `${subtest.getDisplayName()} ${this.title}`;
    }

    getUniqueId() {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return `${this.id}:SID:${subtest.getUniqueId()}`;
    }

    getSubtestContextualEvents() {
        const subtest = this.subtest || ContentQuery.ancestorOfType(this, ItemTypes.subtest);
        return subtest.getContextualEvents();
    }

    getStimPathsForItem() {
        const st = ContentQuery.ancestorOfType(this, 'subtest');
        const paths = [];
        for (let i = 0; i < this.questionStimIds.length; i++) {
            for (let j = 0; j < st.subtestStims.length; j++) {
                if (this.questionStimIds[i] === st.subtestStims[j].id) {
                    paths.push(st.subtestStims[j].filepath);
                }
            }
        }
        return paths;
    }

    getSVGStimPath() {
        return `battery/stims/${this.getTestTitle().toLowerCase()}/${this.id}-0.svg`;
    }

    getElapsedTime(): number {
        return this.elapsedTime || 0;
    }

    setElapsedTime(time: number) {
        this.elapsedTime = time;
        this.setIsDirty();
    }

    getStartTime() {
        return this.startTime;
    }

    setStartTime(startTime: number) {
        this.startTime = startTime;
        this.setIsDirty();
    }

    getEndTime() {
        return this.endTime;
    }

    setEndTime(startTime: number) {
        this.endTime = startTime;
        this.setIsDirty();
    }

    getWasSkippedForStartingPoint() {
        return this.wasSkippedForStartingPoint.value;
    }

    setIsDirty() {
        this.isDirty = true;
    }
    getBaseUrl() {
        return this.parent && this.parent.trials
            ? this.getSubtestBaseUrl() + '/' + this.id
            : this.baseUrl;
    }

    initContextualEventButton(button) {
        if (!this.contextualEventButtons[button.name]) {
            this.contextualEventButtons[button.name] = button;
        }
    }

    getContextualEventButtonState(button) {
        if (this.contextualEventButtons[button.name]) {
            return this.contextualEventButtons[button.name];
        }
    }

    isFirstTrial() {
        return this.itemType === ItemTypes.trial && this.parent.children[0].id === this.id;
    }

    serializeForSave() {
        if (this.isDirty) {
            const data = this.model ? this.model.serializeForSave() : this.savedPredefinedState;

            this.serializedState = {
                id: this.id,
                type: this.predefinedBehavior && this.predefinedBehavior.type,
                questionType: this.questionType,
                enteredRawScore: this.rawScore.value,
                wasAdministered: this.wasAdministered.value,
                stimPaths: this.stimPaths,
                rawScore: this.rawScore.value,
                title: this.title,
                isManualDiscontinuePoint: this.isManualDiscontinuePoint.value,
                isDiscontinuePoint: this.isDiscontinuePoint.value,
                isStartingPoint: this.isStartingPoint.value,
                wasSkipped: this.wasSkipped.value,
                reversedState: this.isReversePoint.value,
                derivedState: {
                    scoreDisplay: this.scoreDisplay
                },
                predefinedBehavior: {
                    type: this.predefinedBehavior.type,
                    data
                },
                completionTime: this.elapsedTime,
                maxTime: this.shouldUseTimer ? this.maxTime : null,
                startTime: this.getStartTime(),
                endTime: this.getEndTime(),
                contextualEventButtons: this.wasAdministered.value ? this.contextualEventButtons : {}
            };
            if (this.wasInterrupted.value) {
                // only save for items that set it
                this.serializedState.wasInterrupted = true;
            }
            if (this.appWasBackgrounded) {
                // only set this flag if it's true; otherwise we don't care
                this.serializedState.appWasBackgrounded = true;
            }
            if (this.hideLeftCol) {
                this.serializedState.hideLeftCol = this.hideLeftCol;
            }
            this.isDirty = false;
        }

        return this.serializedState;
    }

    restoreSavedData(savedState) {
        if (this.id !== savedState.id) {
            throw new Error(`Tried to restore state of item ${savedState.id} to item ${this.id}.`);
        }
        this.stimPaths = savedState.stimPaths;
        this.wasAdministered.next(savedState.wasAdministered);
        this.isManualDiscontinuePoint.next(savedState.isManualDiscontinuePoint);
        this.isDiscontinuePoint.next(savedState.isDiscontinuePoint);
        this.isStartingPoint.next(savedState.isStartingPoint);
        this.isReversePoint.next(savedState.reversedState);
        this.wasSkipped.next(savedState.wasSkipped);
        this.rawScore.next(savedState.rawScore);
        this.enteredRawScore = savedState.enteredRawScore;
        this.setElapsedTime(savedState.completionTime);
        if (savedState.predefinedBehavior && savedState.predefinedBehavior.data &&
            Object.keys(savedState.predefinedBehavior.data).length) {
            if (this.model) {
                this.model.restoreSavedData(savedState.predefinedBehavior.data);
            } else {
                this.savedPredefinedState = savedState.predefinedBehavior.data;
            }
        }
        this.setStartTime(savedState.startTime);
        this.setEndTime(savedState.endTime);
        if (savedState.contextualEventButtons) {
            this.contextualEventButtons = savedState.contextualEventButtons;
        }
        this.wasInterrupted.next(savedState.wasInterrupted);
        if (savedState.appWasBackgrounded) {
            this.appWasBackgrounded = true;
        }
        this.hideLeftCol = savedState.hideLeftCol;
        this.isDirty = true;
    }

    unload() {
        this.unloaded.next();
        this.unloaded.complete();
    }
}
