import { Component, Input, OnInit } from '@angular/core';

import { Subtest } from '@app/models';
import { IStartPointRule } from '@app/rules/reverse';
import { ContentQuery } from '@app/shared';

import { ModalController } from '@ionic/angular';

@Component({
    selector: 'start-point',
    templateUrl: './start-point.component.html',
    styleUrls: ['../overlays.scss', './start-point.component.scss'],
})
export class StartPointComponent implements OnInit {
    @Input() rule: IStartPointRule;

    subtest: Subtest;
    title: string;
    selectedItemID: string;

    constructor(private modalCtrl: ModalController) { }

    public static getCSSClass() {
        return 'start-point-modal';
    }

    ngOnInit() {
        this.subtest = ContentQuery.ancestorOfType(this.rule.noSkipItem, 'subtest');
        this.title = `${this.subtest.testDisplayName} - ${this.subtest.title}`;
    }

    getSkipToTitle() {
        return `${this.rule.skipToItem.title} (Ages ${this.rule.minAge} - ${this.rule.maxAge})`;
    }

    getNoSkipTitle() {
        return `${this.rule.noSkipItem.title} (${this.rule.noSkipMsg})`;
    }

    setSelectedOption(item) {
        this.selectedItemID = item.id;
    }

    isSelected(item) {
        return item.id === this.selectedItemID;
    }

    onDismiss() {
        this.rule.setSubtestStartPoint(this.selectedItemID);
        this.modalCtrl.dismiss({
            destId: this.selectedItemID
        });
    }
}
