import buildJson from '../environments/.build.json';

export interface IEnvironment {
    buildHost: string;
    centralEndpoint?: string;
    mode: string;
    production: boolean;
    version: string;
    ionicPluginVersion: string;
    branch: string;
    config: string;
    slowHttpTimeout: number;
    allowLangSwitcher?: boolean;
    isFr: boolean;
    wssUrl: string;
}

export const BaseEnvironment: IEnvironment = {
    branch: 'master',
    config: buildJson.config,
    buildHost: 'mars',
    mode: 'dev',
    production: false,
    version: buildJson.version,
    slowHttpTimeout: buildJson.slowHttpTimeout,
    ionicPluginVersion: buildJson.ionicPluginVersion,
    isFr: false,
    wssUrl: 'wss://9yc2l3ude2.execute-api.us-east-1.amazonaws.com/dev'
};

