import { ItemTypes } from '@app/models/consts';

import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Administrable } from './administrable';

export class Question {
    trials: Array<Administrable>;
    children: Array<Administrable>;
    itemType = ItemTypes.administrable;
    id: string;
    questionType: string;
    title: string;
    parent: any;
    rawScore = new BehaviorSubject<number>(null);

    private unloaded = new Subject<void>();

    public static createItem(json): Administrable | Question {
        if (json.trials) {
            return new Question(json);
        } else {
            return new Administrable(json);
        }
    }

    constructor(json) {
        if (json.predefinedBehavior) {
            throw new Error('Question cannot have both trials and predefinedBehavior.');
        }

        if (!json.id) {
            throw new Error('Question does not have an ID.');
        }


        this.id = json.id;
        this.questionType = json.questionType;
        this.title = json.title;
        this.trials = this.buildTrialsFromJson(json);

        this.children = this.trials;

        // the raw score of this item is the sum of the children's raw scores
        combineLatest(this.children.map(c => c.rawScore.asObservable())).pipe(takeUntil(this.unloaded)).subscribe((scores) => {
            this.rawScore.next(scores.reduce((a, b) => a + b, 0));
        });
    }

    getUniqueId() {
        return this.id;
    }

    setParent(parent) {
        this.parent = parent;
        this.trials.forEach(t => t.setParent(this));
    }

    getWasSkippedForStartingPoint() {
        return this.trials.some(t => {
            return t.getWasSkippedForStartingPoint();
        });
    }

    serializeForSave() {
        return {
            id: this.id,
            trials: this.trials.map(t => t.serializeForSave())
        };
    }

    restoreSavedData(savedState) {
        for (let i = 0; i < this.trials.length; i++) {
            this.trials[i].restoreSavedData(savedState.trials[i]);
        }
    }

    unload() {
        this.unloaded.next();
        this.unloaded.complete();

        this.trials.forEach((t) => t.unload());
    }

    private buildTrialsFromJson(json) {
        const trials = [];

        if (json.trials) {
            json.trials.forEach((t) => {
                if (json.shouldUseTimer) {
                    t.shouldUseTimer = true;
                    t.maxTime = json.maxTime;
                    t.warningTime = json.warningTime;
                }
                t.baseUrl = json.baseUrl;
                t.isShowInstruction = json.isShowInstruction;
                t.isWritingRequired = json.isWritingRequired;
                t.hideStimWhenLeavingItem = json.hideStimWhenLeavingItem;
                t.questionType = json.questionType;
                trials.push(new Administrable(t, ItemTypes.trial));
            });
        }
        return trials;
    }
}
