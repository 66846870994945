import { Injectable } from '@angular/core';
import { ITestHierarchy } from '@app/interfaces/test-hierarchy';
import { SubtestRef } from '@app/models';

import { HttpService } from './http.service';
import { Logger, LoggingService } from './logging.service';

export interface IContentResult {
    tests: Array<any>;
    subtests: Array<any>;
}

@Injectable()
export class ContentService {
    logger: Logger;

    constructor(
        private loggingService: LoggingService,
        private http: HttpService
    ) {
        this.logger = this.loggingService.getLogger('ContentService');
    }

    public async getContentForBattery(subtestRefs: SubtestRef[], testHierarchy: ITestHierarchy[]): Promise<IContentResult> {
        const testUrlBuilder = (itemRef, th) => {
            return `assets/${this.findTestInHierarchy(itemRef, th).contentPath}`;
        };
        const tests = await this.getItemsForBattery(this.buildTestRefs(subtestRefs), testHierarchy, testUrlBuilder);

        const subtestUrlBuilder = (itemRef, th) => {
            return `assets/${this.findSubtestInHierarchy(itemRef.subtestName, itemRef.testName, th).contentPath}`;
        };
        const subtests = await this.getItemsForBattery(subtestRefs, testHierarchy, subtestUrlBuilder);
        return {
            subtests,
            tests
        };
    }

    public async getContentForSubtest(testName: string, subtestName: string, testHierarchy: ITestHierarchy[]): Promise<any> {
        const subtestUrlBuilder = (itemRef, th) => {
            return `assets/${this.findSubtestInHierarchy(itemRef.subtestName, itemRef.testName, th).contentPath}`;
        };
        const subtests = await this.getItemsForBattery([{ subtestName, testName }], testHierarchy, subtestUrlBuilder);
        return subtests.length ? subtests[0] : null;
    }

    private async getItemsForBattery(itemRefs: any[], testHierarchy: ITestHierarchy[], urlBuilder: Function): Promise<any[]> {
        const ourItems = itemRefs.map(async (ir) => {
            return await this.loadItemJson(ir, testHierarchy, urlBuilder);
        });
        return await Promise.all(ourItems);
    }

    private async loadItemJson(itemRef: any, testHierarchy: ITestHierarchy[], urlBuilder: Function) {
        try {
            const url = urlBuilder(itemRef, testHierarchy);
            return await this.http.get(url);
        } catch (e) {
            this.logger.error('Error loading item JSON.', e);
            throw e;
        }
    }

    private buildTestRefs(subtestRefs: SubtestRef[]): String[] {
        const tests = [];
        subtestRefs.forEach((sr) => {
            if (tests.indexOf(sr.testName) === -1) {
                tests.push(sr.testName);
            }
        });
        return tests;
    }

    private findTestInHierarchy(testName: String, testHierarchy: ITestHierarchy[]) {
        const test = testHierarchy.find(t => t.name === testName);
        if (!test) {
            throw new Error(`Couldn't find test in hierarchy: ${testName}`);
        }
        return test;
    }

    private findSubtestInHierarchy(subtestName: String, testName: String, testHierarchy: ITestHierarchy[]) {
        const test = this.findTestInHierarchy(testName, testHierarchy);
        const subtest = test.subtests.find(st => st.name === subtestName);

        if (!subtest) {
            throw new Error(`Couldn't find subtest in hierarchy: ${testName} ${subtestName}`);
        }
        return subtest;
    }
}
