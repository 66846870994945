import { LocationStrategy } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';

import { NotificationData } from '@app/components';
import { TranslateService, MessageTypes } from '@app/shared';
import { AssessEvents, ConnectionEvents } from '@app/shared/assess-events';

import { AlertController } from '@ionic/angular';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { AuthService } from './auth.service';
import { Events } from './events.service';
import { Logger, LoggingService } from './logging.service';
import { PlatformService } from './platform.service';
import { UserStoreService } from './user-store.service';
import { RemoteSessionService } from './remote-session.service';

@Injectable()
export class DeviceConnectivityService {
    logger: Logger;

    isConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private wifiAlert;
    private stimShown = false;

    // fake things for desktop
    private stimWindow;
    private mpcFakes = {
        startAdvertising: () => { },
        startBrowsing: () => { },
        sendData: (data) => {
            if (!this.isConnected.value) {
                return;
            }
            if (data.includes(MessageTypes.SHOW_STIM) || (this.stimWindow && !this.stimWindow.closed)) {
                this.openStimWindow((newWindow = false) => {
                    this.stimWindow.console.log(`Sending message: ${data}`);
                    // give the stim component a chance to init
                    setTimeout(() => this.stimWindow.MPC.onReceiveData.next(data), newWindow ? 1000 : 10);
                });
            } else if (window.opener) {
                window.opener.MPC.onReceiveData.next(data);
            }
        },
        isWifiEnabled: () => true,
        onFoundPeer: new Subject<any>(),
        onReceiveInvitation: new Subject<any>(),
        onReceiveData: new Subject<any>(),
        onChangeState: new Subject<any>(),
        onEnteringBackground: new Subject<any>(),
        onEnteringForeground: new Subject<any>()
    };

    constructor(
        private events: Events,
        private platform: PlatformService,
        private loggingService: LoggingService,
        private translate: TranslateService,
        private zone: NgZone,
        private userStore: UserStoreService,
        private alertController: AlertController,
        private locationStrategy: LocationStrategy,
        private authService: AuthService,
        private remoteSessionService: RemoteSessionService
    ) {
        this.logger = this.loggingService.getLogger('DeviceConnectivityService');
        if (!this.platform.isNative()) {
            AssessEvents.remoteSession.subscribe(isRemoteSessionStarted => {
                if (!isRemoteSessionStarted) {
                    window['MPC'] = this.mpcFakes;
                    this.isConnected.next(true);
                } else {
                    window['MPC'] = {
                        ...this.mpcFakes,
                        sendData: (data) => this.remoteSessionService.sendData(data)
                    };
                }
            });
        }
    }

    public async init() {
        if (!this.platform.isNative()) {
            window['MPC'] = this.mpcFakes;
            this.isConnected.next(true);
            if (window.location.pathname.endsWith('/stim') && window.opener) {
                // from the stim window side, to show the waiting when the parent window closes or unloads
                window.opener.addEventListener('beforeunload', () => {
                    this.isConnected.next(false);
                    this.events.publish(ConnectionEvents.disconnected);
                    setTimeout(() => {
                        self.close();
                    }, 2000);
                });
            }
        } else {
            this.onEnteringBackground(() => {
                this.logger.warn('App going to background');
                this.events.publish(AssessEvents.paused, new Date().getTime());
            });
            this.onEnteringForeground(() => {
                // give some time for the UI services to load
                setTimeout(() => {
                    this.logger.success('App resuming to the foreground');
                    this.events.publish(AssessEvents.resumed, new Date().getTime());

                    // begin broadcasting to re-establish the device connection
                    this.beginBroadcasting();
                }, 1);
            });
            this.events.subscribe(AssessEvents.loginSuccess, () => this.beginBroadcasting());
        }

        if (typeof window.MPC !== 'undefined') {
            this.onChangeState((stateInfo) => {
                this.zone.run(() => {
                    // publishes a state change event
                    if (stateInfo.state === 'Connected') {
                        this.isConnected.next(true);
                        this.events.publish(ConnectionEvents.connected);
                    } else if (stateInfo.state === 'NotConnected' && this.isConnected.value) {
                        this.isConnected.next(false);
                        this.events.publish(ConnectionEvents.disconnected);
                    }
                });
            });

            this.isWifiEnabled(async (isEnabled) => await this.handleWifiDisabled(isEnabled));
            this.events.subscribe(AssessEvents.logout, () => this.disconnect());
        }

        // for dev and debug purposes only
        // can simulate connection and disconnection via these events
        if (!this.platform.isNative()) {
            this.events.subscribe(ConnectionEvents.connected_debug, () => {
                this.isConnected.next(true);
                this.events.publish(ConnectionEvents.connected);
            });

            this.events.subscribe(ConnectionEvents.disconnected_debug, () => {
                this.isConnected.next(false);
                this.events.publish(ConnectionEvents.disconnected, true);
            });
        }

        this.events.subscribe(AssessEvents.stimShown, shown => this.stimShown = shown);
    }

    public async searchForClient() {
        this.logger.info('Started searching for peers.');
        const userId = (await this.userStore.getLoggedInUserDetails()).userId;
        this.startBrowsing(userId);
    }

    public async searchForPractitioner() {
        this.logger.info('Started advertising for peers.');
        const userId = (await this.userStore.getLoggedInUserDetails()).userId;
        this.startAdvertising(userId);
    }

    public async beginBroadcasting() {
        if (await this.authService.isLoggedIn()) {
            if (await this.platform.isPractitionerMode()) {
                this.searchForClient();
            } else {
                this.searchForPractitioner();
            }
        }
    }

    // TODO:  move this to system health when it's actually a thing
    public async handleWifiDisabled(isEnabled) {
        if (this.wifiAlert) {
            await this.wifiAlert.dismiss();
            this.wifiAlert = null;
        }
        if (!isEnabled && (await this.platform.isPractitionerMode() || !this.stimShown)) {
            // prompt the user to enable wifi
            this.wifiAlert = await this.alertController.create({
                header: await this.translate.get('shared.connectivity.label.wifiDisabled').toPromise(),
                message: await this.translate.get('shared.connectivity.message.wifiDisabled').toPromise(),
                buttons: [
                    {
                        text: await this.translate.get('shared.label.ok').toPromise(),
                        handler: () => {
                            this.wifiAlert = null;
                        }
                    }
                ],
                backdropDismiss: false
            });
            await this.wifiAlert.present();
        }
    }

    public async showConnectionNotification(event: any) {
        const msgKey = this.isConnected.value ?
            'shared.connectivity.message.connected' : 'shared.connectivity.message.notConnected';
        const msg = await this.translate.get(msgKey).toPromise();
        const data: NotificationData = {
            message: `<h5 class="ion-padding">${msg}</h5>`,
            event
        };
        this.events.publish(AssessEvents.notify, data);
    }

    // ------ Methods that interact with the native plugin -----

    public isWifiEnabled(callback) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.isWifiEnabled(callback);
        }
    }

    public disconnect() {
        if (typeof window.MPC !== 'undefined') {
            this.logger.debug('Disconnecting from peer.');
            window.MPC.disconnect();
        }
    }

    public startAdvertising(userId: string) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.startAdvertising(userId);
        }
    }

    public startBrowsing(userId: string) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.startBrowsing(userId);
        }
    }

    public sendData(data): Promise<void> {
        if (typeof window.MPC !== 'undefined' && this.isConnected.value) {
            return new Promise((resolve, reject) => {
                window.MPC.sendData(data, resolve, reject);
            });
        }
    }

    public onFoundPeer(callback: (peerInfo: any) => void) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.onFoundPeer.subscribe(callback);
        }
    }

    public onReceiveInvitation(callback: (peerInfo: any) => void) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.onReceiveInvitation.subscribe(callback);
        }
    }

    public onReceiveData(callback: (dataInfo: any) => void) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.onReceiveData.subscribe(callback);
        }
    }

    public onChangeState(callback: (stateInfo: any) => void) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.onChangeState.subscribe(callback);
        }
    }

    public onEnteringBackground(callback: () => void) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.onEnteringBackground.subscribe(callback);
        }
    }

    public onEnteringForeground(callback: () => void) {
        if (typeof window.MPC !== 'undefined') {
            window.MPC.onEnteringForeground.subscribe(callback);
        }
    }

    // ------ Fakes for desktop -----

    public openStimWindow(callback) {
        if (this.stimWindow && !this.stimWindow.closed) {
            callback();
        } else {
            const baseHref = document.querySelector('base').href;
            this.stimWindow = window.open(`${baseHref}${this.locationStrategy.prepareExternalUrl('stim')}`,
                'stimPad', 'width=1024,height=768');
            this.stimWindow.focus();
            if (this.stimWindow.MPC) {
                this.stimWindow.MPC.onChangeState.next({ state: 'Connected' });
                callback(true);
            } else {
                this.stimWindow.addEventListener('load', () => {
                    this.stimWindow.MPC.onChangeState.next({ state: 'Connected' });
                    callback(true);
                }, false);
                this.stimWindow.addEventListener('unload', () => {
                    if (this.stimWindow.location.pathname.startsWith('/stim')) {
                        this.events.publish(ConnectionEvents.disconnected);
                    }
                });
            }
        }
    }
}
