import { Administrable, ItemGroup, Subtest } from '@app/models';
import { ItemTypes, QuestionTypes } from '@app/models/consts';

export class ContentQuery {

    /**
     * Returns a flattened battery array, which includes all subtests and their children.  Subtest
     * instances are followed by its questions/SCILs/item groups and a subtest summary card.
     * @param content - the content to flatten
     * @return - the flat battery
     */
    static getFlatBattery(content): any[] {
        const items = [];

        const flattenSubtree = (item) => {
            items.push(item);
            if (item.children) {
                item.children.forEach(flattenSubtree);
            }
        };

        if (content.children) {
            content.children.forEach(flattenSubtree);
        } else {
            content.subtestRefs.forEach((sr) => {
                const subtest = sr.model();
                if (subtest) {
                    flattenSubtree(subtest);
                }
            });
        }
        return items;
    }

    /**
     * Flattens the content and applies the provided function to each item.
     * @param content - the content to iterate
     * @param func - the function to apply
     */
    static each(content, func: Function) {
        if (content != null) {
            if (content.subtestRefs) {
                throw new Error('Content queries must be done at the subtest level or lower.');
            }
            const flatBattery = ContentQuery.getFlatBattery(content);
            flatBattery.forEach((item) => func(item));
        }
    }

    /**
     * Returns all items in the given content that match the given predicate.
     * @param content - the content to iterate
     * @param predicate - the predicate used for evaluation
     * @return - an array of items that match the predicate
     */
    static find(content, predicate: Function): Array<any> {
        const result = [];
        ContentQuery.each(content, (item) => {
            if (predicate(item)) {
                result.push(item);
            }
        });
        return result;
    }

    /**
     * Returns the first item in the given content that matches the given predicate.
     * @param content - the content to iterate
     * @param predicate - the predicate used for evaluation
     * @return - the first item that matches the predicate
     */
    static findOne(content, predicate: Function) {
        const result = ContentQuery.find(content, predicate);
        return result.length ? result[0] : null;
    }

    /**
     * Returns array of a single subtest items
     * @param subtest - the subtest to iterate
     * @return - all items per filterPolicy
     */
    static flatSubtest(subtest: Subtest): Array<any> {
        const filterPolicy = (item) => {
            // don't include the trial container
            if (item.itemType === ItemTypes.administrable && item.trials) {
                return false;
            }
            // don't include the subtest if item groups have cover cards
            if (item.itemType === ItemTypes.subtest && item.itemGroups.length && !item.itemGroups[0].shouldSuppressCoverSheet) {
                return false;
            }
            // don't include item group containers if we are suppressing their cover sheet
            if (item.itemType === ItemTypes.itemGroup && item.shouldSuppressCoverSheet) {
                return false;
            }
            return true;
        };

        const flatSubtest = this.getFlatBattery(subtest);
        flatSubtest.unshift(subtest);

        return flatSubtest.filter(item => filterPolicy(item));
    }

    /**
     * Returns the first administrable in the given content.
     * @param content - the content to iterate
     * @return - the first administrable
     */
    static administrable(content): Administrable[] {
        return ContentQuery.find(content, item => item.isAdministrable);
    }

    /**
     * Returns the first scoreable administrable in the given subtest or item group.
     * @param content - the subtest or ig to iterate
     * @return - the first scoreable administrable
     */
    static findFirstScoreableItem(container: Subtest | ItemGroup) {
        return ContentQuery.findOne(container, (item) => {
            return item.isAdministrable && item.questionType === QuestionTypes.score;
        });
    }

    /**
     * Returns the ancestor of the given item that matches the given type.
     * @param item - the item whose ancestor we are trying to find
     * @param type - the type of ancestor we are looking for
     * @return - the ancestor of the given type or null
     */
    static ancestorOfType(item, type) {
        for (let ancestor = item; ancestor; ancestor = ancestor.parent) {
            if (ancestor.itemType === type) {
                return ancestor;
            }
        }
        return null;
    }

    static getManualDiscontinueContainer(item) {
        const itemGroup = ContentQuery.ancestorOfType(item, ItemTypes.itemGroup);
        if (itemGroup && !itemGroup.shouldSuppressCoverSheet) {
            return itemGroup;
        }
        return ContentQuery.ancestorOfType(item, ItemTypes.subtest);
    }
}
