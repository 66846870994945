/**
 * Represents a message type used in device communication.  Consumers will subscribe to message
 * types via Events.  When a message is received with the type the consumer has subscribed to an
 * Event of that type will be published.
 *
 * e.g. - this.events.subscribe(MessageTypes.SHOW_STIM, (data) => { // called when showStim msg received });
 */


export enum MessageTypes {
    HIDE_STIM = 'message:hideStim',
    SHOW_STIM = 'message:showStim',
    STIM_WAS_SHOWN = 'message:stimWasShown',
    STIM_READY = 'message:stimReady',
    CUSTOM_STIM_RESPONSE = 'message:customStimResponse',
    MESSAGE_TO_CLIENT = 'message:>client',
    MESSAGE_FROM_CLIENT = 'message:>pract',
    TRIGGER_SYNC_CLIENT_MESSAGE = 'message:>client_trigger_sync',
    SYNC_MESSAGE_FROM_CLIENT = 'sync-message:>pract',
    INVALIDATE_MESSAGE_FROM_CLIENT = 'inv-message:>pract',
    INVALID_STIM_SHOWING_ON_SYNC = 'message:>client_invalid_stim_on_sync',
    DEBUG_TOGGLE_CONNECTION_TO_CLIENT = 'toggle-connection-message:>client', // only for dev and rex to simulate disconnections
    CONTENT_QUERY = 'message:content_query',
    END_CALL = 'message:end_call',
    CHECK_CLIENT_STATUS = 'message:>check_client',
    MUTE_STREAM = 'message:mute_stream',
    RESETACK_ON_INVALID_STIM_SHOWING = 'message:>client_invalid_reset_ack_stim',
    INFORM_CLIENT_STATUS = 'message:>client_status'
}

export interface Message {
    type: MessageTypes;
    payload?: any;
    uniqueId?: string;
}
