import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared';
import { LoggingService } from '@app/core/providers/logging.service';

import { IonicModule } from '@ionic/angular';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';

@NgModule({
    declarations: [LoginComponent],
    imports: [
        CommonModule,
        IonicModule,
        SharedModule,
        AuthRoutingModule
    ],
    providers: [LoggingService, AppPreferences]
})
export class AuthModule { }
