import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { ConsoleLogModal, NotificationPopoverComponent } from '@app/components';

import { IonicModule, Platform } from '@ionic/angular';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { File } from '@ionic-native/file/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { Zip } from '@ionic-native/zip/ngx';

import { TranslateModule } from '@ngx-translate/core';

import {
    AssessmentService,
    AuthService,
    BatteryService,
    ContentService,
    ContentDownloaderService,
    DeployService,
    DeviceConnectivityService,
    EncryptDecryptService,
    FileUtilService,
    HttpService,
    IdleTimeoutService,
    ImageService,
    LoggingService,
    NetworkStatusService,
    MessagingService,
    OfflineLoginStoreService,
    PlatformService,
    RemoteSessionService,
    SoundEffectsService,
    SyncService,
    UserStoreService,
    TaskQService,
    TestStateService,
    ToastService,
    ZipService
} from './providers';
import { zipServiceFactory } from './providers/zip';

export { ConsoleLogModal, File, NotificationPopoverComponent, ScreenOrientation };

@NgModule({
    imports: [
        HttpClientModule,
        IonicModule,
        TranslateModule
    ],
    exports: [
        HttpClientModule
    ],
    providers: [
        AssessmentService,
        AuthService,
        BatteryService,
        ContentService,
        ContentDownloaderService,
        DeployService,
        EncryptDecryptService,
        FileUtilService,
        HttpService,
        IdleTimeoutService,
        ImageService,
        LoggingService,
        DeviceConnectivityService,
        MessagingService,
        NativeAudio,
        NetworkStatusService,
        OfflineLoginStoreService,
        PlatformService,
        RemoteSessionService,
        SoundEffectsService,
        SyncService,
        UserStoreService,
        TaskQService,
        TestStateService,
        ToastService,
        Zip,
        UniqueDeviceID,
        WebView,
        {
            provide: ZipService,
            useFactory: zipServiceFactory,
            deps: [Platform, LoggingService, Zip, FileUtilService]
        }

    ],
    declarations: [
        ConsoleLogModal,
        NotificationPopoverComponent
    ]
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('Core Module already loaded.');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                AssessmentService,
                AuthService,
                BatteryService,
                ContentService,
                ContentDownloaderService,
                DeployService,
                EncryptDecryptService,
                FileUtilService,
                File,
                HttpService,
                IdleTimeoutService,
                ImageService,
                LoggingService,
                DeviceConnectivityService,
                MessagingService,
                NativeAudio,
                NetworkStatusService,
                OfflineLoginStoreService,
                PlatformService,
                RemoteSessionService,
                ScreenOrientation,
                SoundEffectsService,
                SyncService,
                TaskQService,
                UserStoreService,
                TestStateService,
                ToastService,
                UniqueDeviceID,
                Zip
            ]
        };
    }
}
