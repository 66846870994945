<div class="video-wrapper">
    <div class="controls">
        <ion-button (click)="toggleMicEnabled()" fill="clear" color="new" shape="round" mode="ios">
            <ion-icon *ngIf="micEnabled.value" class="action-btn" slot="end" name="mic-outline"></ion-icon>
            <ion-icon *ngIf="!micEnabled.value" class="action-btn" slot="end" name="mic-off-outline">
            </ion-icon>
        </ion-button>
        <ion-button *ngIf="isLocal" (click)="toggleVideoEnabled()" fill="clear" color="new" shape="round" mode="ios">
            <ion-icon *ngIf="videoEnabled.value" class="action-btn" name="eye-outline"></ion-icon>
            <ion-icon *ngIf="!videoEnabled.value" class="action-btn" name="eye-off-outline">
            </ion-icon>
        </ion-button>
    </div>
    <video #video class="video" autoplay playsinline>Video Player</video>
</div>