import { Injectable, NgZone } from '@angular/core';

import { Platform } from '@ionic/angular';
import { File, FileEntry } from '@ionic-native/file/ngx';

import moment from 'moment';

import { FileUtilService } from './file-util.service';

const CONSOLE_LOG_PREFIX = 'console.log';
const CONSOLE_LOG = `${CONSOLE_LOG_PREFIX}.html`;

export class Logger {

    constructor(private logger: string, private loggingService: LoggingService) {

    }

    public info(msg?: string, ...data): void {
        this.loggingService.info(this.logger, msg, data);
    }

    public error(msg?: string, ...data): void {
        this.loggingService.error(this.logger, msg, data);
    }

    public warn(msg?: string, ...data): void {
        this.loggingService.warn(this.logger, msg, data);
    }

    public debug(msg?: string, ...data): void {
        this.loggingService.debug(this.logger, msg, data);
    }

    public success(msg?: string, ...data): void {
        this.loggingService.success(this.logger, msg, data);
    }
}

const BUFFER_SIZE = 10;
const FLUSH_INTERVAL = 30 * 1000; // 30 seconds
@Injectable()
export class LoggingService {

    private logFile: FileEntry;

    private rootLogger: Logger;

    private originalConsoleLog: Function;

    private logBuffer: Array<any> = [];

    constructor(private platform: Platform, private fileService: File, private fileUtil: FileUtilService, private zone: NgZone) {
        this.rootLogger = this.getLogger('root');
        if (window['jasmine']) { // retain old console log, instead of file writing
            return;
        }
        this.zone.runOutsideAngular(() => {
            setInterval(() => this.flush(), FLUSH_INTERVAL);
        });
        this.rootLogger = this.getLogger('root');
        this.originalConsoleLog = window['origConsoleLog'] || console.log;
        console.log = (...args: any) => {
            this.rootLogger.debug(args.join(' '));
        };
        if (window['initLogs'] && window['initLogs'].length > 0) {
            window['initLogs'].forEach(initLog => {
                this.originalConsoleLog.call(console, ...initLog);
                this.logBuffer.push({ initLog });
            });
        }
    }

    public getLogger(loggerName: string): Logger {
        return new Logger(loggerName, this);
    }

    public info(logger: string, msg?: string, ...data): void {
        this.consoleLog(`${this.buildLogArray(logger, msg, data)}`, 'color: blue;');
    }

    public error(logger: string, msg?: string, ...data): void {
        this.consoleLog(`${this.buildLogArray(logger, msg, data)}`, 'color: red; font-weight: bold;');
        // tslint:disable-next-line: no-console
        console.trace.apply(console);
    }

    public warn(logger: string, msg?: string, ...data): void {
        this.consoleLog(`${this.buildLogArray(logger, msg, data)}`, 'color: orange;');
    }

    public debug(logger: string, msg?: string, ...data): void {
        this.consoleLog(`${this.buildLogArray(logger, msg, data)}`, 'color: black;');
    }

    public success(logger: string, msg?: string, ...data): void {
        this.consoleLog(`${this.buildLogArray(logger, msg, data)}`, 'color: green; font-weight: bold;');
    }

    public async getConsoleLog(): Promise<string> {
        await this.flush();
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
            const dataDir = await this.fileUtil.getRootPath();
            return new Promise<string>((res, rej) => {
                dataDir.getFile(CONSOLE_LOG, { create: false }, fileEntry => {
                    fileEntry.file(file => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            res(reader.result as string);
                        };
                        reader.readAsText(file, 'UTF-8');
                    });
                }, e => rej(e));
            });
        }
        return this.fileService.readAsText(this.fileService.dataDirectory, CONSOLE_LOG);
    }

    public async readLogFile(filename: string): Promise<string> {
        const dataDir = await this.fileUtil.getRootPath();
        return await this.fileUtil.readAsText(dataDir, filename);
    }

    public async deleteLogFile(filename: string): Promise<any> {
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
            const dataDir = await this.fileUtil.getRootPath();
            return new Promise(res => {
                dataDir.getFile(filename, { create: false }, (entry: FileEntry) => {
                    entry.remove(() => res(), e => res());
                });
            });
        } else {
            await this.fileService.removeFile(this.fileService.dataDirectory, filename);
        }
    }

    public async clearConsoleLog(): Promise<boolean> {
        await this.flush();
        const dataDir = await this.fileUtil.getRootPath();

        const time = moment().format('MM-DD-YYYYHHmmssSSS');
        const filename = `${CONSOLE_LOG_PREFIX}.${time}.html`;
        if (!(this.platform.is('desktop') || this.platform.is('mobileweb'))) {
            await this.fileService.copyFile(dataDir.toURL(), CONSOLE_LOG, dataDir.toURL(), filename);
            await this.fileService.writeFile(this.fileService.dataDirectory, CONSOLE_LOG, '', { replace: true });
        } else {
            try {
                const currentLog = (await this.fileUtil.readAsText(dataDir, CONSOLE_LOG)) || '';
                const backupFile = await this.fileUtil.getNewFile(dataDir, filename);
                this.fileUtil.writeBlob(dataDir, backupFile, new Blob([currentLog], { type: 'text/html' }));
            } catch (e) {
                this.rootLogger.error('Error in backing up logs');
            }
            this.logFile = await this.fileUtil.getNewFile(dataDir, CONSOLE_LOG);
        }
        return true;
    }

    public async getAllConsoleLogFileEntries(): Promise<FileEntry[]> {
        const dataDir = await this.fileUtil.getRootPath();
        let entries;
        if (!(this.platform.is('desktop') || this.platform.is('mobileweb'))) {
            entries = await this.fileService.listDir(this.fileService.dataDirectory, '.');
        } else {
            entries = await this.fileUtil.getEntriesAsPromise(dataDir);
        }
        return entries.filter(_ => _.isFile && _.name.startsWith(CONSOLE_LOG_PREFIX));
    }


    private buildLogArray(logger: string, msg?: string, data?): string {
        const time = moment().format('YYYY-MM-DD HH:mm:ss SSS');
        return `[${time}] ▸ ${logger} - ${msg} ${data && data.length > 0 ?
            data.map(it => {
                return JSON.stringify(it, null, 2);
            }).join('\n') : ''}`;
    }


    private async getLogFile(): Promise<FileEntry> {
        if (this.logFile != null) {
            return Promise.resolve(this.logFile);
        }
        const dataDir = await this.fileUtil.getRootPath();
        this.logFile = await this.fileUtil.getNewFile(dataDir, CONSOLE_LOG);
        return this.logFile;
    }

    private async consoleLog(msg: string, color: string = '') {
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
            this.originalConsoleLog.apply(console, [`%c ${msg.replace('[]', '')}`, color]);
        }
        this.logBuffer.push({ serviceLog: { msg, color } });
        await this.checkAndFlush();
    }

    private async checkAndFlush() {
        if (this.logBuffer.length > BUFFER_SIZE) {
            await this.flush();
        }
    }

    public async flush() {
        const logs = [];
        while (this.logBuffer.length > 0) {
            const log = this.logBuffer.shift();
            if (log.serviceLog) {
                // await this.writeLog(log.serviceLog.msg, log.serviceLog.color);
                logs.push(`<div style='${log.serviceLog.color};display:inline-block'>${log.serviceLog.msg.replace('[]', '')}</div>\r\n`);
            } else if (log.initLog) {
                // await this.writeLog(this.buildLogArray('root', 'initlog', log.initLog), `grey`);
                logs.push(`<div style='color: grey;display:inline-block'>${this.buildLogArray('root', 'initlog', log.initLog)}</div>\r\n`);
            }
        }
        if (logs.length > 0) {
            await this.writeLog(logs.join(''));
        }
    }

    private async writeLog(logs: string) {
        this.getLogFile().then(logFile => {
            logFile.createWriter(writer => {
                writer.seek(writer.length);
                writer.write(new Blob([logs], { type: 'text/html' }));
            });
        });
    }
}
